.schedule-container-reskin {
  /* background-color: var(--light-pink); */
  /* padding-top: 50px; */
  font-family: 'Gotham Medium' !important;
}

.flatpickr-prev-month {
  display: none;
}
.flatpickr-next-month {
  display: none;
}

.flatpickr-next-month {
  width: auto;
  left: 0;
}
.flatpickr-current-month {
  width: 100%;
  left: 0;
  display: flex;
  gap: 12px;
  align-items: center;
}
.schedule-container-reskin h5 {
  color: var(--dark-pink);
}
.flatpickr-weekday,
.flatpickr-day {
  color: var(--dark-pink) !important;
}
.flatpickr-disabled {
  opacity: 0.3;
}
.flatpickr-day.selected {
  color: #fff !important;
}
.flatpickr-current-month .numInputWrapper {
  width: 50% !important;
  text-align: initial !important;
  background-color: #fff;
  border: 1px solid var(--grey);
  padding: 4px;
  color: var(--grey);
  margin-bottom: 10px;
}
.flatpickr-day {
  border-radius: 0%;
}
.flatpickr-day:hover,
.flatpickr-day.nextMonthDay:hover {
  background-color: #fff;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  width: 50% !important;
  text-align: initial;
  background-color: #fff !important;
  border: 1px solid var(--grey);
  padding: 4px;
  color: var(--grey);
  margin-bottom: 10px;
}
.schedule-selected-date {
  font-size: 20px;
  color: var(--grey);
  font-family: 'Gotham Book' !important;
}

.estimated-arrival {
  font-size: 16px;
  color: var(--grey);
  font-family: 'Gotham Book' !important;
}

.book__timepicker {
  margin-bottom: 120px;
}

.book__timepicker button {
  background-color: #fff;
  border: 1px solid var(--grey);
  color: var(--grey);
  width: 80% !important;
  text-align: start;
}
.book__timepicker button:hover {
  background-color: var(--dark-pink);
  border: 1px solid var(--light-pink);
  color: #fff;
}

.flatpickr-rContainer {
  margin-top: 30px !important;
}

@media (min-width: 768px) {
  .book__timepicker {
    margin-top: 0px;
    height: 180px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }

  .flatpickr-wrapper {
    margin-top: 30px !important;
  }
}
@media (max-width: 768px) {
  .flatpickr-calendar {
    margin: 0px !important;
  }

  .flatpickr-wrapper {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .book__timepicker {
    margin-bottom: 0;
  }
}
