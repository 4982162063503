.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  flex: 1 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: baseline;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  pointer-events: none;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: baseline;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1 1;
  font-weight: bolder;
}


.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #751d46;
  box-shadow: none;
  color: #fff;
  border-color: #751d46;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
/* .flatpickr-day.nextMonthDay, */
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.nextMonthDay {
  color: #757575; /* show that these dates are selectable */
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}

.flatpickr-time:after {
  content: '';
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  flex: 1 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/**
 * Breakpoints
 */
/**
 * Colors
 */
/**
 * Fonts
 */
/**
 * Links
 */
/**
 * Buttons
 */
/**
 * Cards
 */
/**
 * Forms
 */
/**
 * Nav
 */
/**
 * Shadows
 */
/**
 * Transitions
 */
/**
 * Import Bootstrap styles
 *
 * Pulled this list from ~boostrap/scss/bootstrap.scss.
 * Using our own copy allows us to comment out irrelevant styles.
 * The caveat being that this list may need to be adjusted
 * after changing Bootstrap to a different version.
 */
/**
 * Bootstrap imports
 * If you update Bootstrap, you'll need to pull this updated list from ~bootstrap/scss/bootstrap.scss
 */
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  /* --dark-pink: #e02d88; */
  --dark-pink: #cf2680;
  --dark-bg-pink: #B92273;
  --light-pink: #f9ddeb;
  --white: #fff;
  --light-grey: #efedee;
  --button-transition: all ease 0.3s;
  --grey: #7b6c73;
  /* --grey: #9f9e9d; */
  --dark-grey: #5f6060;
  --container-width: 1130px;
  --large-heading: 64px;
  --sub-heading: 42px;
  --paragraph-text: 16px;
  --paragraph-line-height: 1.75;
  --large-paragraph-text: 24px;
  --large-paragraph-line-height: 40px;
  --breakpoint-xxs: 0;
  --breakpoint-xs: 375px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4d4d4d;
  text-align: left;
  background-color: #fff;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #d4357f;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #d4357f;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
  -ms-text-size-adjust: 100%; /* prevent zooming in IE 10+ */
  -moz-text-size-adjust: 100%; /* prevent zooming in Firefox */
  -webkit-text-size-adjust: 100%; /* prevent zooming in Safari and Chrome */
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 3.75rem;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.5rem + 3vw);
  }
}

h2,
.h2 {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  h2,
  .h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
}

h3,
.h3 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  h3,
  .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
}

h4,
.h4 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  h4,
  .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* @media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
} */

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs,
.col-xs-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

@media (min-width: 375px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xs-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xs-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xs-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xs-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xs-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xs-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #4d4d4d;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ffd8ea;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 0.6;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 0.8;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 0.8;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;      

   
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.btn {
  display: inline-block;
  font-weight: 700;
  color: #4d4d4d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
       user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #4d4d4d;
  text-decoration: none;
}

.cta-buttons .btn:hover:after {
  content: '';
  position: absolute;
  left: 0;
  top: -7px;
  height: 48px;
  width: 101%;
  box-sizing: border-box;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  color: var(--white);
  background: #cf2680;
  background: var(--dark-pink);
  border-color: #cf2680;
  border-color: var(--dark-pink);
  font-family: 'Gotham Bold';
}

.btn-primary:hover {
  color: #cf2680 !important;
  color: var(--dark-pink) !important;
  background-color: #fff !important;
  background-color: var(--white) !important;
  border-color: #cf2680;
  border-color: var(--dark-pink);
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #cf2680;
  background-color: var(--dark-pink);
  border-color: #cf2680;
  border-color: var(--dark-pink);
  box-shadow: 0 0 0 0.2rem rgba(255, 113, 179, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #cf2680;
  background-color: var(--dark-pink);
  border-color: #cf2680;
  border-color: var(--dark-pink);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #d4357f;
  border-color: #ff1883;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 113, 179, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #343434;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #343434;
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #343434;
  border-color: #2d2d2d;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-success:hover {
  color: #fff;
  background-color: #0fa3db;
  border-color: #0e9acf;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #0fa3db;
  border-color: #0e9acf;
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #0e9acf;
  border-color: #0d91c3;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #4d4d4d;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #4d4d4d;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #4d4d4d;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(228, 176, 18, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #4d4d4d;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #4d4d4d;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 176, 18, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #d4357f;
  background-color: #fff;
  border-color: #fff;
}

.btn-light:hover {
  color: #4d4d4d;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-light:focus,
.btn-light.focus {
  color: #4d4d4d;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(228, 228, 228, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #4d4d4d;
  background-color: #fff;
  border-color: #fff;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #4d4d4d;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 228, 228, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-pink {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-pink:hover {
  color: #fff;
  background-color: #ff3290;
  border-color: #d4357f;
}

.btn-pink:focus,
.btn-pink.focus {
  color: #fff;
  background-color: #ff3290;
  border-color: #d4357f;
  box-shadow: 0 0 0 0.2rem rgba(255, 113, 179, 0.5);
}

.btn-pink.disabled,
.btn-pink:disabled {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #d4357f;
  border-color: #ff1883;
}

.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 113, 179, 0.5);
}

.btn-light-pink {
  color: #4d4d4d;
  background-color: #fff4f7;
  border-color: #fff4f7;
}

.btn-light-pink:hover {
  color: #4d4d4d;
  background-color: #ffcedb;
  border-color: #ffc1d2;
}

.btn-light-pink:focus,
.btn-light-pink.focus {
  color: #4d4d4d;
  background-color: #ffcedb;
  border-color: #ffc1d2;
  box-shadow: 0 0 0 0.2rem rgba(228, 219, 222, 0.5);
}

.btn-light-pink.disabled,
.btn-light-pink:disabled {
  color: #4d4d4d;
  background-color: #fff4f7;
  border-color: #fff4f7;
}

.btn-light-pink:not(:disabled):not(.disabled):active,
.btn-light-pink:not(:disabled):not(.disabled).active,
.show > .btn-light-pink.dropdown-toggle {
  color: #4d4d4d;
  background-color: #ffc1d2;
  border-color: #ffb4c9;
}

.btn-light-pink:not(:disabled):not(.disabled):active:focus,
.btn-light-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-light-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 219, 222, 0.5);
}

.btn-blue {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-blue:hover {
  color: #fff;
  background-color: #0fa3db;
  border-color: #0e9acf;
}

.btn-blue:focus,
.btn-blue.focus {
  color: #fff;
  background-color: #0fa3db;
  border-color: #0e9acf;
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.btn-blue.disabled,
.btn-blue:disabled {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #0e9acf;
  border-color: #0d91c3;
}

.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.btn-light-blue {
  color: #4d4d4d;
  background-color: #e8f8fe;
  border-color: #e8f8fe;
}

.btn-light-blue:hover {
  color: #4d4d4d;
  background-color: #c3edfc;
  border-color: #b7e9fc;
}

.btn-light-blue:focus,
.btn-light-blue.focus {
  color: #4d4d4d;
  background-color: #c3edfc;
  border-color: #b7e9fc;
  box-shadow: 0 0 0 0.2rem rgba(209, 222, 227, 0.5);
}

.btn-light-blue.disabled,
.btn-light-blue:disabled {
  color: #4d4d4d;
  background-color: #e8f8fe;
  border-color: #e8f8fe;
}

.btn-light-blue:not(:disabled):not(.disabled):active,
.btn-light-blue:not(:disabled):not(.disabled).active,
.show > .btn-light-blue.dropdown-toggle {
  color: #4d4d4d;
  background-color: #b7e9fc;
  border-color: #abe5fb;
}

.btn-light-blue:not(:disabled):not(.disabled):active:focus,
.btn-light-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-light-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 222, 227, 0.5);
}

.btn-outline-primary {
  color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #d4357f;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.5);
}

.btn-outline-secondary {
  color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #cf2680;
  background-color: var(--dark-pink);
  border-color: #cf2680;
  border-color: var(--dark-pink);
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #4d4d4d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}

.btn-outline-success {
  color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #20b7f0;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #4d4d4d;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #4d4d4d;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #fff;
  border-color: #fff;
}

.btn-outline-light:hover {
  color: #4d4d4d;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #4d4d4d;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-pink {
  color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-pink:focus,
.btn-outline-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.5);
}

.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
  color: #d4357f;
  background-color: transparent;
}

.btn-outline-pink:not(:disabled):not(.disabled):active,
.btn-outline-pink:not(:disabled):not(.disabled).active,
.show > .btn-outline-pink.dropdown-toggle {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.5);
}

.btn-outline-light-pink {
  color: #fff4f7;
  border-color: #fff4f7;
}

.btn-outline-light-pink:hover {
  color: #4d4d4d;
  background-color: #fff4f7;
  border-color: #fff4f7;
}

.btn-outline-light-pink:focus,
.btn-outline-light-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 244, 247, 0.5);
}

.btn-outline-light-pink.disabled,
.btn-outline-light-pink:disabled {
  color: #fff4f7;
  background-color: transparent;
}

.btn-outline-light-pink:not(:disabled):not(.disabled):active,
.btn-outline-light-pink:not(:disabled):not(.disabled).active,
.show > .btn-outline-light-pink.dropdown-toggle {
  color: #4d4d4d;
  background-color: #fff4f7;
  border-color: #fff4f7;
}

.btn-outline-light-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-light-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 244, 247, 0.5);
}

.btn-outline-blue {
  color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-blue:focus,
.btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.5);
}

.btn-outline-blue.disabled,
.btn-outline-blue:disabled {
  color: #20b7f0;
  background-color: transparent;
}

.btn-outline-blue:not(:disabled):not(.disabled):active,
.btn-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.5);
}

.btn-outline-light-blue {
  color: #e8f8fe;
  border-color: #e8f8fe;
}

.btn-outline-light-blue:hover {
  color: #4d4d4d;
  background-color: #e8f8fe;
  border-color: #e8f8fe;
}

.btn-outline-light-blue:focus,
.btn-outline-light-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 248, 254, 0.5);
}

.btn-outline-light-blue.disabled,
.btn-outline-light-blue:disabled {
  color: #e8f8fe;
  background-color: transparent;
}

.btn-outline-light-blue:not(:disabled):not(.disabled):active,
.btn-outline-light-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-light-blue.dropdown-toggle {
  color: #4d4d4d;
  background-color: #e8f8fe;
  border-color: #e8f8fe;
}

.btn-outline-light-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-light-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 248, 254, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #d4357f;
  text-decoration: none;
}

.btn-link:hover {
  color: #d4357f;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 4px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 4px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  cursor: pointer;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #d4357f;
  background-color: #d4357f;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ffd8ea;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: white;
  border-color: white;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  cursor: pointer;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #d4357f solid 2px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(255, 88, 165, 0.5);
}

.custom-radio:hover {
  opacity: 0.7;
}

.custom-control-label::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e8f8fe;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #d4357f;
}

.navbar .nav-item,
.navbar .no-link-style {
  cursor: pointer;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #751d46;
  background-color: #e8f8fe;
  border-color: #e8f8fe;
}

@media (max-width: 991.98px) {
  .nav-tabs .nav-link.active {
    color: #d4357f;
    background-color: #e8f8fe;
    border-color: #d4357f;
  }
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #d4357f;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 374.98px) {
  .navbar-expand-xs > .container,
  .navbar-expand-xs > .container-fluid,
  .navbar-expand-xs > .container-sm,
  .navbar-expand-xs > .container-md,
  .navbar-expand-xs > .container-lg,
  .navbar-expand-xs > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 375px) {
  .navbar-expand-xs {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xs .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xs .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xs .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-xs > .container,
  .navbar-expand-xs > .container-fluid,
  .navbar-expand-xs > .container-sm,
  .navbar-expand-xs > .container-md,
  .navbar-expand-xs > .container-lg,
  .navbar-expand-xs > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xs .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xs .navbar-toggler {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar,
  .navbar-brand {
    padding: 0;
  }
  .navbar-brand img {
    height: 58px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: #4d4d4d;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #d4357f;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: #4d4d4d;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z' style='fill: %23ff58a5;'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: #4d4d4d;
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.navbar .link-no-style {
  color: var(--c-gray-800);
}
.navbar .link-no-style:hover,
.navbar .link-no-style:focus {
  color: var(--pink);
}

.header-link {
  color: var(--c-gray-800);
  margin-bottom: -1px;
  cursor: pointer;
  background-color: transparent;
  flex: 1 1 auto;
  text-align: center;
  /* flex-basis: 0;
  flex-grow: 1; */
  margin-right: 0.25rem;
  border-color: var(--pink);
  padding: 0.5rem;
  font-weight: 700;
  font-size: 0.875rem;
}

.header-link:hover,
.header-link:focus {
  color: var(--pink);
  margin-top: -2px;
}

@media (max-width: 1199.98px) {
  .header-link {
    text-align: left;
    padding: 0.5rem 0;
  }
}

.navbar-logo {
  cursor: pointer;
  width: 200px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2.5rem;
}

.card-subtitle {
  margin-top: -1.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 2.5rem;
}

.card-header {
  padding: 2.5rem 2.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 2.5rem 2.5rem;
  background-color: #fff;
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -1.25rem;
  margin-bottom: -2.5rem;
  margin-left: -1.25rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 2.5rem;
}

@media (min-width: 576px) {
  .card-columns {
    -moz-column-count: 3;
         column-count: 3;
    grid-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.media {
  display: flex;
  align-items: flex-start;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #4d4d4d;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 375px) {
  .list-group-horizontal-xs {
    flex-direction: row;
  }

  .list-group-horizontal-xs .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xs .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xs .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xs .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xs .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;:motion-rotation: ;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #852e56;
  background-color: #ffd0e6;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #852e56;
  background-color: #ffb7d8;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #852e56;
  border-color: #852e56;
}

.list-group-item-secondary {
  color: #282828;
  background-color: #cdcdcd;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #282828;
  background-color: silver;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}

.list-group-item-success {
  color: #fff;
  background-color: #28a745f2;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #115f7d;
  background-color: #a9e3f9;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #115f7d;
  border-color: #115f7d;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #858585;
  background-color: white;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-pink {
  color: #852e56;
  background-color: #ffd0e6;
}

.list-group-item-pink.list-group-item-action:hover,
.list-group-item-pink.list-group-item-action:focus {
  color: #852e56;
  background-color: #ffb7d8;
}

.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #852e56;
  border-color: #852e56;
}

.list-group-item-light-pink {
  color: #857f80;
  background-color: #fffcfd;
}

.list-group-item-light-pink.list-group-item-action:hover,
.list-group-item-light-pink.list-group-item-action:focus {
  color: #857f80;
  background-color: #ffe3ec;
}

.list-group-item-light-pink.list-group-item-action.active {
  color: #fff;
  background-color: #857f80;
  border-color: #857f80;
}

.list-group-item-blue {
  color: #115f7d;
  background-color: #c1ebfb;
}

.list-group-item-blue.list-group-item-action:hover,
.list-group-item-blue.list-group-item-action:focus {
  color: #115f7d;
  background-color: #a9e3f9;
}

.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #115f7d;
  border-color: #115f7d;
}

.list-group-item-light-blue {
  color: #798184;
  background-color: #f9fdff;
}

.list-group-item-light-blue.list-group-item-action:hover,
.list-group-item-light-blue.list-group-item-action:focus {
  color: #798184;
  background-color: #e0f5ff;
}

.list-group-item-light-blue.list-group-item-action.active {
  color: #fff;
  background-color: #798184;
  border-color: #798184;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

@media (max-width: 1200px) {
  .close {
    font-size: calc(1.275rem + 0.3vw);
  }
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
  /* padding-right: 0px !important; */
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 1000px) {
  .tac-modal > div {
    max-width: 1000px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

/* .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
} */

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.bg-primary {
  background-color: #d4357f !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #d4357f !important;
}

.bg-light-blue {
  background-color: #e8f8fe !important;
}

a.bg-light-blue:hover,
a.bg-light-blue:focus,
button.bg-light-blue:hover,
button.bg-light-blue:focus {
  background-color: #b7e9fc !important;
}

.bg-white {
  background-color: #fff !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-flex {
    display: flex !important;
  }
}

@media (min-width: 992px) {

  .d-lg-block {
    display: block !important;
  }

  .d-lg-flex {
    display: flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-self-center {
  align-self: center !important;
}

@media (min-width: 375px) {

  .flex-xs-column {
    flex-direction: column !important;
  }

  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
}

@media (min-width: 576px) {

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
}

@media (min-width: 768px) {

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }
}

@media (min-width: 992px) {

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
}

@media (min-width: 1200px) {

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
}

.float-left {
  float: left !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.shadow {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05),
    0 2px 4px -1px rgba(0, 0, 0, 0.03) !important;
}

.w-50 {
  width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}


.px-0 {
  padding-right: 0 !important;
}


.py-0 {
  padding-bottom: 0 !important;
}


.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}


.py-2 {
  padding-top: 0.5rem !important;
}


.px-2 {
  padding-right: 0.5rem !important;
}


.py-2 {
  padding-bottom: 0.5rem !important;
}


.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}


.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}


.px-4 {
  padding-left: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}


.mx-auto {
  margin-right: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 992px) {

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.text-primary {
  color: #d4357f !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #ff0c7c !important;
}

.text-muted {
  color: #6c757d !important;
}

.visible {
  visibility: visible !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }
}

.sparkles > * {
  transform-origin: center;
}

.sparkles .sparkles-two--st0:first-of-type {
  -webkit-animation: sparkles-glowing-soft 6125ms infinite;
          animation: sparkles-glowing-soft 6125ms infinite;
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  transform-origin: 75% 22%;
  opacity: 0.5;
}

.sparkles .sparkles-two--st0:last-of-type {
  animation: sparkles-pulse 10000ms infinite reverse linear;
  -webkit-animation-delay: 175ms;
          animation-delay: 175ms;
  transform-origin: 35% 70%;
}

@keyframes sparkles-pulse {
  91% {
    transform: scale3d(1, 1, 1);
  }

  94% {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  97% {
    transform: scale3d(0.2, 0.2, 0.2);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes sparkles-glowing-soft {
  60% {
    opacity: 0.5;
  }

  70% {
    opacity: 1;
  }

  80% {
    opacity: 0.5;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes sparkles-glowing-hard {
  88% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }

  94% {
    opacity: 0.2;
    transform: scale(0.5) rotate(5deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}

@keyframes fade-in-up {
  0%,
  30% {
    opacity: 0;
    transform: translateY(15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.cycle-in {
  -webkit-animation: cycle-in 13125ms infinite forwards;
          animation: cycle-in 13125ms infinite forwards;
  -webkit-animation-delay: 750ms;
          animation-delay: 750ms;
}

@keyframes cycle-in {
  0% {
    opacity: 1;
  }

  6% {
    opacity: 0;
  }

  12%,
  100% {
    opacity: 1;
  }
}

#duster {
  transform-origin: 12% 15%;
  -webkit-animation: duster 3000ms infinite ease-in-out;
          animation: duster 3000ms infinite ease-in-out;
}

@keyframes duster {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0);
  }
}

#dust-1,
#dust-2 {
  -webkit-animation: dust 3000ms infinite ease-in-out both;
          animation: dust 3000ms infinite ease-in-out both;
  transform-origin: 35% 100%;
}

#dust-1 {
  -webkit-animation-delay: 1500ms;
          animation-delay: 1500ms;
}

@keyframes dust {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0.5);
  }

  75% {
    opacity: 0.12;
  }

  100% {
    opacity: 0;
    transform: translate3d(150px, -50px) scale(1.2);
  }
}

#chompers-top {
  -webkit-animation: chomp 5500ms infinite ease-in-out both;
          animation: chomp 5500ms infinite ease-in-out both;
  transform-origin: 50% 10%;
}

@keyframes chomp {
  0%,
  88%,
  92%,
  96% {
    transform: rotate(0);
  }

  90%,
  94% {
    transform: rotate(-30deg);
  }
}

img {
  max-width: 100%;
  height: auto;
}

.mb-10 {
  margin-bottom: 6rem !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.transition {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

a {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

a:hover {
  text-decoration: none;
}

/* Underline From Left */
.underline-reveal-from-left {
  --color: var(--pink);
  background-image: linear-gradient(180deg, transparent 96%, var(--color) 0);
  background-size: 0 100%;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size 0.2s ease;
}

.underline-reveal-from-left:hover,
.underline-reveal-from-left:focus {
  background-size: 100% 100%;
  color: var(--color);
}

.underline-reveal-from-left:active {
  --color: var(--blue);
  color: var(--color);
}

/* Move Up Slightly */
/* Inverse link */
.link-inverse {
  color: #fff;
}

.link-inverse:hover {
  color: #fff;
}

/* Body Color link */
.link-body {
  color: #4d4d4d;
}

.link-body:hover {
  color: #d4357f;
}

.accordion__excerpt {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.accordion__excerpt--active {
  opacity: 1;
}

.accordion__card {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background-color: transparent;
}

.accordion__card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #dbdbdb;
  cursor: pointer;
  padding: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.accordion__card--disabled {
  color: #b7b7b7;
}

.accordion__card--disabled .card-header {
  cursor: auto;
  cursor: initial;
}

.accordion__toggle {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  text-align: center;
  width: 100%;
}

.accordion__toggle--active {
  opacity: 1;
}

button:focus {
  outline-color: #d4357f;
}

.btn {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.btn:hover {
  transform: translateY(-2px);
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background-color: #cf2680;
  background-color: var(--dark-pink);
}

.btn-light {
  color: #d4357f;
}

.btn-light:hover {
  color: #fff;
  background-color: #751d46;
}

.btn-blue {
  color: #fff;
  background-color: #751d46;
  border-color: #751d46;
}

.btn-blue:hover {
  color: #fff;
  background-color: #d4357f;
  border-color: #751d46;
}

.btn-blue:focus,
.btn-blue.focus {
  color: #fff;
  background-color: #d4357f;
  border-color: #751d46;
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.btn-blue.disabled,
.btn-blue:disabled {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #d4357f;
  border-color: #751d46;
}

.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.carousel-control-prev,
.carousel-control-next {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  color: #d4357f;
  opacity: 1;
  width: auto;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  color: #751d46;
}

.carousel-control-prev:focus,
.carousel-control-next:focus {
  color: #d4357f;
}

.carousel-control-prev {
  left: -0.5rem;
}

@media (min-width: 576px) {
  .carousel-control-prev {
    left: -1.5rem;
  }
}

@media (min-width: 992px) {
  .carousel-control-prev {
    left: 0;
  }
}

.carousel-control-next {
  right: -0.5rem;
}

@media (min-width: 576px) {
  .carousel-control-next {
    right: -1.5rem;
  }
}

@media (min-width: 992px) {
  .carousel-control-next {
    right: 0;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background: none;
}

.cta-buttons {
  display: block;
}

.cta-buttons--center .btn {
  margin-left: 1rem;
  margin-right: 1rem;
}

.flatpickr-wrapper {
  width: 100%;
}

.flatpickr-calendar {
  background: transparent;
  box-shadow: none;
  border: 0;
  margin: 0 auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  font-size: 1rem;
}

.flatpickr-months svg {
  vertical-align: top;
}

.flatpickr-day {
  border-radius: 0.25rem;
}

.flatpickr-day.selected {
  background: #d4357f;
  border-color: #d4357f;
}

.flatpickr-day.today {
  color: #751d46;
  border-color: #751d46;
}

.footer {
  font-size: 0.875rem;
  color: #fff;
}

.footer a {
  color: #fff;
}

.footer a:hover {
  color: #fff;
}

.footer .navbar-brand .st0 {
  fill: #fff;
}

.footer__nav-link {
  display: inline-block;
  font-weight: 600;
  min-width: 48px;
  min-height: 48px;
}
.footer__nav-link-text {
  background-image: linear-gradient(180deg, transparent 96%, #fff 0);
  background-size: 0 100%;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size 0.2s ease;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.footer__nav-link:hover > .footer__nav-link-text,
.footer__nav-link:focus > .footer__nav-link-text {
  background-size: 100% 100%;
  color: #fff;
}

footer img {
  padding-left: 0 !important;
}

@media (max-width: 993px) {
  .footer-col {
    margin-bottom: 0 !important;
    margin-top: 30px !important;
  }
}

@media (max-width: 767.98px) {
  footer .footer_nav-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  footer .footer-nav-link {
    white-space: nowrap;
  }

  footer .footer-col {
    padding-left: 82px !important;
  }
}

@media (max-width: 575px) {
  footer .footer-nav-link {
    text-align: center;
    max-width: 100%;
  }

  footer .footer-col {
    align-items: center;
    padding-left: 0 !important;
  }
}

@media (max-width: 374.98px) {
  footer .footer-nav-link {
    white-space: normal;
  }
}

#colophon {
  font-size: 0.75rem;
}

#colophon svg {
  margin-right: 0.25rem;
  vertical-align: baseline;
}

body {
  --header-height: 64px;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  transition: top 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    background-color 10ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 250ms;
  z-index: 1030;
  /* margin-bottom: 1.5rem; */
}

.header[data-scroll='in'] {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05),
    0 2px 4px -1px rgba(0, 0, 0, 0.03);
}

@supports (color: var(--header-height)) {
  [data-scroll-dir-y='1'] .header[data-scroll='in'] {
    top: calc(-1 * var(--header-height));
  }
}

.hero {
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  background: #000;
}

/* @media (min-width: 1200px) and (max-height: 699.98px) {
  .hero h1 {
    font-size: calc(1.5em + 1vw);
  }
} */

.hero .cta-buttons {
  display: none;
}

@media (min-width: 576px) {
  .hero .cta-buttons {
    display: block;
  }
}

.hero .cta-buttons .btn {
  display: block;
  width: auto;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

@media (min-width: 576px) {
  .hero .cta-buttons .btn {
    display: inline-block;
    margin-left: 1rem;
  }

  .hero .cta-buttons .btn:first-of-type {
    margin-left: 0;
  }
}

/* @media (max-width: 767px) {
  .hero__overlay video {
    display: none;
  }
} */

.icon__dusted {
  margin-bottom: -1rem;
}

@media (min-width: 768px) {
  .icon__dusted #icon-duster {
    width: 50%;
  }
}

.icon__flytrap svg {
  width: 92px;
}

@media (min-width: 844px) {
  .icon__flytrap {
    position: absolute;
    margin-right: 0;
    bottom: -48px;
    left: 99%;
  }
}
@media (max-width: 843px) {
  .icon__flytrap {
    margin-left: auto;
    margin-right: 1rem;
    height: 284px;
    width: 100%;
    text-align: center;
    margin-bottom: -46px;
  }
}

#icon-couch {
  max-width: 411px;
}

.icon__couch {
  overflow: hidden;
  margin-top: -2rem;
  text-align: right;
}

.icon__couch #sparkle-lg {
  -webkit-animation: sparkles-glowing-hard 3500ms infinite;
          animation: sparkles-glowing-hard 3500ms infinite;
  fill: #d4357f;
  opacity: 1;
  transform-origin: 35% 70%;
}

.icon__couch #sparkle-sm {
  opacity: 0.5;
  fill: #d4357f;
}

.icon__couch #sparkles-two {
  position: absolute;
}

.icon__couch #sparkles-two:nth-of-type(1) {
  top: 12%;
  right: 91%;
  width: 1.5rem;
}

@media (min-width: 576px) {
  .icon__couch #sparkles-two:nth-of-type(1) {
    top: 25%;
    right: 80%;
  }
}

@media (min-width: 768px) {
  .icon__couch #sparkles-two:nth-of-type(1) {
    right: 61%;
  }
}

@media (min-width: 992px) {
  .icon__couch #sparkles-two:nth-of-type(1) {
    right: 45%;
  }
}

@media (min-width: 1200px) {
  .icon__couch #sparkles-two:nth-of-type(1) {
    right: 38%;
  }
}

.icon__couch #sparkles-two:nth-of-type(1) #sparkle-lg {
  -webkit-animation-delay: 4.25s;
          animation-delay: 4.25s;
}

.icon__couch #sparkles-two:nth-of-type(2) {
  top: -1%;
  right: 14%;
  width: 2.5rem;
}

@media (min-width: 768px) {
  .icon__couch #sparkles-two:nth-of-type(2) {
    right: 11%;
  }
}

@media (min-width: 992px) {
  .icon__couch #sparkles-two:nth-of-type(2) {
    right: 8%;
  }
}

@media (min-width: 1200px) {
  .icon__couch #sparkles-two:nth-of-type(2) {
    right: -2.5rem;
    top: 50%;
  }
}

.icon__couch #sparkles-two:nth-of-type(2) #sparkle-lg {
  -webkit-animation-delay: 2.75s;
          animation-delay: 2.75s;
}

.icon__couch #sparkles-two:nth-of-type(2) #sparkle-sm {
  display: none;
}

#icon-neighborhood {
  max-width: 100%;
  height: auto;
}

.icon__neighborhood {
  overflow: hidden;
  margin-bottom: -0.25rem;
  text-align: center;
}

.icon__neighborhood #sparkle-lg {
  -webkit-animation: sparkles-glowing-hard 3500ms infinite;
          animation: sparkles-glowing-hard 3500ms infinite;
  fill: #d4357f;
  opacity: 1;
  transform-origin: 35% 70%;
}

.icon__neighborhood #sparkle-sm {
  opacity: 0.5;
  fill: #d4357f;
}

.icon__neighborhood #sparkles-two {
  position: absolute;
}

.icon__neighborhood #sparkles-two:nth-of-type(1) {
  top: 50%;
  left: 0;
  width: 1.5rem;
}

.icon__neighborhood #sparkles-two:nth-of-type(1) #sparkle-lg {
  -webkit-animation-delay: 4.25s;
          animation-delay: 4.25s;
}

.icon__neighborhood #sparkles-two:nth-of-type(2) {
  top: -25%;
  left: 24%;
  width: 2.5rem;
}

@media (min-width: 992px) {
  .icon__neighborhood #sparkles-two:nth-of-type(2) {
    top: -15%;
  }
}

.icon__neighborhood #sparkles-two:nth-of-type(2) #sparkle-lg {
  -webkit-animation-delay: 2.75s;
          animation-delay: 2.75s;
}

.icon__neighborhood #sparkles-two:nth-of-type(2) #sparkle-sm {
  display: none;
}

.icon__neighborhood #sparkles-two:nth-of-type(3) {
  top: -28%;
  left: 50%;
  width: 2rem;
}

@media (min-width: 992px) {
  .icon__neighborhood #sparkles-two:nth-of-type(3) {
    top: -13%;
  }
}

.icon__neighborhood #sparkles-two:nth-of-type(3) #sparkle-lg {
  -webkit-animation-delay: 3.6s;
          animation-delay: 3.6s;
}

.icon__neighborhood #sparkles-two:nth-of-type(4) {
  top: 55%;
  right: 0;
  width: 2rem;
  transform: scaleX(-1);
}

.icon__neighborhood #sparkles-two:nth-of-type(4) #sparkle-lg {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

#icon-kitchen {
  max-width: 560px;
}

.icon__kitchen {
  overflow: hidden;
}

.icon__kitchen #sparkle-lg {
  -webkit-animation: sparkles-glowing-hard 3500ms infinite;
          animation: sparkles-glowing-hard 3500ms infinite;
  fill: #d4357f;
  opacity: 1;
  transform-origin: 35% 70%;
}

.icon__kitchen #sparkle-sm {
  opacity: 0.5;
  fill: #d4357f;
}

.icon__kitchen #sparkles-two {
  position: absolute;
}

.icon__kitchen #sparkles-two:nth-of-type(1) {
  top: 65%;
  left: -1.5rem;
  width: 1.5rem;
  transform: scaleX(-1);
}

.icon__kitchen #sparkles-two:nth-of-type(1) #sparkle-lg {
  -webkit-animation-delay: 0;
          animation-delay: 0;
}

.icon__kitchen #sparkles-two:nth-of-type(2) {
  top: 30%;
  left: 27%;
  width: 1.5rem;
}

@media (min-width: 768px) {
  .icon__kitchen #sparkles-two:nth-of-type(2) {
    left: 25%;
  }
}

@media (min-width: 992px) {
  .icon__kitchen #sparkles-two:nth-of-type(2) {
    left: 17%;
  }
}

@media (min-width: 1200px) {
  .icon__kitchen #sparkles-two:nth-of-type(2) {
    left: 15%;
  }
}

.icon__kitchen #sparkles-two:nth-of-type(2) #sparkle-lg {
  -webkit-animation-delay: 4.25s;
          animation-delay: 4.25s;
}

.icon__kitchen #sparkles-two:nth-of-type(2) #sparkle-sm {
  display: none;
}

.icon__kitchen #sparkles-two:nth-of-type(3) {
  top: 32%;
  left: 92%;
  width: 2.5rem;
}

@media (min-width: 576px) {
  .icon__kitchen #sparkles-two:nth-of-type(3) {
    top: 50%;
    left: 99%;
  }
}

@media (min-width: 768px) {
  .icon__kitchen #sparkles-two:nth-of-type(3) {
    left: 82%;
  }
}

@media (min-width: 992px) {
  .icon__kitchen #sparkles-two:nth-of-type(3) {
    left: 61%;
  }
}

@media (min-width: 1200px) {
  .icon__kitchen #sparkles-two:nth-of-type(3) {
    left: 52%;
  }
}

.icon__kitchen #sparkles-two:nth-of-type(3) #sparkle-lg {
  -webkit-animation-delay: 2.75s;
          animation-delay: 2.75s;
}

.navbar-collapse {
  flex-direction: column;
}

.navbar-brand {
  width: 125px;
}

@media (min-width: 768px) {
  .navbar-brand {
    width: 200px;
  }
}

.navbar__top-row .btn {
  font-size: 0.875rem;
  margin-right: 1rem;
}

.navbar__bug {
  color: #d4357f;
  margin-left: 2rem;
  text-align: center;
  width: 170px;
  height: 49px;
}

.navbar__bug,
.navbar__bug small {
  font-weight: 700;
}

.navbar__bug small {
  display: block;
}

.navbar__bug a {
  background-image: linear-gradient(180deg, transparent 96%, #d4357f 0);
  background-size: 0 100%;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size 0.2s ease;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
}

.navbar__bug a:hover,
.navbar__bug a:focus {
  background-size: 100% 100%;
  color: #d4357f;
}

.navbar__bug a:active {
  background-image: linear-gradient(180deg, transparent 0, #d4357f 0);
}

.navbar .nav-link {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  font-size: 0.875rem;
  font-weight: 600;
}

.navbar .nav-link:hover {
  transform: translateY(-2px);
}

.navbar .nav-link svg {
  color: #d4357f;
}

.navbar .nav-link:hover svg {
  color: #20b7f0;
}

.navbar .navbar-toggler {
  border: none;
}

.nav-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1020;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.nav-backdrop.show {
  display: block;
}

.nav-tabs {
  font-weight: 600;
}

.nav-tabs .nav-item {
  margin-right: 0.25rem;
}

.nav-tabs .nav-link {
  background-color: #fff4f7;
}

.nav-tabs .nav-link:not(.active):hover {
  background-color: #d4357f;
  color: #fff;
}

.page-title .h1,
.page-title h1 {
  font-size: 52px;
  color: #d4357f;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

@media (max-width: 1200px) {
  .page-title .h1,
  .page-title h1 {
    font-size: 48px;
  }
}

.reviews__accordion .card-body {
  padding-top: 0;
}

@media (max-width: 575.98px) {
  .reviews__accordion .card-body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.reviews__review {
  background-color: #fff;
  border: 1px solid #dbdbdb;
}

@media (min-width: 768px) {
  .reviews__review {
    border: none;
  }
}

.reviews__review .card-header {
  background-color: #fff;
  border: 0;
  margin: 0;
  padding: 1.25rem 1.25rem;
}

@media (min-width: 576px) {
  .reviews__review .card-header {
    padding: 2.5rem 2.5rem;
  }
}

.reviews__review.accordion__card--active,
.reviews__review.accordion__card--active .card-header {
  background-color: #e8f8fe !important;
}

.sticky-cta {
  /* display: none; */
}

.sticky-cta .btn {
  font-size: 0.875rem;
}

.sticky-cta .btn:first-of-type {
  margin-right: 1rem;
}

@media (max-width: 767.98px) {
  .sticky-cta .btn {
    font-size: 0.75rem;
    margin-bottom: 0;
    padding: 0.28125rem 0.75rem;
  }

  .cta-buttons--center .btn {
    font-size: 0.75rem;
    padding: 0.28125rem 0.75rem;
    margin: 0 0.5rem 0 0.5rem;
  }

  .about-cta .cta-buttons--center {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 374.98px) {
  .sticky-cta .btn {
    padding: 0.28125rem 0.375rem;
  }

  .sticky-cta .btn:first-of-type {
    margin-right: 0.25rem;
  }
}

@media (max-width: 575.98px) {
  .sticky-cta .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.sticky-cta--stuck {
  -webkit-animation: stickyUp 500ms 1 forwards;
          animation: stickyUp 500ms 1 forwards;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.05),
    0 -2px 4px -1px rgba(0, 0, 0, 0.03);
  position: fixed;
  right: 0;
  bottom: -1px;
  left: 0;
  z-index: 30020;
  display: block;
}

.sticky-cta--hidden {
  display: none;
}

@keyframes stickyUp {
  0% {
    bottom: -100px;
  }

  100% {
    bottom: -1px;
  }
}

.sticky-cta__phone a {
  background-image: linear-gradient(180deg, transparent 96%, #fff 0);
  background-size: 0 100%;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size 0.2s ease;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.sticky-cta__phone a:hover,
.sticky-cta__phone a:focus {
  background-size: 100% 100%;
  color: #fff;
}

/* .sticky-cta__phone a:active {
  background-image: linear-gradient(180deg, transparent 0, #fff 0);
} */

.sticky-cta__phone .sparkles {
  margin-right: 1rem;
  width: 1.5rem;
}

.sticky-cta--book {
  /* box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.05),
    0 -2px 4px -1px rgba(0, 0, 0, 0.03); */
}

.sticky-cta--book .btn {
  margin-right: 0;
  font-size: 1rem;
}

.sticky-cta--book .sparkles {
  display: none;
}

.about-text-columns {
  max-width: 100%;
}

.book__total-cost {
  text-align: left;
  margin-bottom: -0.35rem;
}

.cta-schedule-button {
  margin-bottom: 0 !important;
}

@media (max-width: 489.98px) {
  .estimated-price-col {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .book__total-cost {
    text-align: center;
  }

  .cta-schedule-button .btn-primary {
    margin-top: 1rem;
    margin-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .text-columns {
    -moz-column-count: 1;
         column-count: 1;
    grid-column-gap: 60px;
    -moz-column-gap: 60px;
         column-gap: 60px;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .text-columns-3 {
    -moz-column-count: 2;
         column-count: 2;
    grid-column-gap: 30px;
    -moz-column-gap: 30px;
         column-gap: 30px;
  }
}

@media (min-width: 1200px) {
  .text-columns-3 {
    -moz-column-count: 3;
         column-count: 3;
    grid-column-gap: 60px;
    -moz-column-gap: 60px;
         column-gap: 60px;
  }
}

@media (min-width: 576px) {
  .text-columns-4 {
    -moz-column-count: 2;
         column-count: 2;
    grid-column-gap: 30px;
    -moz-column-gap: 30px;
         column-gap: 30px;
  }
}

@media (min-width: 768px) {
  .text-columns-4 {
    -moz-column-count: 3;
         column-count: 3;
    grid-column-gap: 60px;
    -moz-column-gap: 60px;
         column-gap: 60px;
  }
}

@media (min-width: 1200px) {
  .text-columns-4 {
    -moz-column-count: 4;
         column-count: 4;
  }
}

.home-services__service #sparkle-lg {
  -webkit-animation: sparkles-glowing-hard 3500ms infinite;
          animation: sparkles-glowing-hard 3500ms infinite;
  fill: #d4357f;
  opacity: 1;
  transform-origin: 35% 70%;
}

.home-services__service #sparkle-sm {
  opacity: 0.5;
  fill: #d4357f;
}

.home-services__service:nth-of-type(1) #sparkles-two {
  position: absolute;
  bottom: 1.5rem;
  left: -2.5rem;
  width: 1.5rem;
  transform: scaleX(-1);
}

.home-services__service:nth-of-type(1) #sparkle-lg {
  -webkit-animation-delay: 0;
          animation-delay: 0;
}

.home-services__service:nth-of-type(2) #sparkles-two {
  position: absolute;
  top: -2.5rem;
  left: 2.5rem;
  width: 1.5rem;
}

.home-services__service:nth-of-type(2) #sparkle-lg {
  -webkit-animation-delay: 4.25s;
          animation-delay: 4.25s;
}

.home-services__service:nth-of-type(2) #sparkle-sm {
  display: none;
}

.home-services__service:nth-of-type(3) #sparkles-two {
  position: absolute;
  top: 1.5rem;
  right: -2.5rem;
  width: 1.5rem;
}

.home-services__service:nth-of-type(3) #sparkle-lg {
  -webkit-animation-delay: 2.75s;
          animation-delay: 2.75s;
}

.home-services__icon {
  margin-left: auto;
  margin-right: auto;
  max-width: 150px;
  overflow: visible;
  width: auto;
}

@media (min-width: 992px) {
  .home-services__icon {
    max-width: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (min-width: 1200px) {
  .home-services__icon {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.home-services__icon .embed-responsive-item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.services .sticky-cta--stuck {
  -webkit-animation: none;
          animation: none;
}

.services__selection {
  top: 1rem;
}

.services__selection .custom-control-label {
  font-size: 1.5rem;
  color: #d4357f;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

@media (max-width: 1200px) {
  .services__selection .custom-control-label {
    font-size: calc(1.275rem + 0.3vw);
  }
}

.services__selection .custom-control-label::before,
.services__selection .custom-control-label::after {
  top: 0.55rem;
}

/* .services__nav-tabs .nav-tabs {
  display: none;
  visibility: hidden;
} */

.services__nav-tabs .nav-tabs.show {
  display: flex;
  visibility: visible;
}

.services__nav-tabs .nav-item {
  margin-right: 0;
  width: 100%;
}

@media (max-width: 991.98px) {
  .services__nav-tabs .nav-item {
    margin-bottom: 4px;
  }

  .services__nav-tabs .nav-item.active {
    order: 99;
    margin-bottom: -1px;
  }
}

@media (min-width: 992px) {
  .services__nav-tabs .nav-item {
    margin-right: 0.25rem;
    width: auto;
  }
}

@media (max-width: 991.98px) {
  .services__nav-tabs .nav-link {
    border-color: #c9c9c9;
  }
}

/* .services__tab-content .tab-content {
  display: none;
  visibility: hidden;
} */

.services__tab-content .tab-content.show {
  display: flex;
  visibility: visible;
}

.services__tab-content .tab-pane {
  max-width: 100%;
}

.services__tab-content .cta-buttons {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.services__room-list li {
  margin-bottom: 1rem;
  /* padding-left: 1.5em; */
  position: relative;
  page-break-inside: avoid;
  -moz-column-break-inside: avoid;
       break-inside: avoid-column;
}

.services__house--desktop {
  top: var(--header-height);
}

.services__house--mobile {
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 30%;
  z-index: 1020;
}

@media (min-width: 375px) {
  .services__house--mobile {
    width: 35%;
  }
}

.services__house--stuck {
  position: fixed;
}

#services-house > g {
  opacity: 0;
}

#services-house > g#services-house-house,
#services-house > g.active {
  opacity: 1;
}

.about main {
  position: relative;
}

main {
  /* margin-top: -87vh; */
  font-family: 'Gotham Book';
}

.locations__franchise {
  border-bottom: 1px solid #dbdbdb;
}

.locations__accordion .card-header,
.locations__accordion .collapse,
.locations__accordion .collapse.show,
.locations__accordion .card {
  background-color: transparent;
}

.locations__accordion .card-header,
.locations__accordion .card {
  border-bottom: 1px solid #dbdbdb;
  padding: 0;
  margin: 0.5em 0;
}

.locations__accordion .card-header,
.locations__accordion .collapse:not(.show) {
  cursor: pointer;
}

.locations__accordion > .card > .card-header {
  /* prevent disabled states from having a second underline */
  border: none;
  margin: 0;
  color: #757575;
  cursor: default;
}

.book .page-title h1 {
  color: #4d4d4d;
  font-weight: 700;
  font-size: 3.75rem;
}

.book__steps {
  text-align: center;
  position: relative;
  line-height: 1.2;
}

.book__steps::before {
  background-color: #4d4d4d;
  content: '';
  position: absolute;
  top: 0.5rem;
  left: 16.67%;
  right: 16.67%;
  height: 1px;
}

.book__indicator {
  border-radius: 50%;
  background-color: #4d4d4d;
  margin: 0 auto 0.25rem;
  height: 1rem;
  width: 1rem;
}
.book__indicator::hover {
  background-color: var(--blue);
}

.book__indicator.active {
  background-color: #d4357f;
}

.book__gated {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0.5;
  /* pointer-events: none; */
}

.book__gated.active {
  opacity: 1;
  pointer-events: auto;
}

@media (max-width: 1200px) {
  .book__gated {
    text-align: center;
  }
  .select__package-col {
    text-align: center !important;
  }
  .select__package-col label {
    margin-bottom: 15px;
  }
}

.book__select-buttons .btn {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin-left: auto;
  margin-right: auto;
  min-width: 200px;
  margin-bottom: 0.5rem;
}

@media (min-width: 992px) {
  .book__select-buttons .btn {
    margin-left: 0;
    margin-right: 1rem;
  }

  .book__select-buttons .btn:nth-of-type(3n) {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .book__select-buttons {
    width: 123%;
  }
}

.book__select-buttons .btn.show {
  display: block;
  visibility: visible;
}

@media (min-width: 992px) {
  .book__select-buttons .btn.show {
    display: inline-block;
  }
}

@media (max-width: 992px) {
  .book__select-buttons {
    margin-top: 15px !important;
  }
}

.book__select-buttons .btn.active {
  background-color: #751d46 !important;
  position: relative;
}

@media (min-width: 992px) {
  .book__select-buttons .btn.active::after {
    border: solid transparent;
    border-bottom-color: #e8f8fe;
    border-width: 2rem;
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    bottom: -2.75rem;
    left: calc(50% - 2rem);
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .book__select-buttons {
    /* width: 123%; */
    margin-left: -50px;
  }
}

.book__datepicker.flatpickr {
  display: none;
}

.book__timepicker .btn {
  width: 100%;
  margin: 0.25rem 0;
}

@media (min-width: 768px) {
  .book__timepicker {
    margin-top: 3rem;
  }
}

/* Custom Scollbar */
*::-webkit-scrollbar {
  width: 12px;
}
/* *::-webkit-scrollbar-button{
  background-color: #ccc;
}

*::-webkit-scrollbar-track {
  background: linear-gradient(0.25turn, #eef, white, #eef);
} */

*::-webkit-scrollbar-thumb {
  background: #888;
  width: 6px;
  border: 3px solid white;
  border-radius: 28px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #d4357f;
}
@media (pointer: coarse) {
  *::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    width: 6px;
  }
}

body {
  overflow-x: hidden;
}

.store-picker-button {
  border: none;
  background: transparent;
  font-size: 0.875rem;
  font-weight: 700;
  color: #4d4d4d;
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 0;
  position: relative;
}

.store-picker-card {
  border-bottom: 1px solid rgba(216, 216, 216, 0.72);
}
.store-picker-card:last-child {
  border: none;
}

.store-picker-card .card-title {
  margin-bottom: 5px;
}

.content-drawer {
  --width: 260px;
  /* offscreen */
  position: absolute;
  margin-left: calc((var(--width) * -1) - 20px);
  left: calc((var(--width) * -1) - 20px);
  width: var(--width);
  top: 0;
  /* height: 100vh; */
  z-index: 1010;
  background-color: white;
  padding: 0px;
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  bottom: 0;
  overflow-y: hidden;
  box-shadow: 35px 30px 78px -44px rgba(0, 0, 0, 0.2);
}

.content-drawer.active {
  margin-left: 280px;
  /* position: sticky; */
}

@media (max-width: 1190px) {
  .store-picker-top{
    margin-top: 7rem !important;
    }
}

@media (max-width: 575.98px) {
  .content-drawer {
    --width: 100vw;
  }

  .content-drawer.active {
    --width: 100vw;
    left: 0;
    margin-left: 0;
  }

}
.scroll-wrapper {
  margin-top: 10px;
  overflow-y: scroll;
  box-shadow: inset 0px 28px 15px -23px rgba(0, 0, 0, 0.08);
  transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.content-drawer .scroll-wrapper {
  height: calc(100vh - (var(--header-height) * 5) + 120px);
  padding-bottom: 40px;

}

.scroll-wrapper.modal-scroll {
  max-height: 300px;
}

.store-link h5 {
  color: var(--secondary);
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.store-link h5:hover {
  color: var(--primary);
}

.toast-wrapper {
  position: fixed;
  top: calc(64px);
  top: calc(var(--header-height, 64px));
  right: 15px;
  transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 9999;
}

.toast-wrapper.confirm-toast-wrapper{
  width: 30%;
  min-width: 350px;
}

.locations__franchise_name {
  color: #cf2680;
  color: var(--dark-pink);
  font-family: 'Gotham Medium';
}

.locations__franchise_name a {
  text-transform: uppercase;
}

.locations__franchise,
.franchisee-location-address {
  color: #7b6c73 !important;
  color: var(--grey) !important;
}

.franchisee-location-address:hover {
  color: #cf2680 !important;
  color: var(--dark-pink) !important;
}

.franchisee-location-phone {
  color: #cf2680 !important;
  color: var(--dark-pink) !important;
}

.toast-error {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.25rem;
  color: #fff;
  padding: 0.75rem 1.25rem;
  text-align: center;
  background: #cf2680;
  background: var(--dark-pink);
  margin-bottom: 10px;
}
@media (max-width: 1199.98px) {
  .mobile-nav-columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0.5em;
  }
}

@media (max-width: 575.98px) {
  .mobile-nav-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0.5em;
  }

  a.header-link:nth-child(2) {
    grid-column-start: 1;
    grid-row-start: 4;
  }

  a.header-link:nth-child(4) {
    grid-column-start: 1;
    grid-row-start: 5;
  }
}

/* .store-card-horizontal{
  display: grid;
  grid-template-columns: auto 150px;
  grid-template-rows: 24px auto;

  grid-template-areas: 
  " name | phone "
  " address | button";
}

.store-card-horizontal>.store-title{
  grid-area: title;
}

.store-card-horizontal>.store-phone-number{
  grid-area: phone;
}

.store-card-horizontal>.store-address{
  grid-area: address;
}

.store-card-horizontal>.btn{
  grid-area: button;
} */

.dynamic-page-embed iframe {
  width: 100%;
  max-height: 100%;
  height: 400px;
}

.dynamic-page-image {
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

@media (max-width: 991.98px) {
  .dynamic-page-image-with-text {
    width: 100% !important;
  }
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.local-content-dropdown-menu li {
  color: var(--c-gray-800);
  margin-bottom: -1px;
  cursor: pointer;
  background-color: transparent;
  flex: 1 1 auto;
  margin-right: 0.25rem;
  border-color: var(--pink);
  padding: 0.5rem;
  /* padding-left: 0; */
  font-weight: 700;
  font-size: 0.875rem;
}

.local-content-dropdown-menu a {
  color: var(--c-gray-800);
  margin-bottom: -1px;
  cursor: pointer;
  background-color: transparent;
  /* text-align: center; */
  border-color: var(--pink);
  padding: 0.5rem;
  font-weight: 700;
  font-size: 0.875rem;
  display: block;
  max-width: 200px;
}

/* .local-content-dropdown-menu li:hover, */
/* .local-content-dropdown-menu li:focus, */
.local-content-dropdown-menu a:hover,
.local-content-dropdown-menu a:focus {
  color: var(--pink);
}

.local-content-dropdown {
  display: none !important;
  -webkit-animation: growDown 300ms ease-in-out;
          animation: growDown 300ms ease-in-out;
  transform-origin: top;
}

.local-content-dropdown-menu:hover .local-content-dropdown,
.local-content-dropdown-menu:focus .local-content-dropdown {
  display: block !important;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@media (max-width: 1199.98px) {
  .local-content-dropdown-menu li {
    padding: 0.5rem 0;
  }

  .local-content-dropdown {
    margin-left: -0.5rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.page-link {
  position: relative;
  display: block;
  font-weight: 700;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--pink);
  background-color: transparent;
  /* border: 1px solid #dee2e6; */
}

.welcome-packet-button:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.welcome-packet-button .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.paginator-nav {
  width: 40% !important;
}

.paginator-nav .page-item .page-link {
  cursor: pointer;
  transition: all 200ms ease;
  position: relative;
  z-index: 3;
}

.paginator-nav .page-item.disabled {
  pointer-events: none;
}

.paginator-nav .page-item.active {
  position: relative;
  color: #fff;
}

.paginator-nav .page-item.active .page-link:after {
  transition: all 200ms ease;
  content: '';
  z-index: -1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #d4357f;
}

.paginator-nav .page-item.active .page-link {
  color: #fff;
  border-radius: 50%;
  background: #cf2680;
  background: var(--dark-pink);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-top: 5px;
}

.paginator-nav .page-item .page-link:after {
  transition: all 200ms ease;
  content: '';
  z-index: 2;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.paginator-nav .page-item .page-link:hover:after {
  background-color: rgba(0, 0, 0, 0.05);
}

.paginator-nav .page-item.active .page-link:hover:after {
  background-color: #751d46;
  content: '';
  z-index: -1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (max-width: 991.98px) {
  nav.paginator-nav {
    width: 100% !important;
  }
}

/* .services__room-list li::before {
  background: url(../images/sparkle-li.svg) top left no-repeat;
  background-size: 0.75em;
  content: '';
  margin-right: 1em;
  position: absolute;
  left: 0;
  top: 0.3em;
  height: 1.3em;
  width: 1.3em;
} */

.swoopty-bg::before {
  content: '';
  background-image: bottom url(/_next/static/media/hero-clip-mobile.b319629d.svg);
  background-size: 106% 100%;
  position: absolute;
  top: 40%;
  right: 0;
  bottom: 20%;
  left: 0;
  z-index: -1;
}

@media (min-width: 576px) {
  .swoopty-bg::before {
    background-image: url(/_next/static/media/hero-clip.b5b96673.svg);
  }
}

.swoopty-bg {
  position: relative;
}

.swoopty-bg::after {
  background-color: #fff;
  content: '';
  position: absolute;
  top: 80%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
}

.swoopty-bg .container {
  position: relative;
  z-index: 10;
}

.swoopty-bg--fixed {
  height: 1px;
  padding: 0 !important;
}

.swoopty-bg--fixed::before {
  background-color: #fff4f7;
  height: 152px;
  top: calc(70vh - 152px);
  bottom: 0;
}

.swoopty-bg--fixed::after {
  background-color: #fff4f7;
  top: 0;
  height: 70vh;
}

@media (max-width: 414px) {
  .swoopty-bg--fixed::after {
    height: 78vh;
  }
}
@media (max-width: 375px) and (max-height: 812px) {
  .swoopty-bg--fixed::after {
    height: 70vh;
  }
}
@media (max-width: 375px) and (max-height: 667px) {
  .swoopty-bg--fixed::after {
    height: 85vh;
  }
}

@media (max-width: 320px) {
  .swoopty-bg--fixed::after {
    height: 110vh;
  }
}

.blog-card {
  transition: all 0.4s ease;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: rgb(31 44 60 / 10%) 0px 5px 40px -10px;
}

.blog-card img {
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 230px;
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
}

.blog-card:hover {
  box-shadow: rgb(30 45 62 / 21%) 0px 10px 15px -10px,
    rgb(31 44 60 / 10%) 0px 2px 30px -2px;
}

.carousel-inner {
  /* padding: 1rem 0; */
}

.review-card {
  border-radius: 5px;
  /* box-shadow: rgb(30 45 62 / 21%) 0px 10px 15px -10px,
    rgb(31 44 60 / 10%) 0px 2px 30px -2px; */
  /* border: solid 1px #4d4d4d; */
  box-shadow: rgb(0 0 0 / 15%) 0px 3px 8px;
  width: 350px;
  height: 375px;
  padding: 1rem;
}

.carousel-control-next {
  right: -15px;
}

.carousel-control-prev {
  left: -15px;
}

@media (max-width: 767.9px) {
  .review-card {
    width: 100%;
    height: 400px;
  }

  .carousel-control-next {
    right: 0;
  }

  .carousel-control-prev {
    left: 0;
  }
}

@media (max-width: 1199.9px) {
  .reviews-stars-cta-row {
    justify-content: center !important;
  }

  .reviews-cta .stars-row {
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 768px) {
  .reviews-buttons {
    justify-content: center !important;
    white-space: nowrap;
    flex-wrap: nowrap;
  }

  .reviews-buttons .btn-blue {
    padding: 0.5rem;
  }

  .first-review-button {
    margin-bottom: 1.75rem !important;
  }
}

@media (max-width: 575.98px) {

  .reviews-buttons {
    justify-content: center !important;
    white-space: nowrap;
    flex-wrap: wrap;
  }
}

.payment-button .payment-tooltip {
  visibility: hidden;
  width: 260px;
  background-color: #d4357f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 23px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 3;
  opacity: 0;
  transition: all 0.2s ease;
}

.payment-button:hover .payment-tooltip {
  visibility: visible;
  opacity: 1;
}
.payment-button:disabled:hover {
  opacity: 1;
}

@media (max-width: 767px) {
  .payment-tooltip{
    /* top: -100% !important; */
    left: -25px !important;

  }
}

@media (max-width: 468px) {
  .form-group-sqft,
  .form-group-pets {
    flex-basis: 50%;
  }
}

#CollectJSInlineccexp,
#CollectJSInlineccnumber,
#CollectJSInlinecvv {
  border: none;
  height: 40px !important;
}

@supports (-webkit-backdrop-filter: blur(1px)) {
  .blog-banner-img {
    max-height: 58vh;
  }
}

@media (max-width: 576px) {
  .blog-banner-img {
    max-height: 38vh;
  }
}

.gradient {
  background-color: rgba(208, 216, 226, 0.48);
  /* IE9, iOS 3.2+ */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIxMDIuOSUiIHkxPSI5NC45JSIgeDI9Ii0yLjklIiB5Mj0iNS4xJSI+PHN0b3Agc3RvcC1jb2xvcj0iI2IyYzBjZiIgc3RvcC1vcGFjaXR5PSIwIiBvZmZzZXQ9IjAuMTI5Ii8+PHN0b3Agc3RvcC1jb2xvcj0iI2YwZjNmNiIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAuMzY2Ii8+PHN0b3Agc3RvcC1jb2xvcj0iI2YwZjNmNiIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAuNTE5Ii8+PHN0b3Agc3RvcC1jb2xvcj0iI2IyYzBjZiIgc3RvcC1vcGFjaXR5PSIwIiBvZmZzZXQ9IjAuOTMyIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI3ZzZ2cpIiAvPjwvc3ZnPg==);
  /* Android 2.3 */
  /* IE10+ */
  background-image: linear-gradient(
    310deg,
    rgba(178, 192, 207, 0) 12.9%,
    rgb(240, 243, 246) 36.6%,
    rgb(240, 243, 246) 51.9%,
    rgba(178, 192, 207, 0) 93.2%
  );
  background-image: -ms-linear-gradient(
    140deg,
    rgba(178, 192, 207, 0) 12.9%,
    rgb(240, 243, 246) 36.6%,
    rgb(240, 243, 246) 51.9%,
    rgba(178, 192, 207, 0) 93.2%
  );
  background-repeat: no-repeat;
}

/* IE8- CSS hack */
@media \0screen\, screen\9 {
  .gradient {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00b2c0cf",endColorstr="#00b2c0cf",GradientType=1);
  }
}
@keyframes shimmer {
  0% {
    background-position: -500px;
  }

  100% {
    background-position: 500px;
  }
}
.shimmer-container {
  width: 100%;
  margin-top: 2px;
}
.shimmer {
  font-weight: 300;
  font-size: 0.65rem;
  padding: 1px 84px;
  display: inline;
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  -webkit-animation: shimmer 2.5s infinite forwards;
          animation: shimmer 2.5s infinite forwards;
}

.btn-learn-more {
  color: #d4357f;
  -webkit-text-decoration: 'underline';
          text-decoration: 'underline';
  cursor: 'pointer';
}
.btn-learn-more:hover {
  opacity: 0.7;
}

@media (max-width: 1000px) {
  .suggested_address {
    margin-top: 15px;
  }
}
.suggested_address,
.current_address {
  box-shadow: 0px 0px 6px #878787;
  padding: 20px;
  border-radius: 15px;
}
#cleaningAddressLine1 {
  max-height: 100px !important;
  background: white;
  z-index: 2323;
  border: 1px solid #d4d4d4;
}
#cleaningAddressLine1 a {
  font-size: 14px;
  color: black;
}

.CookieConsent {
  width: 500px !important;
}
@media (max-width: 576px) {
  .CookieConsent {
    /* width: 90vw !important;
    left: 0 !important; */
    width: 92% !important;
    right: auto !important;
    right: initial !important;
  }
  .cookie_disclaimer_title {
    font-size: 16px;
  }
  .CookieConsent span {
    font-size: 12px;
  }
  .cookie_disclaimer_button button {
    font-size: 12px !important;
  }
}
.tip-bottom-text {
  margin-top: 25px;
  color: gray;
  font-size: 12px;
}
.tip-container {
  display: flex;
  justify-content: space-between;
}
.tip-container .active {
  transition: transform 0.4s ease-out;
  transform: scale(1.1);
  border-color: #d4367f;
}
.tip-container .active svg {
  /* color:#ff9e00 !important; */
}
.tip-container .active h5 {
  color: #d4357f !important;
}
.tip-box {
  /* border: 2px solid #fff4f7; */
  width: 120px;
  text-align: center;
  border-radius: 10px;
  /* color: white; */
  background: #fff4f7;
  box-shadow: 0px 0px 2px 1px #da5592;
  position: relative;
}
.stable:hover {
  opacity: 0.6;
  cursor: pointer;
}
/* .tip-box > h5 {
  margin-bottom: 14px;
  padding-top: 10px;
  color: black;
} */
.tip-box span {
  padding: 2px;
  margin-right: 2px;
}
.tip-box input:focus {
  outline: none;
}
.tip-box input {
  outline: 0;
  border: none;
  width: 35%;
  background: none;
}
/* .tip-box > svg {
  position: absolute;
  top: -8px;
  right: -12px;
  -webkit-animation: rotateHeart 2.5s infinite alternate;
  animation: rotateHeart 2.5s infinite alternate;
} */

.tip-main-container {
  /* display: flex;
  flex-direction: column; */
  position: relative;
}
.tip-main-container button {
  /* width: 30%;
  align-self: end;
  margin-top: 20px; */
  /* width: 38%; */
  /* align-self: end;
  display: flex; */
  margin-top: 20px;
}

@media screen and (min-width: 550px) {
  .tip-box-thank-you {
    width: 100px !important;
  }
}

@media screen and (max-width: 550px) {
  .tip-main-container button {
    width: 50%;
  }
  .tip-box {
    width: 100px;
    height: 80px;
  }
  /* .tip-box > h5 {
    font-size: 20px;
    margin-bottom: 8px;
  } */
  .tip-box > p {
    font-size: 16px;
    /* margin-bottom: 5px; */
  }
  .tip-box input {
    width: 60%;
  }
}
@media screen and (max-width: 450px) {
  .tip-main-container button {
    width: 50%;
  }
  .tip-box {
    width: 80px;
    height: 80px;
  }
  .tip-box > h5 {
    font-size: 18px;
    /* margin-bottom: 8px; */
  }
  .tip-box > p {
    font-size: 14px;
    /* margin-bottom: 5px; */
  }
  .tip-box input {
    width: 60%;
  }
}
@media screen and (max-width: 370px) {
  .tip-main-container button {
    width: 50%;
  }
  .tip-box {
    width: 70px;
    height: 70px;
  }
  .tip-box > h5 {
    font-size: 16px;
    /* margin-bottom: 8px; */
  }
  .tip-box > p {
    font-size: 14px;
    /* margin-bottom: 5px; */
  }
  .tip-box input {
    width: 60%;
  }
}

@-webkit-keyframes rotateHeart {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

@keyframes rotateHeart {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

.tip-heading {
  /* position: relative; */
}
.tip-heading > span {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  color: #d4367f;
}

.tooltip-tip-selection {
  /* position: relative; */
  display: inline-block;
}
.tip-disable {
  opacity: 0.5;
  pointer-events: none;
}

.welcome-packet-button-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: space-evenly;
}

.welcome-packet-button-container p {
  margin-bottom: 0;
}

.welcome-packet-button {
  border: none;
  border-radius: 4px;
  background: none;
  transition: all ease 0.3s;
  font-size: 20px;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

.welcome-packet-content {
  width: 100% !important;
  height: 100% !important;
}
#SubmitPaymentMethod {
  background: none;
  color: #cf2680;
  color: var(--dark-pink);
  border: solid 1px #cf2680;
  border: solid 1px var(--dark-pink);
}
#SubmitPaymentMethod:hover {
  color: #fff !important;
  background-color: #cf2680 !important;
  background-color: var(--dark-pink) !important;
}

.confirm-checkbox input{
  width: 20px;
  height: 20px;
}
.gratuity-button:hover a{
  color: #cf2680 !important;
  color: var(--dark-pink) !important;
}


.gratuity-button:hover,.gratuity-button:active{
  background-color: #fff !important;
  color: #cf2680 !important;
  color: var(--dark-pink) !important;
}

.gratuity-button a{
  color: #fff !important;
}
.toast-header span{
  color: white;
  padding-right: 7px;
}
.nav-funnel{
  display: none;
}
@media (max-width: 1192px) {
  .nav-funnel{
    display: block;
  }
}

input:disabled {
  cursor: not-allowed;
}
@font-face {
  font-family: 'Coves Bold';
  src: url(/_next/static/media/Coves-Bold.f79e3dd0.otf);
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Book';
  src: url(/_next/static/media/Gotham-Book.4195c67f.otf);
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Medium';
  src: url(/_next/static/media/Gotham-Medium.22a46729.otf);
  font-display: swap;
}
@font-face {
  font-family: 'Gotham Bold';
  src: url(/_next/static/media/Gotham-Bold.5306f5d5.otf);
  font-display: swap;
}
@font-face {
  font-family: 'Gotham Book Italic';
  src: url(/_next/static/media/Gotham-BookItalic.29b407c5.otf);
  font-display: swap;
}
@font-face {
  font-family: 'Gotham Medium Italic';
  src: url(/_next/static/media/Gotham-MediumItalic.acdf3a2b.otf);
  font-display: swap;
}

.about-us-top-content h1 {
  color: var(--dark-pink);
  font-family: 'TiemposFine Regular';
  padding: 50px 0px;
}
.about-us-franchisee-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 200px;
}
.about-us-top-content {
  display: flex;
  flex-direction: column;
  /* margin-top: 70px; */
}
.about-us-top-content h5,
.about-us-franchisee-content h5 {
  color: var(--dark-pink);
  margin-bottom: 40px;
  font-family: 'Gotham Medium';
}
.about-us-franchisee-content h5 {
  margin-top: 80px;
}
.about-us-photo-container {
  position: relative;
  max-width: 800px;
  margin-top: 50px;
}
.about-us-photo-container img {
  width: 400px;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;

}
.about-us-circle {
  position: absolute;
  width: 400px;
  height: 400px;
  background-color: #f9ddebd1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  right: 40px;
  bottom: -130px;
  color: var(--dark-pink);
}
.about-us-circle p {
  width: 70%;
  margin: 5px auto;
}

@media screen and (max-width: 992px) {
  .about-us-franchisee-content,
  .about-us-text {
    flex-direction: column-reverse;
  }
  .about-us-text {
    margin-top: 200px;
  }
  .about-us-photo-container img {
    width: 250px;
    height: 250px;
  }
  .about-us-circle {
    width: 250px;
    height: 250px;
    left: 110px;
    bottom: -190px;
  }
  .about-us-circle {
    font-size: 12px;
  }
}

.booking-input-container {
  /* background: var(--light-pink); */
}

.booking-input-container input,
.booking-input-container select,
#cleaningAddressLine1 {
  border: solid 1px var(--grey);
  border-radius: 0;
  font-family: 'Gotham Medium';
}
#cleaningAddressLine1:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ffd8ea;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}
.form-section-header {
  color: var(--dark-pink);
  font-family: 'Gotham Medium';
  /* margin-top: 3rem; */
  font-size: var(--paragraph-text);
  width: 100%;
  text-align: left;
}

.booking-terms-disclaimer {
  font-size: 12px;
  color: var(--grey);
  /* padding-top: 2rem; */
  line-height: 2;
}

.form-section-labels {
  color: var(--dark-pink);
  font-size: 14px;
  font-family: 'Gotham Book';
  text-align: left;
  width: 100%;
}

.book__select-buttons .btn.active::after {
  display: none;
}

.book__select-buttons {
  /* margin-bottom: 6rem !important; */
}

.clear-lead-button {
  border: solid 1px transparent;
  transition: .3s ease all;
  background: var(--dark-pink);
  color: var(--white);
  margin-left: 20px;
}

.clear-lead-button:hover {
  background: transparent;
  color: var(--dark-pink);
  border: solid 1px var(--dark-pink);
}

.booking-accordion-card .card-header {
  border-bottom: none !important;
}

.booking-accordion-card .card-body {
  padding: 2rem 0;
}
.store-picker-card .card-body {
  padding-bottom: 0px !important;
}

.tip-container {
  font-family: 'Gotham Bold';
}

.tip-box {
  border: solid 1px var(--grey);
  border-radius: 0;
  box-shadow: none;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tip-box h5,
.tip-box p {
  margin-bottom: 0;
  color: var(--grey);
}

.tip-box input {
  border: none;
  /* width: 100%; */
  /* margin: 0 auto; */
  color: var(--white);
  /* width: 65px; */
}
.tip-box div:before {
  content: '$';
}
.tip-box.active {
  transform: none;
  border-color: var(--dark-pink);
  opacity: 1;
  background: var(--dark-pink);
  color: var(--white);
}

.tip-box.active h5,
.tip-box.active p {
  color: var(--white) !important;
}

.booking-info-text {
  color: #808080;
  font-size: 14px;
}

.thank-you-hero-container {
  position: relative;
  /* margin-top: 2rem; */
  /* height: 50vh; */
}

.thank-you-hero-background {
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.thank-you-hero-text-section {
  padding: 4rem 0;
  font-family: 'Gotham Book';
}

.thank-you-hero-text-section h2 {
  color: var(--dark-pink);
  font-family: 'TiemposFine Semibold';
}

.thank-you-hero-text-section p {
  color: var(--grey);
  font-size: 20px;
  line-height: 1.5;
}

.booking-input-container h3 {
  color: var(--dark-pink);
  font-size: 24px;
  overflow-x: hidden;
}

.thank-you-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  margin-top: 4rem !important;
}

.thank-you-page-card {
  background: var(--white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 300px;
  color: var(--dark-pink);
}

.thank-you-page-card .border-top {
  border-top: 1px solid var(--dark-pink) !important;
}

.video-modal-title,
.store-picker-modal-title {
  color: var(--dark-pink);
  font-size: 20px;
  font-family: 'Gotham Bold';
}
.video-modal-title {
  font-size: 22px;
}
.modal-content {
  border: none;
  border-radius: 0;
  font-family: 'Gotham Book';
  color: var(--grey);
  line-height: 1.75;
}

.modal-content .form-control {
  border: solid 2px var(--light-pink);
  border-radius: 0;
  margin-right: 1.5rem;
}

.content-drawer .form-control {
  border-radius: 0;
  /* width: 60%; */
}

.modal-header {
  border-bottom: none;
}

.modal-body {
  padding-top: 0;
  font-family: 'Gotham Book';
  /* margin-bottom: 1rem; */
}

.scroll-wrapper {
  box-shadow: none;
}

.modal-footer {
  border-top: none;
  /* margin-top: 1rem; */
  padding: 0px 0.75rem;
}

.performance__text {
  color: var(--grey);
  font-size: var(--paragraph-text);
  font-family: 'Gotham Book';
}

.performance__text strong {
  font-weight: 800;
  font-family: 'Gotham Bold';
}

.video-modal-button {
  background: var(--dark-pink);
  color: var(--white);
  text-transform: uppercase;
  font-family: 'Gotham Medium';
  font-size: 14px;
  margin: 1rem 0;
}

.store-picker-button {
  background: var(--dark-pink);
  border: solid 1px var(--dark-pink);
  color: var(--white);
  text-transform: uppercase;
  font-family: 'Gotham Medium';
  font-size: 14px;
}

.store-picker-store-title {
  color: var(--dark-pink) !important;
  font-family: 'Gotham Medium';
  font-size: 18px;
  margin-bottom: 0;
  border-top: 1px solid var(--dark-pink);
  padding-top: 1rem;
}

.store-picker-card {
  border-bottom: none;
}

.store-picker-title {
  color: var(--dark-pink);
}

.select-package-header {
  width: 680px;
}

.thank-you-address-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.returning-customer-welcome-container {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.welcome-back-greeting-heading {
  font-size: 24px !important;
  font-family: 'Gotham Book';
  display: block !important;
}

.welcome-back-greeting-store {
  font-size: 24px;
  font-family: 'Gotham Bold';
  display: inline-block;
}
 
.welcome-back-address {
  font-size: 24px;
  font-family: 'Gotham Bold';
}

.returning-customer-home-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.returning-home-label {
  color: var(--dark-pink);
  margin-bottom: .5rem;
}

.returning-customer-home-details .returning-home-value {
  font-family: 'Gotham Bold' !important;
  font-size: 16px !important;
  color: var(--grey)
}

.customer-thank-you-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

@media screen and (max-width: 1332px) {
  .book__select-buttons .btn {
    margin-right: 1rem;
  }

  .thank-you-hero-text-section p {
    font-size: 18px;
    line-height: 1.75;
  }
}

@media screen and (max-width: 768px) {
  .form-section-labels {
    color: var(--dark-pink);
    font-size: 18px;
  }

  .form-section-header {
    font-size: 18px;
    margin-bottom: 0.5rem;
  }

  #booking-form-billing-info .form-row {
    flex-direction: column;
  }

  .form-section-header {
    display: flex;
    text-align: left;
  }

  .booking-home-info-inputs {
    flex-direction: column;
  }

  .returning-customer-home-details {
    flex-direction: column;
  }

  .booking-home-info-inputs .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: flex-start;
    align-items: center;
  }

  .select-package-header {
    width: 100%;
  }

  .booking-package-info {
    margin: 0 !important;
  }

  .booking-package-info .row {
    display: flex;
    /* flex-wrap: nowrap; */
    /* flex-direction: row; */
  }

  .book__select-buttons {
    margin-top: 3rem !important;
    text-align: left !important;
  }

  .book__select-buttons button {
    /* min-width: 250px !important; */
    width: 100%;
    background-color: var(--white);
    color: var(--grey);
    border-color: var(--grey);
    margin-bottom: 1.5rem !important;
    font-family: 'Gotham Medium';
  }

  .booking-state-select,
  .booking-zip-input {
    flex-basis: 50%;
  }

  .tip-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .tip-box {
    width: 100%;
  }

  .booking-input-container h3 {
    border-top: 1px solid var(--dark-pink);
    border-bottom: 1px solid var(--dark-pink);
    padding: 1rem 0;
  }

  .thank-you-hero-text-section {
    padding: 2rem 0 0 0;
  }

  .thank-you-hero-text-section p {
    font-size: 18px;
  }

  .thank-you-grid {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    justify-items: center;
    margin-top: 2rem !important;
  }

  .thank-you-page-card-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  body.customer-thank-you .thank-you-page-card-container {
    margin-top: 0;
  }

  body.thank-you .thank-you-grid {
    margin-top: 1rem !important;
  }

  .thank-you-page-card {
    width: 100%;
  }
}

.schedule-container-reskin {
  /* background-color: var(--light-pink); */
  /* padding-top: 50px; */
  font-family: 'Gotham Medium' !important;
}

.flatpickr-prev-month {
  display: none;
}
.flatpickr-next-month {
  display: none;
}

.flatpickr-next-month {
  width: auto;
  left: 0;
}
.flatpickr-current-month {
  width: 100%;
  left: 0;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  align-items: center;
}
.schedule-container-reskin h5 {
  color: var(--dark-pink);
}
.flatpickr-weekday,
.flatpickr-day {
  color: var(--dark-pink) !important;
}
.flatpickr-disabled {
  opacity: 0.3;
}
.flatpickr-day.selected {
  color: #fff !important;
}
.flatpickr-current-month .numInputWrapper {
  width: 50% !important;
  text-align: left !important;
  text-align: initial !important;
  background-color: #fff;
  border: 1px solid var(--grey);
  padding: 4px;
  color: var(--grey);
  margin-bottom: 10px;
}
.flatpickr-day {
  border-radius: 0%;
}
.flatpickr-day:hover,
.flatpickr-day.nextMonthDay:hover {
  background-color: #fff;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  width: 50% !important;
  text-align: left;
  text-align: initial;
  background-color: #fff !important;
  border: 1px solid var(--grey);
  padding: 4px;
  color: var(--grey);
  margin-bottom: 10px;
}
.schedule-selected-date {
  font-size: 20px;
  color: var(--grey);
  font-family: 'Gotham Book' !important;
}

.estimated-arrival {
  font-size: 16px;
  color: var(--grey);
  font-family: 'Gotham Book' !important;
}

.book__timepicker {
  margin-bottom: 120px;
}

.book__timepicker button {
  background-color: #fff;
  border: 1px solid var(--grey);
  color: var(--grey);
  width: 80% !important;
  text-align: start;
}
.book__timepicker button:hover {
  background-color: var(--dark-pink);
  border: 1px solid var(--light-pink);
  color: #fff;
}

.flatpickr-rContainer {
  margin-top: 30px !important;
}

@media (min-width: 768px) {
  .book__timepicker {
    margin-top: 0px;
    height: 180px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }

  .flatpickr-wrapper {
    margin-top: 30px !important;
  }
}
@media (max-width: 768px) {
  .flatpickr-calendar {
    margin: 0px !important;
  }

  .flatpickr-wrapper {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .book__timepicker {
    margin-bottom: 0;
  }
}

.book__summary {
  font-family: 'Gotham Medium';
}
.book__summary hr {
  color: var(--dark-pink);
  /* height: 1px; */
  background-color: var(--dark-pink);
  opacity: 0.4;
}
.summary-with-order {
  display: flex;
  justify-content: space-between;
  color: var(--dark-pink);
  font-family: 'Gotham Bold';
}
.summary-with-order .h3 {
  margin-bottom: 0px;
}
[data-summary='package'],
[data-summary='appointmentDate'] {
  color: var(--dark-pink);
}
[data-summary='squareFootage'],
[data-summary='bedrooms'],
[data-summary='bathrooms'],
[data-summary='people'],
[data-summary='pets'],
.appointmentTime {
  color: var(--grey) !important;
  font-family: 'Gotham Book';
}

.booking-order-number {
  font-family: 'Gotham Book';
}

@media screen and (max-width: 768px) {
  .book__summary {
    margin-top: 0 !important;
  }

  .booking-summary-hr {
    display: none;
  }
}
.top-hero-container {
  position: relative;
  height: 350px;
  margin-top: 48px;
  font-family: 'Gotham Book';
}
.top-hero-container h1 {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  /* margin-bottom: 20px; */
}
.top-hero-container p {
  margin: 30px 0px;
  font-size: 18px;
  color: var(--grey);
}
.top-hero-image-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.steps-container {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.step-container {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.step-container h6 {
  color: var(--dark-pink);
  font-weight: normal;
  font-weight: initial;
  font-family: 'Gotham Medium';
  margin-bottom: 10px;
  font-size: 12px;
}
.step-circle {
  background: var(--dark-pink);
  width: 40px;
  border-radius: 50%;
  height: 40px;
  position: relative;
}
.circle-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  color: #fff;
  font-family: 'Coves Bold';
}

.top-hero-content-container {
  padding-top: 30px;
}

/* Element | http://localhost:3000/book/ */

.steps-container hr {
  width: 100%;
  align-self: flex-end;
  padding-top: 5px;
}

@media screen and (max-width: 1330px) {
  .top-hero-content-container {
    padding-top: 0px;
  }
}

/* Footer Styles ----------------------------------------------------------- */
footer {
  overflow: hidden;
  /* z-index: 1; */
  position: relative;
  font-family: 'Gotham Medium';
}

.let-life-shine-section {
  position: relative;
  width: 100%;
  background: var(--light-pink);
  z-index: -1;
  background-image: url('https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,q_auto,w_1920/v1670256573/hubsite-reskin/opaque-dots.webp');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: bottom; */
  height: 30vh;
  /* background-size: 100% 200%; */
  background-position: 0 51%;
}

.footer-white-bg {
  background: #fff;
  position: relative;
  width: 100%;
  z-index: -1;
  background-image: url('https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,q_auto,w_1920/v1670256573/hubsite-reskin/opaque-dots.webp');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: bottom; */
  /* height: 80vh; */
  /* background-position: 0% 80%; */
  height: 30vh;
  background-position: 0 52%;
}

.let-life-shine-text {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 3 !important;
  width: 300px !important;
  height: 38px !important;
}

.footer-cta-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.footer-cta-section img {
  z-index: 1;
}

.footer-cta-section a {
  color: var(--dark-pink);
  background: var(--white);
  margin-right: 1rem;
  border: solid 1px var(--white);
  padding: 0.75rem 1rem;
  font-weight: 600;
  transition: var(--button-transition);
  z-index: 3;
}

.footer-cta-section .link-inverse {
  border: none;
  z-index: 20000;
  background: none;
  color: #fff;
}

.footer-cta-section a:hover {
  background: var(--dark-pink);
  color: var(--white);
}

.footer-cta-section .link-inverse:hover {
  border: none;
  z-index: 20000;
  background: none;
  text-decoration: underline;
}

.call-for-estimate-text {
  display: flex;
  align-items: center;
}

.footer-cta-section p {
  color: #fff;
  z-index: 20000;
  margin: 0;
}

.footer-cta-section span {
  font-weight: 800;
  color: #fff;
  margin-left: 1rem;
}

.sticky-footer-cta-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 6rem;
  font-family: 'Gotham Medium' !important;
}

.sticky-footer-cta-section a {
  color: var(--dark-pink);
  background: var(--white);
  margin-right: 1rem;
  border: solid 1px var(--white);
  padding: 0.75rem 1rem;
  font-weight: 600;
  transition: var(--button-transition);
}

.sticky-footer-cta-section .link-inverse {
  border: none;
  z-index: 20000;
  background: none;
  color: var(--white);
}

.sticky-footer-cta-section a:hover {
  background: var(--dark-pink);
  color: var(--white);
}

.sticky-footer-cta-section .link-inverse:hover {
  border: none;
  z-index: 20000;
  background: none;
  text-decoration: underline;
}

/* .call-for-estimate-text {
  display: flex;
  align-items: center;
} */

.sticky-footer-cta-section p {
  color: #fff;
  z-index: 20000;
  margin: 0;
}

.sticky-footer-cta-section span {
  font-weight: 800;
  color: #fff;
  margin-left: 1rem;
}

.footer-nav-section {
  background: var(--dark-pink);
  padding: 4rem 6rem 4rem 6rem;
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

.footer-circle {
  position: absolute;
  content: '';
  width: 500px;
  height: 500px;
  background: var(--light-pink);
  top: -200px;
  right: 140px;
  border-radius: 50%;
  opacity: 0.25;
  /* box-shadow: 10px 10px 10px #971c5c; */
  z-index: 0;
}

.footer-circle-two {
  position: absolute;
  content: '';
  width: 500px;
  height: 500px;
  background: var(--light-pink);
  bottom: -300px;
  right: 140px;
  border-radius: 50%;
  opacity: 0.25;
  /* box-shadow: 10px 10px 10px #971c5c; */
  z-index: 0;
}

.footer-nav-section hr {
  color: var(--light-pink);
  height: 1px;
  border: none;
  background: var(--light-pink);
}

.mobile-footer-logo {
  display: none;
}

.footer-nav {
  padding-top: 6rem;
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
}

.footer-logo {
  max-width: 175px;
  /* height: 100%; */
  margin-right: 10rem;
  margin-bottom: 1rem;
}

.footer-links {
  list-style: none;
  padding: 0 0 3rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 6rem;
  -moz-column-gap: 6rem;
       column-gap: 6rem;
  grid-row-gap: 0.75rem;
  row-gap: 0.75rem;
  font-weight: 600;
  z-index: 2;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  transition: var(--button-transition);
  z-index: 2;
}

.footer-link:hover {
  color: #f9ddebd4;
}

.footer-link a {
  color: var(--light-grey);
}

.social-links-reskin-container {
  display: inline;
  list-style: none;
  display: flex;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  padding-left: 0;
  z-index: 2;
}

.social-link-reskin {
  color: var(--white) !important;
}

.social-link-reskin:hover {
  color: #f9ddebd4 !important;
}

.brand-icons {
  color: var(--light-pink);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-self: flex-start;
  grid-column-gap: 1rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.brand-icons svg {
  width: 22px;
}

.footer-nav div p {
  color: #fff;
  font-size: var(--paragraph-text);
  font-weight: 400;
  z-index: 2;
}

.footer-nav div p a {
  color: inherit;
}

.footer-address {
  display: flex;
  flex-direction: column;
}

.footer-address p {
  font-size: 14px !important;
}

.footer-address .copyright-text {
  font-size: 10px !important;
}

/* Tablet Styles --------------------------------------------------------------- */
@media screen and (max-width: 1350px) {
  .footer-cta-section {
    display: flex;
    flex-direction: column;
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .sticky-footer-cta-section {
    display: flex;
    flex-direction: column;
    grid-row-gap: .5rem;
    row-gap: .5rem;
    padding: .75rem 0rem;
  }

  .sticky-footer-cta-section a { 
    margin-right: 0;
    text-align: center;
    width: 100%;
    max-width: 240px;
    padding: 0.28125rem 0.75rem;
    white-space: nowrap;
  }

  .sticky-footer-cta-section-buttons {
    grid-row-gap: .75rem !important;
    row-gap: .75rem !important;
    grid-row-gap: .75rem;
    font-size: 12px;
    flex-direction: row !important;
    grid-column-gap: .75rem;
    -moz-column-gap: .75rem;
         column-gap: .75rem;
  }

  .sticky-footer-cta-section .link-inverse {
    padding: 0rem;
    font-size: 1.25rem;
  }

  .sticky-footer-cta-section .call-for-estimate-text p { 
    margin-bottom: 0 !important;
    font-size: 12px;
  }

  .footer-cta-section a {
    margin: 0;
    min-width: 300px;
    width: 100%;
    text-align: center;
  }

  .call-for-estimate-text {
    flex-direction: column;
  }

  .call-for-estimate-text p {
    margin-bottom: 0.5rem;
  }

  .call-for-estimate-text span {
    margin: 0;
  }

  .mobile-footer-logo {
    max-width: 175px;
    height: auto;
    margin-bottom: 2rem;
    display: block;
  }

  .footer-logo {
    display: none;
  }

  .footer-cta-section-buttons,
  .sticky-footer-cta-section-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .footer-nav-section {
    background: var(--dark-pink);
    padding: 8rem 6rem 5rem 6rem;
    font-weight: 400;
  }

  .footer-circle {
    top: -200px;
    right: -200px;
  }

  .footer-circle-two {
    bottom: -200px;
    right: -200px;
  }

  .footer-nav {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .footer-logo {
    max-width: 150px;
  }

  .footer-links {
    padding: 3rem 0 3rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 0rem;
    -moz-column-gap: 0rem;
         column-gap: 0rem;
    grid-row-gap: 0.75rem;
    row-gap: 0.75rem;
  }
}

/* Mobile Styles --------------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .let-life-shine-section {
    height: 20vh;
  }

  .let-life-shine-text {
    width: 200px !important;
    height: 26px !important
  }

  .social-links-reskin-container {
    margin: 0 auto;
  }

  .footer-nav-section {
    background: var(--dark-pink);
    padding: 8rem 2rem 5rem 2rem;
    font-weight: 400;
  }

  .footer-circle {
    display: none;
  }

  .footer-circle-two {
    display: none;
  }

  .footer-nav-section hr {
    color: var(--light-pink);
    height: 1px;
    border: none;
    background: var(--light-pink);
  }

  .footer-nav {
    padding-top: 0;
    flex-direction: column-reverse;
    text-align: center;
  }

  .footer-logo {
    display: none;
  }

  .footer-links {
    padding: 2rem 0 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0;
    -moz-column-gap: 0;
         column-gap: 0;
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .footer-links-col {
    grid-row-gap: 2rem;
    row-gap: 2rem;
    grid-template-columns: auto;
  }

  .brand-icons {
    color: var(--light-pink);
    align-self: center;
    flex: 1 1;
    padding: 0.5rem 0 0.5rem 0;
  }

  .footer-address {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 4rem;
  }

  .footer-address p:first-child {
    margin-right: 0 !important;
  }
}

.navigation {
  /* height: 70px; */
  background: #fff;
  font-family: 'Gotham Medium';
}
.header {
  position: relative ;
}
.brand {
  height: 100%;
  width: 175px;
  position: relative;
  margin-left: 20px;
}
.brand img {
  /* height: 100%; */
  /* width: 100%; */
  -o-object-fit: contain;
     object-fit: contain;
}

.nav-container {
  /* width: 90%;
  margin: 0 auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 100%; */
  position: relative;
  height: 70px;
  padding-bottom: 20px;
}

nav {
  float: right;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav ul li {
  float: left;
  position: relative;
}
nav ul li a,
nav ul li a:visited {
  display: block;
  padding: 0 10px;
  font-size: 13px;
  margin-top: 22px;

  color: #75757A;
  text-decoration: none;
}
nav ul li a:hover,
nav ul li a:visited:hover {
  opacity: 0.7;
  color: #75757A;
}
nav ul li a:not(:only-child):after,
nav ul li a:visited:not(:only-child):after {
  padding-left: 4px;
  content: ' ▾';
}
nav ul li ul li {
  min-width: 190px;
}
nav ul li ul li a {
  padding: 15px;
  line-height: 20px;
}

.nav-dropdown {
  position: absolute;
  display: none;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

/* Mobile navigation */
.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  width: 70px;
}

.nav-list {
  padding-right: 20px;
  background: #fff;
  padding-left: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #75757A;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  z-index: 1;
  width: 155px;
  right: -5px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: #75757A;
  text-decoration: none;
  display: block;
  margin: 0;
  width: 155px;
  padding: 5px 10px;
}

.dropdown-content a:hover {
  background-color: #f2f2f2;
}

.header-link-booking {
  color: var(--white) !important;
  padding: 8px 10px;
  border: 2px solid var(--dark-pink);
  background: var(--dark-pink);
  margin-top: 10px;
  margin-left: 10px;
}
.header-link-booking:hover {
  color: var(--dark-pink) !important;
  background: var(--white);
}

.top-nav {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  padding-top: 30px;
  font-size: 12px;
  font-family: 'Gotham Medium';
  color: var(--grey);
}
.top-nav-left span {
  color: var(--dark-pink);
  font-family: 'Gotham Bold';
}
.top-nav-left span:hover {
  cursor: pointer;
  opacity: 0.7;
}

.top-nav-right {
  display: flex;
  flex-direction: row-reverse;
}

.top-nav-right li {
  list-style: none;
  margin-left: 1rem;
}

.top-nav-right span {
  color: var(--dark-pink);
  font-family: 'Gotham Bold';
}
.navigation hr {
  margin-left: 20px;
  margin-right: 20px;
  color: var(--grey);
}
.nav-bottom-mobile {
  display: none;
}
@media only screen and (max-width: 1192px) {
  .nav-container {
    padding-top: 20px;
  }
  .top-nav,
  .navigation hr {
    display: none;
  }
  .nav-mobile {
    display: block;
  }
  .nav-bottom-mobile {
    display: block;
    background-color: var(--light-pink);
    /* color: var(--grey); */
    font-size: 12px;
    padding: 10px 0px;
    padding-left: 20px;
  }
  .nav-bottom-mobile span {
    color: var(--dark-bg-pink);
    margin-left: 3px;
  }
  .nav-bottom-mobile span:hover {
    opacity: 0.7;
    /* cursor: pointer; */
  }
  nav {
    width: 100%;
    padding: 70px 0 15px;
  }
  nav ul {
    display: none;
  }
  nav ul li {
    float: none;
  }
  nav ul li a {
    padding: 15px;
    line-height: 20px;
  }
  nav ul li ul li a {
    padding-left: 30px;
  }
  nav ul li a,
  nav ul li a:visited {
    margin: 0px;
    padding: 0px;
  }
  .nav-dropdown {
    position: static;
  }
  .nav-list {
    position: absolute;
    right: 0;
    width: 100%;
    top: 70px;
  }
  .nav-list li {
    /* width: 360px; */
    padding: 17px;
    border-bottom: 1px solid #e02d8859;
  }
  .nav-list li a:hover {
    opacity: 0.5;
  }
  .nav-list li:nth-child(1) {
    border-top: 1px solid #e02d8859;
  }

  .nav-list .social-links-reskin-container li:nth-child(1) {
    border-top: none !important;
  }

  .nav-list li:nth-child(10) {
    border-bottom: none;
    display: none;
  }
  .header-link-booking,
  .header-link-li-mobile {
    display: none;
  }
}
@media screen and (min-width: 1193px) {
  .nav-list {
    display: block !important;
  }
}
#nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
  border: none;
  background: transparent;
}

#nav-toggle:focus,
#nav-toggle:active {
  outline: none;
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 2px;
  width: 35px;
  background: var(--dark-pink);
  position: absolute;
  display: block;
  content: '';
transition: all 300ms ease-in-out;  
}
#nav-toggle span:before {
  top: -10px;
}
#nav-toggle span:after {
  bottom: -10px;
}
#nav-toggle.active span {
  background-color: transparent;
}
#nav-toggle.active span:before,
#nav-toggle.active span:after {
  top: 0;
}
#nav-toggle.active span:before {
  transform: rotate(45deg);
}
#nav-toggle.active span:after {
  transform: rotate(-45deg);
}
.hero__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.hero__content {
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
  align-items: center;
  margin: 2rem;
  position: absolute;
  width: calc(100vw - 4rem);
  height: calc(100% - 4rem);
  justify-content: space-between;
  top: 0;
}

.hero__image {
  position: relative;
  height: 24rem;
  color: '#fff';
  width: 100vw;
}

.hero__content--image {
  width: 90%;
  max-width: 847px;
  min-width: 300px;
}

.hero__ctas {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  flex-direction: row;
}

a.btn-tm-cta {
  display: inline-flex;
  color: var(--white);
  border: solid 2px var(--dark-pink);
  background: var(--dark-pink);
  padding: 0.75rem 1rem;
  font-weight: 600;
  transition: var(--button-transition);
  font-family: 'Gotham Medium';
  /* background-color: rgba(0, 0, 0, 0.007); */
  min-width: 16rem;
  align-items: center;
  justify-content: center;
}

a.btn-tm-cta:hover {
  background: var(--white);
  color: var(--dark-pink);
  border-width: 2px;
}

.hero-circles {
  position: fixed;
  z-index: -2;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: none;
}

.hero-circle {
  background-color: var(--light-pink);
  z-index: -2;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  opacity: 0.5;
}

@media(max-width: 1332px) {
  a.btn-tm-cta {
    /* width: 40%; */
    max-width: 350px;
    height: 50px;
    color: var(--white);
    border: solid 2px var(--dark-pink);
    background: var(--dark-pink);
    cursor: pointer;
    font-weight: 700;
    padding: 15px;
    text-decoration: none;
    font-family: Gotham Medium;
  }

  .hero-circles {
    display: flex;
    top: -12%;
  }
}

@media(max-width: 600px) {
  .hero__content {
    position: relative;
  }

  .hero__content--image {
    position: absolute;
    top: -200px;
  }

  .hero__image {
    height: 12rem;
  }

  .hero__ctas {
    flex-direction: column;
  }
}

.rotating-text-section {
  margin-top: 4rem;
}

.rotating-text-section h1 {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  font-size: var(--large-heading);
  margin-bottom: 2rem;
  max-width: 700px;
  position: relative 
}

.rotating-mobile-break {
  display: none;
}

.rotating-words {
	display: inline;
	text-indent: 12px;
  white-space: nowrap;
}

.rotating-words span {
	animation: topToBottom 12.5s linear infinite 0s;
	-ms-animation: topToBottom 12.5s linear infinite 0s;
	-webkit-animation: topToBottom 12.5s linear infinite 0s;
	color: var(--dark-pink);
	opacity: 0;
	overflow: hidden;
	position: absolute;
}
.rotating-words span:nth-child(2) {
	animation-delay: 2.5s;
	-ms-animation-delay: 2.5s;
	-webkit-animation-delay: 2.5s;
}
.rotating-words span:nth-child(3) {
	animation-delay: 5s;
	-ms-animation-delay: 5s;
	-webkit-animation-delay: 5s;
}
.rotating-words span:nth-child(4) {
	animation-delay: 7.5s;
	-ms-animation-delay: 7.5s;
	-webkit-animation-delay: 7.5s;
}
.rotating-words span:nth-child(5) {
	animation-delay: 10s;
	-ms-animation-delay: 10s;
	-webkit-animation-delay: 10s;
}

@keyframes topToBottom {
	0% { opacity: 0; }
	5% { opacity: 0; transform: translateY(-50px); }
	10% { opacity: 1; transform: translateY(0px); }
	25% { opacity: 1; transform: translateY(0px); }
	30% { opacity: 0; transform: translateY(50px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}

.rotating-text-section p {
  font-family: 'Gotham Book';
  font-size: var(--large-paragraph-text);
  line-height: var(--large-paragraph-line-height);
  color: var(--grey);
  max-width: 700px;
}

.house-cleaning-experts-section {
  padding: 4rem 0 4rem 0;
}

.house-cleaning-experts-section h2 {
  font-size: 24px;
  color: var(--dark-pink);
  margin-bottom: 1rem;
  font-family: 'Gotham Medium';
}

.house-cleaning-experts-section p {
  font-size: 14px;
  color: var(--dark-grey);
  line-height: 1.75;
  margin-bottom: 4rem;
}

.house-cleaning-experts-section img {
  width: 100%;
  max-width: 40px;
  margin-bottom: 2rem;
}

.house-cleaning-experts-section h3 {
  color: var(--dark-pink);
  font-size: 18px;
  font-family: 'Gotham Medium';
  margin-bottom: 10px;
  min-width: 275px;
}

.house-cleaning-experts-section a {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 250px;
  color: #fff !important;
}

.home-services__service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.home-logos {
  background: var(--light-pink) !important;
}

.home-difference {
  background: var(--light-grey);
  padding: 2rem 0 4rem 0;
}

.home-difference h2 {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  font-size: var(--sub-heading);
}

.home-difference p {
  text-align: center;
  margin: 2rem auto 3rem auto;
  line-height: 30px;
}

.home-difference a {
  margin: 0 auto;
  border-color: var(--dark-pink);
  color: var(--dark-pink);
  font-family: 'Gotham Bold';
}

.powered-by-caring-image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-column-gap: 3rem;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
}

.powered-by-caring-group {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.powered-by-caring-group a {
  color: var(--dark-pink);
  font-size: 18px;
  font-family: 'Gotham Medium';
  margin-top: 2rem;
}

.powered-by-caring-group img {
  width: 300px;
  max-height: 150px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 8px;
  -o-object-position: bottom;
     object-position: bottom;
}

.powered-by-caring-group:nth-child(3) img {
  width: 200px;
}


.reviews-carousel-container {
  background: var(--light-pink);
  padding: 2rem 0 6rem 0;
}

.reviews-carousel-container h2 {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  font-size: var(--sub-heading);
  margin-bottom: 1rem;
}

.reviews-carousel-container .carousel-inner {
  height: auto;
  padding: 1rem 0;
}

.reviews-carousel-container .container {
  max-width: 100vw;
}

.reviews-carousel-container .review-card {
  background: var(--white);
  max-width: 450px;
  width: 100%;
  height: 100%;
  min-height: 375px;
  padding: 1rem 1.5rem;
  border-radius: 0;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  box-shadow: rgb(0 0 0 / 35%) 4.95px 4.95px 2.6px;
}

.reviews-carousel-container .review-card .review__name {
  font-family: 'Gotham Bold';
  color: var(--dark-pink);
}

.reviews-carousel-container .review-card .review__location {
  color: var(--dark-pink);
  font-family: 'Gotham Book';
}

.reviews-carousel-container .btn {
  margin-top: 5rem;
}

.dark-bg-btn {
  color: var(--white) !important;
  background: var(--dark-pink);
  border: solid 1px var(--dark-bg-pink) !important;
}

.dark-bg-btn:hover {
  color: var(--dark-pink) !important;
  border: solid 1px var(--dark-pink) !important;
  background: var(--white);
}

@media screen and (max-width: 1000px) {
  .rotating-mobile-break {
    display: block;
  }

  .rotating-words {
    display: block;
    text-indent: 0;
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 991px) {
  .house-cleaning-experts-section h3 {
    min-width: 100%;
  }
}

@media (max-width: 768px) {
  .rotating-text-section .h2 {
    font-size: var(--sub-heading);
    margin-bottom: 1rem;
    font-size: 40px;
  }

  .rotating-text-section p {
    font-size: 18px;
    line-height: 1.75;
  }

  .rotating-words {
    margin-bottom: 4rem;
  }

  .house-cleaning-experts-section h3 {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .home-difference h2,
  .reviews-carousel-container h2 {
    font-size: var(--large-paragraph-text);
    font-family: 'Gotham Bold';
  }

  .home-difference a {
    width: 250px;
  }

  .home-difference p {
    padding: 0 1.5rem;
  }

  .powered-by-caring-image-container {
    justify-content: center;
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .powered-by-caring-image:nth-child(1) {
    max-width: 200px;
  }
  
  .powered-by-caring-image:nth-child(2) {
    max-width: 200px;
    margin-left: 0;
  }
  
  .powered-by-caring-image:nth-child(3) {
    max-width: 200px;
  }

  .services-btn {
    margin-bottom: 2rem !important;
  }

  .home-services__service {
    margin-bottom: -2rem !important;
  }
}
@media (max-width: 575px) {
  .rotating-words {
    display: block;
    text-indent: 0;
    margin-bottom: 4rem;
  }

  .home-services-container {
    background-color: var(--light-pink);
    padding: 40px 0px;
  }
  .house-cleaning-experts-section {
    padding: 3rem 0 0 0;
  }
}

.services-hero-image {
  height: 740px;
  /* height: 80vh; */
  position: relative;
}
.services-hero-image img {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}
.services-hero-container {
}

.services-hero-content {
  position: absolute;
  width: 550px;
  top: 300px;
  left: 100px;
}
.services-hero-content h1 {
  color: var(--dark-pink);
  font-family: 'TiemposFine Regular';
  margin-bottom: 40px;
}
.services-hero-content p {
  line-height: 30px;
  font-family: 'Gotham Book';
  color: var(--grey);
  margin-bottom: 50px;
}
.services-hero-cta-buttons {
  display: flex;
  justify-content: space-between;
  width: 85%;
}
.services-accordian-container div.card:nth-child(1) > div:nth-child(1) {
  /* background-color: var(--light-pink); */
}
.services-accordian-container div.card:nth-child(2) > div:nth-child(1) {
  /* background-color: var(--light-grey); */
}
.services-accordian-container div.card:nth-child(3) > div:nth-child(1) {
  /* background-color: #fff; */
}

.services-accordian-container {
  font-family: 'Gotham Book';
}
.services-accordian-header {
  text-align: center;
  /* width: 800px; */
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.services-accordian-header span {
  /* margin-right: 50px; */
  color: var(--dark-pink);
  font-family: 'Gotham Medium';
  font-size: 24px;
}
.services-accordian-header div {
  position: absolute;
  margin-left: 300px;
}
.services-accordian-header div svg {
  font-size: 32px;
  background-color: var(--dark-pink);
  border-radius: 50%;
  border: 2px solid var(--dark-pink);
}
.services-accordian-header div svg:hover {
  cursor: pointer;
  opacity: 0.6;
}
.package-selection-container {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Gotham Medium';
  color: #4d4d4d;
  margin-top: 50px;
  text-align: center;
}
.faq-service-container {
  font-family: 'Gotham Book';
}

.services-package-description {
  width: 67%;
  margin: 80px auto;
  position: relative;
}
.services-package-description p {
  z-index: 2;
  position: relative;
}
.service-circle-one,
.service-circle-two {
  height: 100px;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.268);
  position: absolute;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  z-index: 1;
}
.service-circle-one {
  transform: translate(-50%, 20%);
}
.service-circle-two {
  transform: translate(-50%, -100%);
}
.package-selection-container span:hover,
.service-package-active {
  cursor: pointer;
  color: var(--dark-bg-pink);
  /* text-decoration: underline; */
  border-bottom: 1px solid var(--dark-bg-pink);
}
.accordion__card .card-header {
  border-bottom: 1px solid #9f9f9d;
}
.services-accordion-icon svg {
  width: 22px !important;
  height: 3em !important;
}
@media screen and (min-width: 600px) {
  .touch-up-service-header {
    margin-left: 40px;
  }
}

@media screen and (max-width: 1332px) {
  body.services main {
    /* margin-top: -91vh; */
  }
}

@media screen and (max-width: 1234px) {
  .services-hero-content {
    position: relative;
    top: unset;
    left: unset;
    width: 90%;
    margin: 50px auto;
  }
  .services-hero-cta-buttons {
    flex-direction: column;
    width: 250px;
    grid-gap: 20px;
    gap: 20px;
  }
  .services-hero-image {
    height: 50vh;
  }
  .services-hero-image img {
    display: block;
    position: relative;
  }
}
@media screen and (max-width: 736px) {
  .services-hero-image {
    height: 32vh;
  }

  .services-accordian-header {
    justify-content: flex-start;
  }

  .services-package-description {
    width: 85%;
    margin: 50px auto;
  }

  .faq-service-container .card-body,
  .faqs .card-body {
    padding: 2.5rem 0 !important;
  }
}

@font-face {
    font-family: 'TiemposFine Regular';
    src: url(/_next/static/media/TestTiemposFine-Regular.4e1cdff6.otf);
    font-display: swap;
  }
  @font-face {
    font-family: 'TiemposFine Semibold';
    src: url(/_next/static/media/TestTiemposFine-Semibold.e38bde53.otf);
    font-display: swap;
  }
    
.service-description {
    padding: 3rem 15%;
    margin: 0px;

    @media (max-width: 1269.98px) {
        padding-left: 10%;
        padding-right: 10%;
    }

    @media (max-width: 575.98px) {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.service-page-title {
    color: #d4357f;
    font-family: 'TiemposFine Semibold';
    font-size: var(--large-heading) !important;
}
  
.service-description-section {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
}

.service-description h2 {
    color: #d4357f;
    font-size: 50px;
    font-family: 'TiemposFine semibold';
}

.service-description-icon-text {
    color: #d4357f;
    font-family: 'Gotham Bold';
}
  
.service-description-section-svg {
    filter: invert(25%) sepia(92%) saturate(2469%) hue-rotate(311deg) brightness(84%) contrast(93%);
    width: 90px;
    min-width: 90px;
    max-width: 120px;
    padding-bottom: .5rem;
}

.service-description-section-promise-svg {
    width: 270px;
    min-width: 270px;
    max-width: 270px;
    margin-top: -60px;
    margin-bottom: -100px;
}

.service-description-title {
    color: #d4357f;
}

.gotham-medium {
    font-family: 'Gotham Medium';
}

h3.service-description-title {
    font-weight: 100;
}

.service-description-content {
    display: flex;
    padding: 3rem;
    padding-left: 15%;
    padding-right: 15%;
    margin: 0px;

    @media (max-width: 1269.98px) {
        padding-left: 10%;
        padding-right: 10%;
    }
    
    @media (max-width: 575.98px) {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.gray-background {
    background-color: #F4F4F4;
}

.service-learn-more {
    font-family: 'Gotham Book';
    font-size: 1.5rem;
}

.service-description-img {
    @media (min-width: 768px) {
        max-width: 240px;
    }

    @media (max-width: 767.98px) {
        width: 100%;
        margin-bottom: 2rem;
    }
}

.service-description-img-col  {
    @media (min-width: 768px) {
        max-width: 340px !important;
    }
}

.service-description-small-image {
    margin: 0px;
    @media (min-width: 768px) {
        max-width: 200px;
    }

    @media (max-width: 767.98px) {
        width: 100%;
        margin-bottom: 2rem;
    }
}

.small-service-button {
    display: flex;
    color: var(--white);
    border: solid 2px #d4357f;
    background: #d4357f;
    padding: .5rem 1rem;
    transition: var(--button-transition);
    align-items: center;
    justify-content: center;
    margin-top: auto;
    max-width: 350px;
}

.small-service-button:hover {
    background: var(--white);
    color: #d4357f;
    border-width: 2px;
}

.services-description-list {
    page-break-inside: avoid;
    page-break-inside: avoid;
    -moz-column-break-inside: avoid;
         break-inside: avoid;
    margin-bottom: 0;

    li {
        padding-bottom: 1rem;
    }
}

.mr-3 {
    margin-right: 1.5rem;
}

.pt-0 {
    padding-top: 0rem;
}

.contact-us {
    display: flex;
    flex-wrap: wrap;
    color: var(--white);
    border: solid 2px #d4357f;
    background: #d4357f;
    padding: 1rem 2rem;
    transition: var(--button-transition);
    margin-left: 15%;
    margin-right: 15%;

    @media (max-width: 1269.98px) {
        margin-left: 10%;
        margin-right: 10%;
    }
    
    @media (max-width: 575.98px) {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.contact-us-flex {
    flex-direction: row;
    @media (max-width: 1591.98px) {
        flex-direction: column;
    }
}

.contact-us-button {
    display: flex;
    color: #d4357f;
    border: solid 2px var(--white);
    background: var(--white);
    padding: .5rem 1rem;
    transition: var(--button-transition);
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 245px;
    max-width: 250px;
    font-family: "Gotham Bold";
}

.flex-wrap-deep-clean {
    flex-wrap: nowrap;
    @media (max-width: 893.98px) {
        flex-wrap: wrap;
    }
}

.promise-description {
    display: flex;
    flex-wrap: nowrap;
    @media (max-width: 667.98px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.vertical-surfaces {
    margin-left: 1rem;
    padding: 1.5rem;
    min-width: 310px;
    max-height: 234px;

    ul {
        margin-bottom: 0px;
    }

    @media (max-width: 893.98px) {
        margin-left: 0px;
        flex-wrap: wrap;
    }
}

.gotham-bold {
    font-family: "Gotham bold";
}

.service-description-ready-check {
    display: flex;
    min-width: 270px;
    justify-content: center;
    margin-right: 1.5rem;
    max-height: 210px;
}

.comparison-table-pink-bg {
    background-color: #FFCAE6;
}

.comparison-table-premium {
    background: rgba(223, 25, 149, 0.5);
}

.comparison-table-premium-title {
    background: #d4357f;
    color: var(--white);
}

.comparison-table-header {
    background: #FBF7EF;
}

.table td {
    vertical-align: middle;
}

@media (max-width: 575.98px) {
    .table {
        font-size: 12px !important;
    }

    .service-description-table-container {
        padding: 0 !important;
        margin-top: 2rem;
    }

    .table td {
        padding: .5rem !important;
    }

    .service-description-section-promise-svg {
        padding-top: 1rem;
    }

    .service-description-ready-check {
        padding-bottom: 1rem;
    }
}
.blog-heading-section {
  padding-left: 0 !important;
}

.blog-title {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  font-size: 4rem;
}

.blog-card-new {
  cursor: pointer;
  background: none;
}
.blog-card-new span {
  border-radius: 50%;
}

.blog-card-image {
  transition: var(--button-transition);
  border-radius: 50%;
  width: 400px;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
  margin-bottom: 2rem;
}

.blog-card-title {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.blog-card-new:hover .blog-card-image {
  filter: grayscale(100);
}

.badge-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge {
  padding-left: 0 !important;
  /* padding-right: 1rem; */
}

.single-blog-page .static-page-title,
.single-blog-page h1 {
  margin-bottom: 0;
}

.single-blog-content {
  margin-top: 8rem;
}

.blog-text-content::first-letter {
  float: left;
  color: var(--dark-pink);
  font-family: 'TiemposFine Semibold';
  font-size: 6rem;
  line-height: 4rem;
  height: 4rem;
  text-transform: uppercase;
}

.blog-content-container h1 {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  font-size: 36px;
}

.blog-social-link {
  color: var(--grey);
  margin-right: 1.5rem !important;
  margin-top: 1.5rem !important;
  transition: var(--button-transition);
}

.blog-social-link:hover {
  color: var(--dark-pink);
}

@media screen and (max-width: 1332px) {
  .blog-title {
    font-size: 40px;
  }

  .blog-content-container h1 {
    font-size: 24px;
  }
}
.contact-page-google-map {
  filter: grayscale(0.5);
}

.contact-page-city-state {
  color: var(--dark-pink);
  font-size: var(--large-paragraph-text);
  font-family: 'Gotham Bold';
}

.communities-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
}

.served-community {
  border-right: 2px solid var(--grey);
  padding-right: 1rem;
  padding-left: 1rem;
}
.communities-grid div:last-child span {
  border-right: none;
}
.contact-us-title-container,
.contact-us-content-container {
  /* background-color: #faf7f3; */
}

.contact-us-content-container {
  padding-bottom: 2rem;
}

@media screen and (max-width: 1332px) {
  body.contact main {
    /* margin-top: -91vh; */
  }
}

@media screen and (max-width: 991px) {
  .contact-page-google-map {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .contact-page-google-map {
    margin-top: 0;
  }
}
.sticky-cta {
  /* background-color: var(--light-pink); */
}

.book__total-cost {
  display: flex;
  flex-direction: column;
}
.sticky-footer-lightbox {
  display: flex;
  align-items: center;
}

.sticky-footer-lightbox div {
  margin: 0 5px;
}

.sticky-footer-lightbox hr {
  width: 100%;
  background-color: var(--dark-pink);
  height: 1px;
}
.book__total-cost {
  padding-top: 15px;
}
.book__total-cost strong {
  font-family: 'Gotham Book';
  color: var(--dark-pink);
  font-size: 22px;
}
.book__total {
  color: var(--dark-pink);
  font-family: 'Gotham Bold';
  font-size: 44px;
}

.popover-basic-content p {
  margin-bottom: 5px;
  font-size: 14px;
}
.popover-basic-content {
  font-family: 'Gotham Book';
  color: var(--dark-pink);
}
.popover-basic-content .border-top {
  border-top: 1px solid var(--dark-pink) !important;
}
@media screen and (max-width: 768px) {
  .sticky-footer-content-container {
    flex-direction: column;
  }
  .sticky-footer-content-container .sticky-cta__phone {
    margin: 0 !important;
    margin-top: 40px !important;
  }
  .sticky-footer-content-container button {
    margin-right: 0px !important;
  }
}
@media (max-width: 489.98px) {
  .sticky-footer-lightbox {
    width: 50%;
    margin: auto;
  }
}

.static-page-title {
  color: var(--dark-pink);
  font-family: 'TiemposFine Semibold';
  font-size: var(--large-heading) !important;
  margin-top: 2rem;
  margin-bottom: 20px;
}

.static-page-content {
  font-family: 'Gotham Book';
  color: var(--grey);
  line-height: 1.75;
}

.static-page-content .h5 {
  font-family: 'Gotham Medium';
}

.why-cta-container {
  display: flex;
  justify-content: space-between;
  width: 500px;
  /* margin-bottom: 4rem; */
  margin-top: 1rem;
  margin: 2rem auto;
}

.why-cta {
  font-family: 'Gotham Medium';
}

.top-background-dots {
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: -1;
  top: 0;
  transform: rotate(180deg);
  background-image: url('https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,q_auto,w_1920/v1670256573/hubsite-reskin/opaque-dots.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 80vh;
  margin-top: 10rem;
}
.top-background-dots.about{
  top: -130px;
}
.reviews__review {
  border: none;
}

.first-review {
  position: relative;
  margin-left: 4rem;
}

.rounded-circle {
  filter: grayscale(100);
  width: 400px;
  height: auto;
}

.first-review-section {
  margin-bottom: 6rem;
}

.first-review-content {
  position: absolute;
  top: 25%;
  background: #f9ddebe3;
  left: 70%;
  color: var(--dark-pink);
  border-radius: 50%;
  width: 400px;
  height: 400px;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.review__name,
.review__location {
  font-size: var(--paragraph-font);
}

.review__content {
  font-size: 15px;
}

.review__name {
  font-family: 'Gotham Medium';
  font-style: italic;
  color: #832e54;
  margin-top: 1rem;
}

.review__location {
  font-style: italic;
}

.anon-review-image {
  width: 400px;
  height: 400px;
  background: var(--dark-pink);
  border-radius: 50%;
  opacity: 0.9;
  background-image: url(https://res.cloudinary.com/twomaidsengi/image/upload/f_auto,q_auto/v1618592386/next-site/jane-doe.jpg);
  background-position: center;
  background-size: 110%;
  background-repeat: no-repeat;
  filter: grayscale(100);
}

.reviews__accordion .card,
.reviews__accordion .card-header {
  background: transparent !important;
}

/* Tablet Styles --------------------------------------------------------------- */
@media screen and (max-width: 1350px) {
  .page-title .h1,
  .page-title h1 {
    font-size: 36px;
    margin: 0;
  }

  .static-page-title {
    margin-top: 2rem;
    /* margin-bottom: 4rem; */
  }

  .top-background-dots {
    margin-top: 6rem;
  }
  .top-background-dots.about{
    top: 0;
    margin-top: 0px;
  }
  .first-review-content {
    top: 70%;
    left: 40%;
  }

  .why-cta-container {
    max-width: 500px;
    width: 100%;
  }
}

/* Mobile Styles --------------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .why-cta-container {
    width: 100%;
    margin-top: 4rem;
  }

  .top-background-dots {
    height: 40vh;
    margin-top: 5rem;
  }
  .top-background-dots.about{
    top: 0;
    margin-top: 0px;
  }
  .first-review {
    margin-left: 0;
  }

  .first-review-content {
    top: 70%;
    left: 20%;
    /* padding: 2rem 4rem; */
    padding: 30px;
    font-size: 8px;
  }
  .anon-review-image,
  .first-review-content,
  .rounded-circle,
  .round-circle-container {
    height: 300px;
    width: 300px;
  }
}

@media screen and (max-width: 480px) {
  .why-cta-container {
    flex-direction: column;
  }

  .why-cta {
    margin-bottom: 2rem;
  }
}

#reviews-accordion {
    margin-top: 6rem;
}

.review-button {
    border: solid 1px var(--dark-pink);
    min-width: 250px;
}
  
@media (max-width: 1350px) {
    #reviews-accordion {
        margin-top: 12rem;
    }

    .first-review-content .review__content {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    #reviews-accordion {
        margin-top: 6rem;
    }
}
  
.careers-container {
    position: relative;
}

.careers-hero-image-container {
    width: 100%;
    height: 600px;
    display: block;
}

.careers-hero-image-container img {
    position: absolute;
    width: 100%;
    height: 600px;
    top: 0;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: left;
       object-position: left;
}

.careers-heading {
    color: var(--dark-pink);
    text-align: center;
    font-family: 'TiemposFine Regular';
    padding: 50px 0px;
    font-size: var(--large-heading);
}

.careers-sub-heading {
    color: var(--dark-pink);
    font-family: 'TiemposFine Regular';
    padding: 50px 0px;
    font-size: var(--sub-heading);
}

.careers-columns {
    -moz-column-count: 2;
         column-count: 2;
    padding-bottom: 50px;
    margin-bottom: 0;
}

.careers-maid-image-container {
    position: relative;
}

.grey-bg {
    background: var(--light-grey);
    height: 90%;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

.careers-future-section {
    display: grid;
    grid-template-columns: 65% 35%;
    justify-items: center;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 1rem;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
}

.careers-button-section p {
    text-align: center;
    margin-top: 1rem;
}

.careers-button-border {
    border: 3px solid var(--dark-pink);
    width: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    cursor: pointer;
    transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.careers-button-border:hover {
    background: var(--dark-pink);
}

.careers-button-border:hover > .careers-button {
    color: #fff;
}

.careers-button {
    transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    padding: 20px;
    font-size: 24px;
    text-transform: uppercase;
    word-wrap: break-word;
    text-align: center;
    width: 250px;
}

.careers-benefits-list {
    -moz-column-count: 2;
         column-count: 2;
    padding: 50px 0 50px 12px;
    line-height: 2.5;
}

.careers-grey-section {
    background: var(--light-grey);
    border-radius: 25px;
    padding: 50px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 4rem;
    -moz-column-gap: 4rem;
         column-gap: 4rem;
}

.careers-grey-section img {
    max-width: 250px;
    width: 100%;
}

.careers-grey-section p {
    max-width: 600px;
}

.careers-video-section {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    padding: 0 2rem;
    grid-column-gap: 2rem;
    -moz-column-gap: 2rem;
         column-gap: 2rem;
}

.careers-video {
    position: relative;
    cursor: pointer;
}

.career-vid-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    width: 100%;
    height: 100%;
    transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 1;
}

.career-vid-one {
    background-image: url("https://res.cloudinary.com/twomaidsengi/image/upload/q_auto/v1682369983/hubsite-reskin/video_thumb_1.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 425px;
    max-height: 333px;
    width: 100%;
    height: 300px;
}

.career-vid-two {
    background-image: url("https://res.cloudinary.com/twomaidsengi/image/upload/q_auto/v1682369983/hubsite-reskin/video_thumb_2.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 425px;
    max-height: 333px;
    width: 100%;
    height: 300px;
}

.career-vid-three {
    background-image: url("https://res.cloudinary.com/twomaidsengi/image/upload/q_auto/v1682369983/hubsite-reskin/video_thumb_3.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 425px;
    max-height: 333px;
    width: 100%;
    height: 300px;
}

.career-vid-four {
    background-image: url("https://res.cloudinary.com/twomaidsengi/image/upload/q_auto/v1682369983/hubsite-reskin/video_thumb_4.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 425px;
    max-height: 333px;
    width: 100%;
    height: 300px;
}

.career-vid-one:hover > .career-vid-gradient,
.career-vid-two:hover > .career-vid-gradient,
.career-vid-three:hover > .career-vid-gradient,
.career-vid-four:hover > .career-vid-gradient {
    opacity: 0;
}

.careers-video img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.careers-video p {
    position: absolute;
    bottom: 0;
    left: 8px;
    color: var(--white);
}

@media (max-width: 991px) {
    .careers-future-section {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
        row-gap: 1rem;
    }

    .careers-grey-section {
        flex-direction: column;
        grid-row-gap: 2rem;
        row-gap: 2rem;
    }
}

@media (max-width: 768px) {
    .careers-columns {
        -moz-column-count: 1;
             column-count: 1;
    }

    .careers-video-section {
        display: flex;
        flex-wrap: wrap;
        grid-row-gap: 2rem;
        row-gap: 2rem;
        padding: 0 2rem;
    }

    .careers-button {
        width: 100%;
        max-width: 100%;
    }

    .careers-button-border {
        width: 100%;
        max-width: 100%;
    }
}
