.about-us-top-content h1 {
  color: var(--dark-pink);
  font-family: 'TiemposFine Regular';
  padding: 50px 0px;
}
.about-us-franchisee-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 200px;
}
.about-us-top-content {
  display: flex;
  flex-direction: column;
  /* margin-top: 70px; */
}
.about-us-top-content h5,
.about-us-franchisee-content h5 {
  color: var(--dark-pink);
  margin-bottom: 40px;
  font-family: 'Gotham Medium';
}
.about-us-franchisee-content h5 {
  margin-top: 80px;
}
.about-us-photo-container {
  position: relative;
  max-width: 800px;
  margin-top: 50px;
}
.about-us-photo-container img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 50%;

}
.about-us-circle {
  position: absolute;
  width: 400px;
  height: 400px;
  background-color: #f9ddebd1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  right: 40px;
  bottom: -130px;
  color: var(--dark-pink);
}
.about-us-circle p {
  width: 70%;
  margin: 5px auto;
}

@media screen and (max-width: 992px) {
  .about-us-franchisee-content,
  .about-us-text {
    flex-direction: column-reverse;
  }
  .about-us-text {
    margin-top: 200px;
  }
  .about-us-photo-container img {
    width: 250px;
    height: 250px;
  }
  .about-us-circle {
    width: 250px;
    height: 250px;
    left: 110px;
    bottom: -190px;
  }
  .about-us-circle {
    font-size: 12px;
  }
}
