@font-face {
    font-family: 'TiemposFine Regular';
    src: url('../../fonts/TestTiemposFine-Regular.otf');
    font-display: swap;
  }
  @font-face {
    font-family: 'TiemposFine Semibold';
    src: url('../../fonts/TestTiemposFine-Semibold.otf');
    font-display: swap;
  }
    