.static-page-title {
  color: var(--dark-pink);
  font-family: 'TiemposFine Semibold';
  font-size: var(--large-heading) !important;
  margin-top: 2rem;
  margin-bottom: 20px;
}

.static-page-content {
  font-family: 'Gotham Book';
  color: var(--grey);
  line-height: 1.75;
}

.static-page-content .h5 {
  font-family: 'Gotham Medium';
}

.why-cta-container {
  display: flex;
  justify-content: space-between;
  width: 500px;
  /* margin-bottom: 4rem; */
  margin-top: 1rem;
  margin: 2rem auto;
}

.why-cta {
  font-family: 'Gotham Medium';
}

.top-background-dots {
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: -1;
  top: 0;
  transform: rotate(180deg);
  background-image: url('https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,q_auto,w_1920/v1670256573/hubsite-reskin/opaque-dots.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 80vh;
  margin-top: 10rem;
}
.top-background-dots.about{
  top: -130px;
}
.reviews__review {
  border: none;
}

.first-review {
  position: relative;
  margin-left: 4rem;
}

.rounded-circle {
  filter: grayscale(100);
  width: 400px;
  height: auto;
}

.first-review-section {
  margin-bottom: 6rem;
}

.first-review-content {
  position: absolute;
  top: 25%;
  background: #f9ddebe3;
  left: 70%;
  color: var(--dark-pink);
  border-radius: 50%;
  width: 400px;
  height: 400px;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.review__name,
.review__location {
  font-size: var(--paragraph-font);
}

.review__content {
  font-size: 15px;
}

.review__name {
  font-family: 'Gotham Medium';
  font-style: italic;
  color: #832e54;
  margin-top: 1rem;
}

.review__location {
  font-style: italic;
}

.anon-review-image {
  width: 400px;
  height: 400px;
  background: var(--dark-pink);
  border-radius: 50%;
  opacity: 0.9;
  background-image: url(https://res.cloudinary.com/twomaidsengi/image/upload/f_auto,q_auto/v1618592386/next-site/jane-doe.jpg);
  background-position: center;
  background-size: 110%;
  background-repeat: no-repeat;
  filter: grayscale(100);
}

.reviews__accordion .card,
.reviews__accordion .card-header {
  background: transparent !important;
}

/* Tablet Styles --------------------------------------------------------------- */
@media screen and (max-width: 1350px) {
  .page-title .h1,
  .page-title h1 {
    font-size: 36px;
    margin: 0;
  }

  .static-page-title {
    margin-top: 2rem;
    /* margin-bottom: 4rem; */
  }

  .top-background-dots {
    margin-top: 6rem;
  }
  .top-background-dots.about{
    top: 0;
    margin-top: 0px;
  }
  .first-review-content {
    top: 70%;
    left: 40%;
  }

  .why-cta-container {
    max-width: 500px;
    width: 100%;
  }
}

/* Mobile Styles --------------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .why-cta-container {
    width: 100%;
    margin-top: 4rem;
  }

  .top-background-dots {
    height: 40vh;
    margin-top: 5rem;
  }
  .top-background-dots.about{
    top: 0;
    margin-top: 0px;
  }
  .first-review {
    margin-left: 0;
  }

  .first-review-content {
    top: 70%;
    left: 20%;
    /* padding: 2rem 4rem; */
    padding: 30px;
    font-size: 8px;
  }
  .anon-review-image,
  .first-review-content,
  .rounded-circle,
  .round-circle-container {
    height: 300px;
    width: 300px;
  }
}

@media screen and (max-width: 480px) {
  .why-cta-container {
    flex-direction: column;
  }

  .why-cta {
    margin-bottom: 2rem;
  }
}
