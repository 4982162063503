.book__summary {
  font-family: 'Gotham Medium';
}
.book__summary hr {
  color: var(--dark-pink);
  /* height: 1px; */
  background-color: var(--dark-pink);
  opacity: 0.4;
}
.summary-with-order {
  display: flex;
  justify-content: space-between;
  color: var(--dark-pink);
  font-family: 'Gotham Bold';
}
.summary-with-order .h3 {
  margin-bottom: 0px;
}
[data-summary='package'],
[data-summary='appointmentDate'] {
  color: var(--dark-pink);
}
[data-summary='squareFootage'],
[data-summary='bedrooms'],
[data-summary='bathrooms'],
[data-summary='people'],
[data-summary='pets'],
.appointmentTime {
  color: var(--grey) !important;
  font-family: 'Gotham Book';
}

.booking-order-number {
  font-family: 'Gotham Book';
}

@media screen and (max-width: 768px) {
  .book__summary {
    margin-top: 0 !important;
  }

  .booking-summary-hr {
    display: none;
  }
}