@font-face {
  font-family: 'Gotham Book';
  src: url('../../fonts/Gotham-Book.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Medium';
  src: url('../../fonts/Gotham-Medium.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Gotham Bold';
  src: url('../../fonts/Gotham-Bold.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Gotham Book Italic';
  src: url('../../fonts/Gotham-BookItalic.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Gotham Medium Italic';
  src: url('../../fonts/Gotham-MediumItalic.otf');
  font-display: swap;
}
