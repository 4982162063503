.rotating-text-section {
  margin-top: 4rem;
}

.rotating-text-section h1 {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  font-size: var(--large-heading);
  margin-bottom: 2rem;
  max-width: 700px;
  position: relative 
}

.rotating-mobile-break {
  display: none;
}

.rotating-words {
	display: inline;
	text-indent: 12px;
  white-space: nowrap;
}

.rotating-words span {
	animation: topToBottom 12.5s linear infinite 0s;
	-ms-animation: topToBottom 12.5s linear infinite 0s;
	-webkit-animation: topToBottom 12.5s linear infinite 0s;
	color: var(--dark-pink);
	opacity: 0;
	overflow: hidden;
	position: absolute;
}
.rotating-words span:nth-child(2) {
	animation-delay: 2.5s;
	-ms-animation-delay: 2.5s;
	-webkit-animation-delay: 2.5s;
}
.rotating-words span:nth-child(3) {
	animation-delay: 5s;
	-ms-animation-delay: 5s;
	-webkit-animation-delay: 5s;
}
.rotating-words span:nth-child(4) {
	animation-delay: 7.5s;
	-ms-animation-delay: 7.5s;
	-webkit-animation-delay: 7.5s;
}
.rotating-words span:nth-child(5) {
	animation-delay: 10s;
	-ms-animation-delay: 10s;
	-webkit-animation-delay: 10s;
}

@keyframes topToBottom {
	0% { opacity: 0; }
	5% { opacity: 0; transform: translateY(-50px); }
	10% { opacity: 1; transform: translateY(0px); }
	25% { opacity: 1; transform: translateY(0px); }
	30% { opacity: 0; transform: translateY(50px); }
	80% { opacity: 0; }
	100% { opacity: 0; }
}

.rotating-text-section p {
  font-family: 'Gotham Book';
  font-size: var(--large-paragraph-text);
  line-height: var(--large-paragraph-line-height);
  color: var(--grey);
  max-width: 700px;
}

.house-cleaning-experts-section {
  padding: 4rem 0 4rem 0;
}

.house-cleaning-experts-section h2 {
  font-size: 24px;
  color: var(--dark-pink);
  margin-bottom: 1rem;
  font-family: 'Gotham Medium';
}

.house-cleaning-experts-section p {
  font-size: 14px;
  color: var(--dark-grey);
  line-height: 1.75;
  margin-bottom: 4rem;
}

.house-cleaning-experts-section img {
  width: 100%;
  max-width: 40px;
  margin-bottom: 2rem;
}

.house-cleaning-experts-section h3 {
  color: var(--dark-pink);
  font-size: 18px;
  font-family: 'Gotham Medium';
  margin-bottom: 10px;
  min-width: 275px;
}

.house-cleaning-experts-section a {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 250px;
  color: #fff !important;
}

.home-services__service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.home-logos {
  background: var(--light-pink) !important;
}

.home-difference {
  background: var(--light-grey);
  padding: 2rem 0 4rem 0;
}

.home-difference h2 {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  font-size: var(--sub-heading);
}

.home-difference p {
  text-align: center;
  margin: 2rem auto 3rem auto;
  line-height: 30px;
}

.home-difference a {
  margin: 0 auto;
  border-color: var(--dark-pink);
  color: var(--dark-pink);
  font-family: 'Gotham Bold';
}

.powered-by-caring-image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 3rem;
}

.powered-by-caring-group {
  flex: 1 calc(50% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.powered-by-caring-group a {
  color: var(--dark-pink);
  font-size: 18px;
  font-family: 'Gotham Medium';
  margin-top: 2rem;
}

.powered-by-caring-group img {
  width: 300px;
  max-height: 150px;
  object-fit: contain;
  border-radius: 8px;
  object-position: bottom;
}

.powered-by-caring-group:nth-child(3) img {
  width: 200px;
}


.reviews-carousel-container {
  background: var(--light-pink);
  padding: 2rem 0 6rem 0;
}

.reviews-carousel-container h2 {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  font-size: var(--sub-heading);
  margin-bottom: 1rem;
}

.reviews-carousel-container .carousel-inner {
  height: auto;
  padding: 1rem 0;
}

.reviews-carousel-container .container {
  max-width: 100vw;
}

.reviews-carousel-container .review-card {
  background: var(--white);
  max-width: 450px;
  width: 100%;
  height: 100%;
  min-height: 375px;
  padding: 1rem 1.5rem;
  border-radius: 0;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  box-shadow: rgb(0 0 0 / 35%) 4.95px 4.95px 2.6px;
}

.reviews-carousel-container .review-card .review__name {
  font-family: 'Gotham Bold';
  color: var(--dark-pink);
}

.reviews-carousel-container .review-card .review__location {
  color: var(--dark-pink);
  font-family: 'Gotham Book';
}

.reviews-carousel-container .btn {
  margin-top: 5rem;
}

.dark-bg-btn {
  color: var(--white) !important;
  background: var(--dark-pink);
  border: solid 1px var(--dark-bg-pink) !important;
}

.dark-bg-btn:hover {
  color: var(--dark-pink) !important;
  border: solid 1px var(--dark-pink) !important;
  background: var(--white);
}

@media screen and (max-width: 1000px) {
  .rotating-mobile-break {
    display: block;
  }

  .rotating-words {
    display: block;
    text-indent: 0;
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 991px) {
  .house-cleaning-experts-section h3 {
    min-width: 100%;
  }
}

@media (max-width: 768px) {
  .rotating-text-section .h2 {
    font-size: var(--sub-heading);
    margin-bottom: 1rem;
    font-size: 40px;
  }

  .rotating-text-section p {
    font-size: 18px;
    line-height: 1.75;
  }

  .rotating-words {
    margin-bottom: 4rem;
  }

  .house-cleaning-experts-section h3 {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .home-difference h2,
  .reviews-carousel-container h2 {
    font-size: var(--large-paragraph-text);
    font-family: 'Gotham Bold';
  }

  .home-difference a {
    width: 250px;
  }

  .home-difference p {
    padding: 0 1.5rem;
  }

  .powered-by-caring-image-container {
    justify-content: center;
    row-gap: 4rem;
  }

  .powered-by-caring-image:nth-child(1) {
    max-width: 200px;
  }
  
  .powered-by-caring-image:nth-child(2) {
    max-width: 200px;
    margin-left: 0;
  }
  
  .powered-by-caring-image:nth-child(3) {
    max-width: 200px;
  }

  .services-btn {
    margin-bottom: 2rem !important;
  }

  .home-services__service {
    margin-bottom: -2rem !important;
  }
}
@media (max-width: 575px) {
  .rotating-words {
    display: block;
    text-indent: 0;
    margin-bottom: 4rem;
  }

  .home-services-container {
    background-color: var(--light-pink);
    padding: 40px 0px;
  }
  .house-cleaning-experts-section {
    padding: 3rem 0 0 0;
  }
}
