.service-description {
    padding: 3rem 15%;
    margin: 0px;

    @media (max-width: 1269.98px) {
        padding-left: 10%;
        padding-right: 10%;
    }

    @media (max-width: 575.98px) {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.service-page-title {
    color: #d4357f;
    font-family: 'TiemposFine Semibold';
    font-size: var(--large-heading) !important;
}
  
.service-description-section {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
}

.service-description h2 {
    color: #d4357f;
    font-size: 50px;
    font-family: 'TiemposFine semibold';
}

.service-description-icon-text {
    color: #d4357f;
    font-family: 'Gotham Bold';
}
  
.service-description-section-svg {
    filter: invert(25%) sepia(92%) saturate(2469%) hue-rotate(311deg) brightness(84%) contrast(93%);
    width: 90px;
    min-width: 90px;
    max-width: 120px;
    padding-bottom: .5rem;
}

.service-description-section-promise-svg {
    width: 270px;
    min-width: 270px;
    max-width: 270px;
    margin-top: -60px;
    margin-bottom: -100px;
}

.service-description-title {
    color: #d4357f;
}

.gotham-medium {
    font-family: 'Gotham Medium';
}

h3.service-description-title {
    font-weight: 100;
}

.service-description-content {
    display: flex;
    padding: 3rem;
    padding-left: 15%;
    padding-right: 15%;
    margin: 0px;

    @media (max-width: 1269.98px) {
        padding-left: 10%;
        padding-right: 10%;
    }
    
    @media (max-width: 575.98px) {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.gray-background {
    background-color: #F4F4F4;
}

.service-learn-more {
    font-family: 'Gotham Book';
    font-size: 1.5rem;
}

.service-description-img {
    @media (min-width: 768px) {
        max-width: 240px;
    }

    @media (max-width: 767.98px) {
        width: 100%;
        margin-bottom: 2rem;
    }
}

.service-description-img-col  {
    @media (min-width: 768px) {
        max-width: 340px !important;
    }
}

.alterating-description {
    @media (max-width: 813.98px) {
        flex-direction: column;
    }
}

.service-description-small-image {
    margin: 0px;
    @media (min-width: 768px) {
        max-width: 200px;
    }

    @media (max-width: 767.98px) {
        width: 100%;
        margin-bottom: 2rem;
    }
}

.small-service-button {
    display: flex;
    color: var(--white);
    border: solid 2px #d4357f;
    background: #d4357f;
    padding: .5rem 1rem;
    transition: var(--button-transition);
    align-items: center;
    justify-content: center;
    margin-top: auto;
    max-width: 350px;
}

.small-service-button:hover {
    background: var(--white);
    color: #d4357f;
    border-width: 2px;
}

.services-description-list {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 0;

    li {
        padding-bottom: 1rem;
    }
}

.mr-5 {
    margin-right: 3rem;
}

.mr-3 {
    margin-right: 1.5rem;
}

.pt-0 {
    padding-top: 0rem;
}

.contact-us {
    display: flex;
    flex-wrap: wrap;
    color: var(--white);
    border: solid 2px #d4357f;
    background: #d4357f;
    padding: 1rem 2rem;
    transition: var(--button-transition);
    margin-left: 15%;
    margin-right: 15%;

    @media (max-width: 1269.98px) {
        margin-left: 10%;
        margin-right: 10%;
    }
    
    @media (max-width: 575.98px) {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.contact-us-flex {
    flex-direction: row;
    @media (max-width: 1591.98px) {
        flex-direction: column;
    }
}

.contact-us-button {
    display: flex;
    color: #d4357f;
    border: solid 2px var(--white);
    background: var(--white);
    padding: .5rem 1rem;
    transition: var(--button-transition);
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 245px;
    max-width: 250px;
    font-family: "Gotham Bold";
}

.contact-us-button-button:hover {
    background: #d4357f;
    color: var(--white);
    border-width: 2px;
}

.flex-xs-wrap {
    @media (max-width: 575.98px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.flex-wrap-deep-clean {
    flex-wrap: nowrap;
    @media (max-width: 893.98px) {
        flex-wrap: wrap;
    }
}

.promise-description {
    display: flex;
    flex-wrap: nowrap;
    @media (max-width: 667.98px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.coves-bold {
    font-family: 'Coves bold';
}

.vertical-surfaces {
    margin-left: 1rem;
    padding: 1.5rem;
    min-width: 310px;
    max-height: 234px;

    ul {
        margin-bottom: 0px;
    }

    @media (max-width: 893.98px) {
        margin-left: 0px;
        flex-wrap: wrap;
    }
}

.gotham-bold {
    font-family: "Gotham bold";
}

.service-description-ready-check {
    display: flex;
    min-width: 270px;
    justify-content: center;
    margin-right: 1.5rem;
    max-height: 210px;
}

.comparison-table-pink-bg {
    background-color: #FFCAE6;
}

.comparison-table-center {
    margin-left: auto;
    margin-right: auto;
}

.comparison-table-premium {
    background: rgba(223, 25, 149, 0.5);
}

.comparison-table-premium-title {
    background: #d4357f;
    color: var(--white);
}

.comparison-table-header {
    background: #FBF7EF;
}

.table td {
    vertical-align: middle;
}

@media (max-width: 575.98px) {
    .table {
        font-size: 12px !important;
    }

    .service-description-table-container {
        padding: 0 !important;
        margin-top: 2rem;
    }

    .table td {
        padding: .5rem !important;
    }

    .service-description-section-promise-svg {
        padding-top: 1rem;
    }

    .service-description-ready-check {
        padding-bottom: 1rem;
    }
}