.navigation {
  /* height: 70px; */
  background: #fff;
  font-family: 'Gotham Medium';
}
.header {
  position: relative ;
}
.brand {
  height: 100%;
  width: 175px;
  position: relative;
  margin-left: 20px;
}
.brand img {
  /* height: 100%; */
  /* width: 100%; */
  object-fit: contain;
}

.nav-container {
  /* width: 90%;
  margin: 0 auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 100%; */
  position: relative;
  height: 70px;
  padding-bottom: 20px;
}

nav {
  float: right;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav ul li {
  float: left;
  position: relative;
}
nav ul li a,
nav ul li a:visited {
  display: block;
  padding: 0 10px;
  font-size: 13px;
  margin-top: 22px;

  color: #75757A;
  text-decoration: none;
}
nav ul li a:hover,
nav ul li a:visited:hover {
  opacity: 0.7;
  color: #75757A;
}
nav ul li a:not(:only-child):after,
nav ul li a:visited:not(:only-child):after {
  padding-left: 4px;
  content: ' ▾';
}
nav ul li ul li {
  min-width: 190px;
}
nav ul li ul li a {
  padding: 15px;
  line-height: 20px;
}

.nav-dropdown {
  position: absolute;
  display: none;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

/* Mobile navigation */
.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  width: 70px;
}

.nav-list {
  padding-right: 20px;
  background: #fff;
  padding-left: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #75757A;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  z-index: 1;
  width: 155px;
  right: -5px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: #75757A;
  text-decoration: none;
  display: block;
  margin: 0;
  width: 155px;
  padding: 5px 10px;
}

.dropdown-content a:hover {
  background-color: #f2f2f2;
}

.header-link-booking {
  color: var(--white) !important;
  padding: 8px 10px;
  border: 2px solid var(--dark-pink);
  background: var(--dark-pink);
  margin-top: 10px;
  margin-left: 10px;
}
.header-link-booking:hover {
  color: var(--dark-pink) !important;
  background: var(--white);
}

.top-nav {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  padding-top: 30px;
  font-size: 12px;
  font-family: 'Gotham Medium';
  color: var(--grey);
}
.top-nav-left span {
  color: var(--dark-pink);
  font-family: 'Gotham Bold';
}
.top-nav-left span:hover {
  cursor: pointer;
  opacity: 0.7;
}

.top-nav-right {
  display: flex;
  flex-direction: row-reverse;
}

.top-nav-right li {
  list-style: none;
  margin-left: 1rem;
}

.top-nav-right span {
  color: var(--dark-pink);
  font-family: 'Gotham Bold';
}
.navigation hr {
  margin-left: 20px;
  margin-right: 20px;
  color: var(--grey);
}
.nav-bottom-mobile {
  display: none;
}
@media only screen and (max-width: 1192px) {
  .nav-container {
    padding-top: 20px;
  }
  .top-nav,
  .navigation hr {
    display: none;
  }
  .nav-mobile {
    display: block;
  }
  .nav-bottom-mobile {
    display: block;
    background-color: var(--light-pink);
    /* color: var(--grey); */
    font-size: 12px;
    padding: 10px 0px;
    padding-left: 20px;
  }
  .nav-bottom-mobile span {
    color: var(--dark-bg-pink);
    margin-left: 3px;
  }
  .nav-bottom-mobile span:hover {
    opacity: 0.7;
    /* cursor: pointer; */
  }
  nav {
    width: 100%;
    padding: 70px 0 15px;
  }
  nav ul {
    display: none;
  }
  nav ul li {
    float: none;
  }
  nav ul li a {
    padding: 15px;
    line-height: 20px;
  }
  nav ul li ul li a {
    padding-left: 30px;
  }
  nav ul li a,
  nav ul li a:visited {
    margin: 0px;
    padding: 0px;
  }
  .nav-dropdown {
    position: static;
  }
  .nav-list {
    position: absolute;
    right: 0;
    width: 100%;
    top: 70px;
  }
  .nav-list li {
    /* width: 360px; */
    padding: 17px;
    border-bottom: 1px solid #e02d8859;
  }
  .nav-list li a:hover {
    opacity: 0.5;
  }
  .nav-list li:nth-child(1) {
    border-top: 1px solid #e02d8859;
  }

  .nav-list .social-links-reskin-container li:nth-child(1) {
    border-top: none !important;
  }

  .nav-list li:nth-child(10) {
    border-bottom: none;
    display: none;
  }
  .header-link-booking,
  .header-link-li-mobile {
    display: none;
  }
}
@media screen and (min-width: 1193px) {
  .nav-list {
    display: block !important;
  }
}
#nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
  border: none;
  background: transparent;
}

#nav-toggle:focus,
#nav-toggle:active {
  outline: none;
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 2px;
  width: 35px;
  background: var(--dark-pink);
  position: absolute;
  display: block;
  content: '';
transition: all 300ms ease-in-out;  
}
#nav-toggle span:before {
  top: -10px;
}
#nav-toggle span:after {
  bottom: -10px;
}
#nav-toggle.active span {
  background-color: transparent;
}
#nav-toggle.active span:before,
#nav-toggle.active span:after {
  top: 0;
}
#nav-toggle.active span:before {
  transform: rotate(45deg);
}
#nav-toggle.active span:after {
  transform: rotate(-45deg);
}