.sticky-cta {
  /* background-color: var(--light-pink); */
}

.book__total-cost {
  display: flex;
  flex-direction: column;
}
.sticky-footer-lightbox {
  display: flex;
  align-items: center;
}

.sticky-footer-lightbox div {
  margin: 0 5px;
}

.sticky-footer-lightbox hr {
  width: 100%;
  background-color: var(--dark-pink);
  height: 1px;
}
.book__total-cost {
  padding-top: 15px;
}
.book__total-cost strong {
  font-family: 'Gotham Book';
  color: var(--dark-pink);
  font-size: 22px;
}
.book__total {
  color: var(--dark-pink);
  font-family: 'Gotham Bold';
  font-size: 44px;
}

.popover-basic-content p {
  margin-bottom: 5px;
  font-size: 14px;
}
.popover-basic-content {
  font-family: 'Gotham Book';
  color: var(--dark-pink);
}
.popover-basic-content .border-top {
  border-top: 1px solid var(--dark-pink) !important;
}
@media screen and (max-width: 768px) {
  .sticky-footer-content-container {
    flex-direction: column;
  }
  .sticky-footer-content-container .sticky-cta__phone {
    margin: 0 !important;
    margin-top: 40px !important;
  }
  .sticky-footer-content-container button {
    margin-right: 0px !important;
  }
}
@media (max-width: 489.98px) {
  .sticky-footer-lightbox {
    width: 50%;
    margin: auto;
  }
}
