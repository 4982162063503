.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: '';
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  pointer-events: none;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #751d46;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #751d46;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
/* .flatpickr-day.nextMonthDay, */
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.nextMonthDay {
  color: #757575; /* show that these dates are selectable */
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: '';
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/**
 * Breakpoints
 */
/**
 * Colors
 */
/**
 * Fonts
 */
/**
 * Links
 */
/**
 * Buttons
 */
/**
 * Cards
 */
/**
 * Forms
 */
/**
 * Nav
 */
/**
 * Shadows
 */
/**
 * Transitions
 */
/**
 * Import Bootstrap styles
 *
 * Pulled this list from ~boostrap/scss/bootstrap.scss.
 * Using our own copy allows us to comment out irrelevant styles.
 * The caveat being that this list may need to be adjusted
 * after changing Bootstrap to a different version.
 */
/**
 * Bootstrap imports
 * If you update Bootstrap, you'll need to pull this updated list from ~bootstrap/scss/bootstrap.scss
 */
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  /* --dark-pink: #e02d88; */
  --dark-pink: #cf2680;
  --dark-bg-pink: #B92273;
  --light-pink: #f9ddeb;
  --white: #fff;
  --light-grey: #efedee;
  --button-transition: all ease 0.3s;
  --grey: #7b6c73;
  /* --grey: #9f9e9d; */
  --dark-grey: #5f6060;
  --container-width: 1130px;
  --large-heading: 64px;
  --sub-heading: 42px;
  --paragraph-text: 16px;
  --paragraph-line-height: 1.75;
  --large-paragraph-text: 24px;
  --large-paragraph-line-height: 40px;
  --breakpoint-xxs: 0;
  --breakpoint-xs: 375px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4d4d4d;
  text-align: left;
  background-color: #fff;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #d4357f;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #d4357f;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
  -ms-text-size-adjust: 100%; /* prevent zooming in IE 10+ */
  -moz-text-size-adjust: 100%; /* prevent zooming in Firefox */
  -webkit-text-size-adjust: 100%; /* prevent zooming in Safari and Chrome */
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 3.75rem;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.5rem + 3vw);
  }
}

h2,
.h2 {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  h2,
  .h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
}

h3,
.h3 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  h3,
  .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
}

h4,
.h4 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  h4,
  .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: '\2014\00A0';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* @media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
} */

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-xs,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-xs,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-xs,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs,
.col-xs-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 375px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xs-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xs-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xs-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xs-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xs-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xs-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xs-first {
    order: -1;
  }

  .order-xs-last {
    order: 13;
  }

  .order-xs-0 {
    order: 0;
  }

  .order-xs-1 {
    order: 1;
  }

  .order-xs-2 {
    order: 2;
  }

  .order-xs-3 {
    order: 3;
  }

  .order-xs-4 {
    order: 4;
  }

  .order-xs-5 {
    order: 5;
  }

  .order-xs-6 {
    order: 6;
  }

  .order-xs-7 {
    order: 7;
  }

  .order-xs-8 {
    order: 8;
  }

  .order-xs-9 {
    order: 9;
  }

  .order-xs-10 {
    order: 10;
  }

  .order-xs-11 {
    order: 11;
  }

  .order-xs-12 {
    order: 12;
  }

  .offset-xs-0 {
    margin-left: 0;
  }

  .offset-xs-1 {
    margin-left: 8.33333%;
  }

  .offset-xs-2 {
    margin-left: 16.66667%;
  }

  .offset-xs-3 {
    margin-left: 25%;
  }

  .offset-xs-4 {
    margin-left: 33.33333%;
  }

  .offset-xs-5 {
    margin-left: 41.66667%;
  }

  .offset-xs-6 {
    margin-left: 50%;
  }

  .offset-xs-7 {
    margin-left: 58.33333%;
  }

  .offset-xs-8 {
    margin-left: 66.66667%;
  }

  .offset-xs-9 {
    margin-left: 75%;
  }

  .offset-xs-10 {
    margin-left: 83.33333%;
  }

  .offset-xs-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #4d4d4d;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #4d4d4d;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffd0e6;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ffa8d0;
}

.table-hover .table-primary:hover {
  background-color: #ffb7d8;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ffb7d8;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cdcdcd;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #a2a2a2;
}

.table-hover .table-secondary:hover {
  background-color: silver;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: silver;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c1ebfb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8bdaf7;
}

.table-hover .table-success:hover {
  background-color: #a9e3f9;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a9e3f9;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: white;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: white;
}

.table-hover .table-light:hover {
  background-color: #f2f2f2;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f2f2f2;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #ffd0e6;
}

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody + tbody {
  border-color: #ffa8d0;
}

.table-hover .table-pink:hover {
  background-color: #ffb7d8;
}

.table-hover .table-pink:hover > td,
.table-hover .table-pink:hover > th {
  background-color: #ffb7d8;
}

.table-light-pink,
.table-light-pink > th,
.table-light-pink > td {
  background-color: #fffcfd;
}

.table-light-pink th,
.table-light-pink td,
.table-light-pink thead th,
.table-light-pink tbody + tbody {
  border-color: #fff9fb;
}

.table-hover .table-light-pink:hover {
  background-color: #ffe3ec;
}

.table-hover .table-light-pink:hover > td,
.table-hover .table-light-pink:hover > th {
  background-color: #ffe3ec;
}

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #c1ebfb;
}

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #8bdaf7;
}

.table-hover .table-blue:hover {
  background-color: #a9e3f9;
}

.table-hover .table-blue:hover > td,
.table-hover .table-blue:hover > th {
  background-color: #a9e3f9;
}

.table-light-blue,
.table-light-blue > th,
.table-light-blue > td {
  background-color: #f9fdff;
}

.table-light-blue th,
.table-light-blue td,
.table-light-blue thead th,
.table-light-blue tbody + tbody {
  border-color: #f3fbfe;
}

.table-hover .table-light-blue:hover {
  background-color: #e0f5ff;
}

.table-hover .table-light-blue:hover > td,
.table-hover .table-light-blue:hover > th {
  background-color: #e0f5ff;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 374.98px) {
  .table-responsive-xs {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xs > .table-bordered {
    border: 0;
  }
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ffd8ea;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 0.6;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 0.6;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 0.8;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #4d4d4d;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;      

   
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
  width: 1.5em;
  height: 1.5em;
}
.form-check-input:checked {
  background-color: var(--pink);
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #20b7f0;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(32, 183, 240, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #20b7f0;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2320b7f0' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #20b7f0;
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #20b7f0;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2320b7f0' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #20b7f0;
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #20b7f0;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #20b7f0;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #20b7f0;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #50c6f3;
  background-color: #50c6f3;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #20b7f0;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #20b7f0;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #20b7f0;
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 700;
  color: #4d4d4d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #4d4d4d;
  text-decoration: none;
}

.cta-buttons .btn:hover:after {
  content: '';
  position: absolute;
  left: 0;
  top: -7px;
  height: 48px;
  width: 101%;
  box-sizing: border-box;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: var(--white);
  background: var(--dark-pink);
  border-color: var(--dark-pink);
  font-family: 'Gotham Bold';
}

.btn-primary:hover {
  color: var(--dark-pink) !important;
  background-color: var(--white) !important;
  border-color: var(--dark-pink);
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: var(--dark-pink);
  border-color: var(--dark-pink);
  box-shadow: 0 0 0 0.2rem rgba(255, 113, 179, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--dark-pink);
  border-color: var(--dark-pink);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #d4357f;
  border-color: #ff1883;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 113, 179, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #343434;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #343434;
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #343434;
  border-color: #2d2d2d;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-success:hover {
  color: #fff;
  background-color: #0fa3db;
  border-color: #0e9acf;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #0fa3db;
  border-color: #0e9acf;
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #0e9acf;
  border-color: #0d91c3;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #4d4d4d;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #4d4d4d;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #4d4d4d;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(228, 176, 18, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #4d4d4d;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #4d4d4d;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 176, 18, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #d4357f;
  background-color: #fff;
  border-color: #fff;
}

.btn-light:hover {
  color: #4d4d4d;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-light:focus,
.btn-light.focus {
  color: #4d4d4d;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(228, 228, 228, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #4d4d4d;
  background-color: #fff;
  border-color: #fff;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #4d4d4d;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 228, 228, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-pink {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-pink:hover {
  color: #fff;
  background-color: #ff3290;
  border-color: #d4357f;
}

.btn-pink:focus,
.btn-pink.focus {
  color: #fff;
  background-color: #ff3290;
  border-color: #d4357f;
  box-shadow: 0 0 0 0.2rem rgba(255, 113, 179, 0.5);
}

.btn-pink.disabled,
.btn-pink:disabled {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #d4357f;
  border-color: #ff1883;
}

.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 113, 179, 0.5);
}

.btn-light-pink {
  color: #4d4d4d;
  background-color: #fff4f7;
  border-color: #fff4f7;
}

.btn-light-pink:hover {
  color: #4d4d4d;
  background-color: #ffcedb;
  border-color: #ffc1d2;
}

.btn-light-pink:focus,
.btn-light-pink.focus {
  color: #4d4d4d;
  background-color: #ffcedb;
  border-color: #ffc1d2;
  box-shadow: 0 0 0 0.2rem rgba(228, 219, 222, 0.5);
}

.btn-light-pink.disabled,
.btn-light-pink:disabled {
  color: #4d4d4d;
  background-color: #fff4f7;
  border-color: #fff4f7;
}

.btn-light-pink:not(:disabled):not(.disabled):active,
.btn-light-pink:not(:disabled):not(.disabled).active,
.show > .btn-light-pink.dropdown-toggle {
  color: #4d4d4d;
  background-color: #ffc1d2;
  border-color: #ffb4c9;
}

.btn-light-pink:not(:disabled):not(.disabled):active:focus,
.btn-light-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-light-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 219, 222, 0.5);
}

.btn-blue {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-blue:hover {
  color: #fff;
  background-color: #0fa3db;
  border-color: #0e9acf;
}

.btn-blue:focus,
.btn-blue.focus {
  color: #fff;
  background-color: #0fa3db;
  border-color: #0e9acf;
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.btn-blue.disabled,
.btn-blue:disabled {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #0e9acf;
  border-color: #0d91c3;
}

.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.btn-light-blue {
  color: #4d4d4d;
  background-color: #e8f8fe;
  border-color: #e8f8fe;
}

.btn-light-blue:hover {
  color: #4d4d4d;
  background-color: #c3edfc;
  border-color: #b7e9fc;
}

.btn-light-blue:focus,
.btn-light-blue.focus {
  color: #4d4d4d;
  background-color: #c3edfc;
  border-color: #b7e9fc;
  box-shadow: 0 0 0 0.2rem rgba(209, 222, 227, 0.5);
}

.btn-light-blue.disabled,
.btn-light-blue:disabled {
  color: #4d4d4d;
  background-color: #e8f8fe;
  border-color: #e8f8fe;
}

.btn-light-blue:not(:disabled):not(.disabled):active,
.btn-light-blue:not(:disabled):not(.disabled).active,
.show > .btn-light-blue.dropdown-toggle {
  color: #4d4d4d;
  background-color: #b7e9fc;
  border-color: #abe5fb;
}

.btn-light-blue:not(:disabled):not(.disabled):active:focus,
.btn-light-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-light-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 222, 227, 0.5);
}

.btn-outline-primary {
  color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #d4357f;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.5);
}

.btn-outline-secondary {
  color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: var(--dark-pink);
  border-color: var(--dark-pink);
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #4d4d4d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}

.btn-outline-success {
  color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #20b7f0;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #4d4d4d;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #4d4d4d;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #fff;
  border-color: #fff;
}

.btn-outline-light:hover {
  color: #4d4d4d;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #4d4d4d;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-pink {
  color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-pink:focus,
.btn-outline-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.5);
}

.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
  color: #d4357f;
  background-color: transparent;
}

.btn-outline-pink:not(:disabled):not(.disabled):active,
.btn-outline-pink:not(:disabled):not(.disabled).active,
.show > .btn-outline-pink.dropdown-toggle {
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.btn-outline-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.5);
}

.btn-outline-light-pink {
  color: #fff4f7;
  border-color: #fff4f7;
}

.btn-outline-light-pink:hover {
  color: #4d4d4d;
  background-color: #fff4f7;
  border-color: #fff4f7;
}

.btn-outline-light-pink:focus,
.btn-outline-light-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 244, 247, 0.5);
}

.btn-outline-light-pink.disabled,
.btn-outline-light-pink:disabled {
  color: #fff4f7;
  background-color: transparent;
}

.btn-outline-light-pink:not(:disabled):not(.disabled):active,
.btn-outline-light-pink:not(:disabled):not(.disabled).active,
.show > .btn-outline-light-pink.dropdown-toggle {
  color: #4d4d4d;
  background-color: #fff4f7;
  border-color: #fff4f7;
}

.btn-outline-light-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-light-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 244, 247, 0.5);
}

.btn-outline-blue {
  color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-blue:focus,
.btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.5);
}

.btn-outline-blue.disabled,
.btn-outline-blue:disabled {
  color: #20b7f0;
  background-color: transparent;
}

.btn-outline-blue:not(:disabled):not(.disabled):active,
.btn-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-outline-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 183, 240, 0.5);
}

.btn-outline-light-blue {
  color: #e8f8fe;
  border-color: #e8f8fe;
}

.btn-outline-light-blue:hover {
  color: #4d4d4d;
  background-color: #e8f8fe;
  border-color: #e8f8fe;
}

.btn-outline-light-blue:focus,
.btn-outline-light-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 248, 254, 0.5);
}

.btn-outline-light-blue.disabled,
.btn-outline-light-blue:disabled {
  color: #e8f8fe;
  background-color: transparent;
}

.btn-outline-light-blue:not(:disabled):not(.disabled):active,
.btn-outline-light-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-light-blue.dropdown-toggle {
  color: #4d4d4d;
  background-color: #e8f8fe;
  border-color: #e8f8fe;
}

.btn-outline-light-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-light-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 248, 254, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #d4357f;
  text-decoration: none;
}

.btn-link:hover {
  color: #d4357f;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 4px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 4px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  cursor: pointer;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #d4357f;
  background-color: #d4357f;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ffd8ea;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: white;
  border-color: white;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  cursor: pointer;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #d4357f solid 2px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #d4357f;
  background-color: #d4357f;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(255, 88, 165, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(255, 88, 165, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(255, 88, 165, 0.5);
}

.custom-radio:hover {
  opacity: 0.7;
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 4px);
  left: calc(-2.25rem + 4px);
  width: calc(1rem - 8px);
  height: calc(1rem - 8px);
  background-color: #d4357f;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(255, 88, 165, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #ffd8ea;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 4px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 4px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 4px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #ffd8ea;
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: 'Browse';
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #d4357f;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: white;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #d4357f;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: white;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #d4357f;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: white;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e8f8fe;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #d4357f;
}

.navbar .nav-item,
.navbar .no-link-style {
  cursor: pointer;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #751d46;
  background-color: #e8f8fe;
  border-color: #e8f8fe;
}

@media (max-width: 991.98px) {
  .nav-tabs .nav-link.active {
    color: #d4357f;
    background-color: #e8f8fe;
    border-color: #d4357f;
  }
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #d4357f;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 374.98px) {
  .navbar-expand-xs > .container,
  .navbar-expand-xs > .container-fluid,
  .navbar-expand-xs > .container-sm,
  .navbar-expand-xs > .container-md,
  .navbar-expand-xs > .container-lg,
  .navbar-expand-xs > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 375px) {
  .navbar-expand-xs {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xs .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xs .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xs .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-xs > .container,
  .navbar-expand-xs > .container-fluid,
  .navbar-expand-xs > .container-sm,
  .navbar-expand-xs > .container-md,
  .navbar-expand-xs > .container-lg,
  .navbar-expand-xs > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xs .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xs .navbar-toggler {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar,
  .navbar-brand {
    padding: 0;
  }
  .navbar-brand img {
    height: 58px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: #4d4d4d;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #d4357f;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: #4d4d4d;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z' style='fill: %23ff58a5;'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: #4d4d4d;
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.navbar .link-no-style {
  color: var(--c-gray-800);
}
.navbar .link-no-style:hover,
.navbar .link-no-style:focus {
  color: var(--pink);
}

.header-link {
  color: var(--c-gray-800);
  margin-bottom: -1px;
  cursor: pointer;
  background-color: transparent;
  flex: 1 1 auto;
  text-align: center;
  /* flex-basis: 0;
  flex-grow: 1; */
  margin-right: 0.25rem;
  border-color: var(--pink);
  padding: 0.5rem;
  font-weight: 700;
  font-size: 0.875rem;
}

.header-link:hover,
.header-link:focus {
  color: var(--pink);
  margin-top: -2px;
}

@media (max-width: 1199.98px) {
  .header-link {
    text-align: left;
    padding: 0.5rem 0;
  }
}

.navbar-logo {
  cursor: pointer;
  width: 200px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2.5rem;
}

.card-subtitle {
  margin-top: -1.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 2.5rem;
}

.card-header {
  padding: 2.5rem 2.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 2.5rem 2.5rem;
  background-color: #fff;
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -1.25rem;
  margin-bottom: -2.5rem;
  margin-left: -1.25rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 2.5rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #852e56;
  background-color: #ffdeed;
  border-color: #ffd0e6;
}

.alert-primary hr {
  border-top-color: #ffb7d8;
}

.alert-primary .alert-link {
  color: #5f213d;
}

.alert-secondary {
  color: #282828;
  background-color: #dbdbdb;
  border-color: #cdcdcd;
}

.alert-secondary hr {
  border-top-color: silver;
}

.alert-secondary .alert-link {
  color: #0f0f0f;
}

.alert-success {
  color: #115f7d;
  background-color: #d2f1fc;
  border-color: #c1ebfb;
}

.alert-success hr {
  border-top-color: #a9e3f9;
}

.alert-success .alert-link {
  color: #0b3d50;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-light hr {
  border-top-color: #f2f2f2;
}

.alert-light .alert-link {
  color: #6c6c6c;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

.alert-pink {
  color: #852e56;
  background-color: #ffdeed;
  border-color: #ffd0e6;
}

.alert-pink hr {
  border-top-color: #ffb7d8;
}

.alert-pink .alert-link {
  color: #5f213d;
}

.alert-light-pink {
  color: #857f80;
  background-color: #fffdfd;
  border-color: #fffcfd;
}

.alert-light-pink hr {
  border-top-color: #ffe3ec;
}

.alert-light-pink .alert-link {
  color: #6b6667;
}

.alert-blue {
  color: #115f7d;
  background-color: #d2f1fc;
  border-color: #c1ebfb;
}

.alert-blue hr {
  border-top-color: #a9e3f9;
}

.alert-blue .alert-link {
  color: #0b3d50;
}

.alert-light-blue {
  color: #798184;
  background-color: #fafeff;
  border-color: #f9fdff;
}

.alert-light-blue hr {
  border-top-color: #e0f5ff;
}

.alert-light-blue .alert-link {
  color: #616769;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #4d4d4d;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #d4357f;
  border-color: #d4357f;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 375px) {
  .list-group-horizontal-xs {
    flex-direction: row;
  }

  .list-group-horizontal-xs .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xs .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xs .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xs .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xs .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;:motion-rotation: ;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #852e56;
  background-color: #ffd0e6;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #852e56;
  background-color: #ffb7d8;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #852e56;
  border-color: #852e56;
}

.list-group-item-secondary {
  color: #282828;
  background-color: #cdcdcd;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #282828;
  background-color: silver;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}

.list-group-item-success {
  color: #fff;
  background-color: #28a745f2;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #115f7d;
  background-color: #a9e3f9;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #115f7d;
  border-color: #115f7d;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #858585;
  background-color: white;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-pink {
  color: #852e56;
  background-color: #ffd0e6;
}

.list-group-item-pink.list-group-item-action:hover,
.list-group-item-pink.list-group-item-action:focus {
  color: #852e56;
  background-color: #ffb7d8;
}

.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #852e56;
  border-color: #852e56;
}

.list-group-item-light-pink {
  color: #857f80;
  background-color: #fffcfd;
}

.list-group-item-light-pink.list-group-item-action:hover,
.list-group-item-light-pink.list-group-item-action:focus {
  color: #857f80;
  background-color: #ffe3ec;
}

.list-group-item-light-pink.list-group-item-action.active {
  color: #fff;
  background-color: #857f80;
  border-color: #857f80;
}

.list-group-item-blue {
  color: #115f7d;
  background-color: #c1ebfb;
}

.list-group-item-blue.list-group-item-action:hover,
.list-group-item-blue.list-group-item-action:focus {
  color: #115f7d;
  background-color: #a9e3f9;
}

.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #115f7d;
  border-color: #115f7d;
}

.list-group-item-light-blue {
  color: #798184;
  background-color: #f9fdff;
}

.list-group-item-light-blue.list-group-item-action:hover,
.list-group-item-light-blue.list-group-item-action:focus {
  color: #798184;
  background-color: #e0f5ff;
}

.list-group-item-light-blue.list-group-item-action.active {
  color: #fff;
  background-color: #798184;
  border-color: #798184;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

@media (max-width: 1200px) {
  .close {
    font-size: calc(1.275rem + 0.3vw);
  }
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
  /* padding-right: 0px !important; */
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 1000px) {
  .tac-modal > div {
    max-width: 1000px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

/* .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
} */

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #d4357f !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #d4357f !important;
}

.bg-secondary {
  background-color: #4d4d4d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #343434 !important;
}

.bg-success {
  background-color: #20b7f0 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0e9acf !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #fff !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-pink {
  background-color: #d4357f !important;
}

a.bg-pink:hover,
a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d4357f !important;
}

.bg-light-pink {
  background-color: #fff4f7 !important;
}

a.bg-light-pink:hover,
a.bg-light-pink:focus,
button.bg-light-pink:hover,
button.bg-light-pink:focus {
  background-color: #ffc1d2 !important;
}

.bg-blue {
  background-color: #20b7f0 !important;
}

a.bg-blue:hover,
a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #0e9acf !important;
}

.bg-light-blue {
  background-color: #e8f8fe !important;
}

a.bg-light-blue:hover,
a.bg-light-blue:focus,
button.bg-light-blue:hover,
button.bg-light-blue:focus {
  background-color: #b7e9fc !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #d4357f !important;
}

.border-secondary {
  border-color: #4d4d4d !important;
}

.border-success {
  border-color: #20b7f0 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #fff !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-pink {
  border-color: #d4357f !important;
}

.border-light-pink {
  border-color: #fff4f7 !important;
}

.border-blue {
  border-color: #20b7f0 !important;
}

.border-light-blue {
  border-color: #e8f8fe !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 375px) {
  .d-xs-none {
    display: none !important;
  }

  .d-xs-inline {
    display: inline !important;
  }

  .d-xs-inline-block {
    display: inline-block !important;
  }

  .d-xs-block {
    display: block !important;
  }

  .d-xs-table {
    display: table !important;
  }

  .d-xs-table-row {
    display: table-row !important;
  }

  .d-xs-table-cell {
    display: table-cell !important;
  }

  .d-xs-flex {
    display: flex !important;
  }

  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 375px) {
  .flex-xs-row {
    flex-direction: row !important;
  }

  .flex-xs-column {
    flex-direction: column !important;
  }

  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xs-fill {
    flex: 1 1 auto !important;
  }

  .flex-xs-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xs-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xs-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xs-start {
    justify-content: flex-start !important;
  }

  .justify-content-xs-end {
    justify-content: flex-end !important;
  }

  .justify-content-xs-center {
    justify-content: center !important;
  }

  .justify-content-xs-between {
    justify-content: space-between !important;
  }

  .justify-content-xs-around {
    justify-content: space-around !important;
  }

  .align-items-xs-start {
    align-items: flex-start !important;
  }

  .align-items-xs-end {
    align-items: flex-end !important;
  }

  .align-items-xs-center {
    align-items: center !important;
  }

  .align-items-xs-baseline {
    align-items: baseline !important;
  }

  .align-items-xs-stretch {
    align-items: stretch !important;
  }

  .align-content-xs-start {
    align-content: flex-start !important;
  }

  .align-content-xs-end {
    align-content: flex-end !important;
  }

  .align-content-xs-center {
    align-content: center !important;
  }

  .align-content-xs-between {
    align-content: space-between !important;
  }

  .align-content-xs-around {
    align-content: space-around !important;
  }

  .align-content-xs-stretch {
    align-content: stretch !important;
  }

  .align-self-xs-auto {
    align-self: auto !important;
  }

  .align-self-xs-start {
    align-self: flex-start !important;
  }

  .align-self-xs-end {
    align-self: flex-end !important;
  }

  .align-self-xs-center {
    align-self: center !important;
  }

  .align-self-xs-baseline {
    align-self: baseline !important;
  }

  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 375px) {
  .float-xs-left {
    float: left !important;
  }

  .float-xs-right {
    float: right !important;
  }

  .float-xs-none {
    float: none !important;
  }
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.03) !important;
}

.shadow {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05),
    0 2px 4px -1px rgba(0, 0, 0, 0.03) !important;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.05),
    0 4px 6px -2px rgba(0, 0, 0, 0.025) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 375px) {
  .m-xs-0 {
    margin: 0 !important;
  }

  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important;
  }

  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important;
  }

  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important;
  }

  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important;
  }

  .m-xs-1 {
    margin: 0.25rem !important;
  }

  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important;
  }

  .m-xs-2 {
    margin: 0.5rem !important;
  }

  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important;
  }

  .m-xs-3 {
    margin: 1rem !important;
  }

  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important;
  }

  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important;
  }

  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important;
  }

  .m-xs-4 {
    margin: 1.5rem !important;
  }

  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important;
  }

  .m-xs-5 {
    margin: 3rem !important;
  }

  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important;
  }

  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important;
  }

  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important;
  }

  .p-xs-0 {
    padding: 0 !important;
  }

  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important;
  }

  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important;
  }

  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important;
  }

  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important;
  }

  .p-xs-1 {
    padding: 0.25rem !important;
  }

  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important;
  }

  .p-xs-2 {
    padding: 0.5rem !important;
  }

  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important;
  }

  .p-xs-3 {
    padding: 1rem !important;
  }

  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important;
  }

  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important;
  }

  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important;
  }

  .p-xs-4 {
    padding: 1.5rem !important;
  }

  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important;
  }

  .p-xs-5 {
    padding: 3rem !important;
  }

  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important;
  }

  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important;
  }

  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important;
  }

  .m-xs-n1 {
    margin: -0.25rem !important;
  }

  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xs-n2 {
    margin: -0.5rem !important;
  }

  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xs-n3 {
    margin: -1rem !important;
  }

  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important;
  }

  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important;
  }

  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important;
  }

  .m-xs-n4 {
    margin: -1.5rem !important;
  }

  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xs-n5 {
    margin: -3rem !important;
  }

  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important;
  }

  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important;
  }

  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important;
  }

  .m-xs-auto {
    margin: auto !important;
  }

  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important;
  }

  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important;
  }

  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important;
  }

  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 375px) {
  .text-xs-left {
    text-align: left !important;
  }

  .text-xs-right {
    text-align: right !important;
  }

  .text-xs-center {
    text-align: center !important;
  }
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #d4357f !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #ff0c7c !important;
}

.text-secondary {
  color: #4d4d4d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #272727 !important;
}

.text-success {
  color: #20b7f0 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #0c88b7 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #fff !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #d9d9d9 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-pink {
  color: #d4357f !important;
}

a.text-pink:hover,
a.text-pink:focus {
  color: #ff0c7c !important;
}

.text-light-pink {
  color: #fff4f7 !important;
}

a.text-light-pink:hover,
a.text-light-pink:focus {
  color: #ffa8bf !important;
}

.text-blue {
  color: #20b7f0 !important;
}

a.text-blue:hover,
a.text-blue:focus {
  color: #0c88b7 !important;
}

.text-light-blue {
  color: #e8f8fe !important;
}

a.text-light-blue:hover,
a.text-light-blue:focus {
  color: #9fe2fb !important;
}

.text-body {
  color: #4d4d4d !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: ' (' attr(title) ')';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.sparkles > * {
  transform-origin: center;
}

.sparkles .sparkles-two--st0:first-of-type {
  animation: sparkles-glowing-soft 6125ms infinite;
  animation-delay: 0ms;
  transform-origin: 75% 22%;
  opacity: 0.5;
}

.sparkles .sparkles-two--st0:last-of-type {
  animation: sparkles-pulse 10000ms infinite reverse linear;
  animation-delay: 175ms;
  transform-origin: 35% 70%;
}

@keyframes sparkles-pulse {
  91% {
    transform: scale3d(1, 1, 1);
  }

  94% {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  97% {
    transform: scale3d(0.2, 0.2, 0.2);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes sparkles-glowing-soft {
  60% {
    opacity: 0.5;
  }

  70% {
    opacity: 1;
  }

  80% {
    opacity: 0.5;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes sparkles-glowing-hard {
  88% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }

  94% {
    opacity: 0.2;
    transform: scale(0.5) rotate(5deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}

.fade-in-up {
  animation: fade-in-up 2125ms 1 forwards;
  animation-delay: 750ms;
}

@keyframes fade-in-up {
  0%,
  30% {
    opacity: 0;
    transform: translateY(15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.cycle-in {
  animation: cycle-in 13125ms infinite forwards;
  animation-delay: 750ms;
}

@keyframes cycle-in {
  0% {
    opacity: 1;
  }

  6% {
    opacity: 0;
  }

  12%,
  100% {
    opacity: 1;
  }
}

#duster {
  transform-origin: 12% 15%;
  animation: duster 3000ms infinite ease-in-out;
}

@keyframes duster {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0);
  }
}

#dust-1,
#dust-2 {
  animation: dust 3000ms infinite ease-in-out both;
  transform-origin: 35% 100%;
}

#dust-1 {
  animation-delay: 1500ms;
}

@keyframes dust {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0.5);
  }

  75% {
    opacity: 0.12;
  }

  100% {
    opacity: 0;
    transform: translate3d(150px, -50px) scale(1.2);
  }
}

#chompers-top {
  animation: chomp 5500ms infinite ease-in-out both;
  transform-origin: 50% 10%;
}

@keyframes chomp {
  0%,
  88%,
  92%,
  96% {
    transform: rotate(0);
  }

  90%,
  94% {
    transform: rotate(-30deg);
  }
}

img {
  max-width: 100%;
  height: auto;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mt-10 {
  margin-top: 6rem !important;
}

.mr-10 {
  margin-right: 6rem !important;
}

.mb-10 {
  margin-bottom: 6rem !important;
}

.ml-10 {
  margin-left: 6rem !important;
}

.mx-10 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.my-10 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.m-10 {
  margin: 6rem !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.transition {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

a {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

a:hover {
  text-decoration: none;
}

a.link-unstyled {
  color: inherit;
}

/* Underline From Left */
.underline-reveal-from-left {
  --color: var(--pink);
  background-image: linear-gradient(180deg, transparent 96%, var(--color) 0);
  background-size: 0 100%;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size 0.2s ease;
}

.underline-reveal-from-left:hover,
.underline-reveal-from-left:focus {
  background-size: 100% 100%;
  color: var(--color);
}

.underline-reveal-from-left:active {
  --color: var(--blue);
  color: var(--color);
}

/* Move Up Slightly */
/* Inverse link */
.link-inverse {
  color: #fff;
}

.link-inverse:hover {
  color: #fff;
}

/* Body Color link */
.link-body {
  color: #4d4d4d;
}

.link-body:hover {
  color: #d4357f;
}

.accordion__excerpt {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.accordion__excerpt--active {
  opacity: 1;
}

.accordion__card {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background-color: transparent;
}

.accordion__card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #dbdbdb;
  cursor: pointer;
  padding: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.accordion__card--disabled {
  color: #b7b7b7;
}

.accordion__card--disabled .card-header {
  cursor: initial;
}

.accordion__toggle {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  text-align: center;
  width: 100%;
}

.accordion__toggle--active {
  opacity: 1;
}

button:focus {
  outline-color: #d4357f;
}

.btn {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.btn:hover {
  transform: translateY(-2px);
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background-color: var(--dark-pink);
}

.btn-light {
  color: #d4357f;
}

.btn-light:hover {
  color: #fff;
  background-color: #751d46;
}

.btn-blue {
  color: #fff;
  background-color: #751d46;
  border-color: #751d46;
}

.btn-blue:hover {
  color: #fff;
  background-color: #d4357f;
  border-color: #751d46;
}

.btn-blue:focus,
.btn-blue.focus {
  color: #fff;
  background-color: #d4357f;
  border-color: #751d46;
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.btn-blue.disabled,
.btn-blue:disabled {
  color: #fff;
  background-color: #20b7f0;
  border-color: #20b7f0;
}

.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #d4357f;
  border-color: #751d46;
}

.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 194, 242, 0.5);
}

.carousel-control-prev,
.carousel-control-next {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  color: #d4357f;
  opacity: 1;
  width: auto;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  color: #751d46;
}

.carousel-control-prev:focus,
.carousel-control-next:focus {
  color: #d4357f;
}

.carousel-control-prev {
  left: -0.5rem;
}

@media (min-width: 576px) {
  .carousel-control-prev {
    left: -1.5rem;
  }
}

@media (min-width: 992px) {
  .carousel-control-prev {
    left: 0;
  }
}

.carousel-control-next {
  right: -0.5rem;
}

@media (min-width: 576px) {
  .carousel-control-next {
    right: -1.5rem;
  }
}

@media (min-width: 992px) {
  .carousel-control-next {
    right: 0;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background: none;
}

.cta-buttons {
  display: block;
}

.cta-buttons--center .btn {
  margin-left: 1rem;
  margin-right: 1rem;
}

.flatpickr-wrapper {
  width: 100%;
}

.flatpickr-calendar {
  background: transparent;
  box-shadow: none;
  border: 0;
  margin: 0 auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  font-size: 1rem;
}

.flatpickr-months svg {
  vertical-align: top;
}

.flatpickr-day {
  border-radius: 0.25rem;
}

.flatpickr-day.selected {
  background: #d4357f;
  border-color: #d4357f;
}

.flatpickr-day.today {
  color: #751d46;
  border-color: #751d46;
}

.footer {
  font-size: 0.875rem;
  color: #fff;
}

.footer a {
  color: #fff;
}

.footer a:hover {
  color: #fff;
}

.footer .navbar-brand .st0 {
  fill: #fff;
}

.footer__nav-link {
  display: inline-block;
  font-weight: 600;
  min-width: 48px;
  min-height: 48px;
}
.footer__nav-link-text {
  background-image: linear-gradient(180deg, transparent 96%, #fff 0);
  background-size: 0 100%;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size 0.2s ease;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.footer__nav-link:hover > .footer__nav-link-text,
.footer__nav-link:focus > .footer__nav-link-text {
  background-size: 100% 100%;
  color: #fff;
}

footer img {
  padding-left: 0 !important;
}

@media (max-width: 993px) {
  .footer-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .footer-col {
    margin-bottom: 0 !important;
    margin-top: 30px !important;
  }
}

@media (max-width: 767.98px) {
  footer .footer_nav-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  footer .footer-nav-link {
    white-space: nowrap;
  }

  footer .footer-col {
    padding-left: 82px !important;
  }
}

@media (max-width: 575px) {
  footer .footer-nav-link {
    text-align: center;
    max-width: 100%;
  }

  footer .footer-col {
    align-items: center;
    padding-left: 0 !important;
  }
}

@media (max-width: 374.98px) {
  footer .footer-nav-link {
    white-space: normal;
  }
}

#colophon {
  font-size: 0.75rem;
}

#colophon svg {
  margin-right: 0.25rem;
  vertical-align: baseline;
}

::-webkit-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

:placeholder-shown {
  font-style: italic;
}

body {
  --header-height: 64px;
}

.header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  transition: top 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    background-color 10ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 250ms;
  z-index: 1030;
  /* margin-bottom: 1.5rem; */
}

.header[data-scroll='in'] {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05),
    0 2px 4px -1px rgba(0, 0, 0, 0.03);
}

@supports (color: var(--header-height)) {
  [data-scroll-dir-y='1'] .header[data-scroll='in'] {
    top: calc(-1 * var(--header-height));
  }
}

.hero {
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  background: #000;
}

/* @media (min-width: 1200px) and (max-height: 699.98px) {
  .hero h1 {
    font-size: calc(1.5em + 1vw);
  }
} */

.hero .cta-buttons {
  display: none;
}

@media (min-width: 576px) {
  .hero .cta-buttons {
    display: block;
  }
}

.hero .cta-buttons .btn {
  display: block;
  width: auto;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

@media (min-width: 576px) {
  .hero .cta-buttons .btn {
    display: inline-block;
    margin-left: 1rem;
  }

  .hero .cta-buttons .btn:first-of-type {
    margin-left: 0;
  }
}

.hero__filler {
  height: calc(100vh - 64px);
  width: 100%;
}

.hero__overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}

.hero__overlay::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #d4357f;
  background: linear-gradient(
    140deg,
    rgba(255, 88, 165, 0.35) 10%,
    #d4357f 100%
  );
  content: '';
  opacity: 0.5;
  position: absolute;
}

@media (min-aspect-ratio: 16/9) {
  .hero__overlay video {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  .hero__overlay video {
    width: auto;
    height: 100%;
  }
}

/* @media (max-width: 767px) {
  .hero__overlay video {
    display: none;
  }
} */

.hero__clip {
  height: 150px;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  width: 100%;
}

@media (max-width: 374.98px) {
  .hero__clip {
    bottom: -5px;
  }
}

.hero__clip--mobile {
  display: block;
}

@media (min-width: 576px) {
  .hero__clip--mobile {
    display: none;
  }
}

.hero__clip--desktop {
  display: none;
}

@media (min-width: 576px) {
  .hero__clip--desktop {
    display: block;
  }
}

.hero__children {
  height: 100%;
  color: #fff;
}

.hero__children p {
  font-size: 1.25rem;
}

@media (min-width: 768px) {
  .hero__children p {
    padding-left: 20%;
  }
}

@media (min-width: 992px) {
  .hero__children p {
    padding-left: 25%;
  }
}

.icon__dusted {
  margin-bottom: -1rem;
}

@media (min-width: 768px) {
  .icon__dusted #icon-duster {
    width: 50%;
  }
}

.icon__flytrap svg {
  width: 92px;
}

@media (min-width: 844px) {
  .icon__flytrap {
    position: absolute;
    margin-right: 0;
    bottom: -48px;
    left: 99%;
  }
}
@media (max-width: 843px) {
  .icon__flytrap {
    margin-left: auto;
    margin-right: 1rem;
    height: 284px;
    width: 100%;
    text-align: center;
    margin-bottom: -46px;
  }
}

#icon-couch {
  max-width: 411px;
}

.icon__couch {
  overflow: hidden;
  margin-top: -2rem;
  text-align: right;
}

.icon__couch #sparkle-lg {
  animation: sparkles-glowing-hard 3500ms infinite;
  fill: #d4357f;
  opacity: 1;
  transform-origin: 35% 70%;
}

.icon__couch #sparkle-sm {
  opacity: 0.5;
  fill: #d4357f;
}

.icon__couch #sparkles-two {
  position: absolute;
}

.icon__couch #sparkles-two:nth-of-type(1) {
  top: 12%;
  right: 91%;
  width: 1.5rem;
}

@media (min-width: 576px) {
  .icon__couch #sparkles-two:nth-of-type(1) {
    top: 25%;
    right: 80%;
  }
}

@media (min-width: 768px) {
  .icon__couch #sparkles-two:nth-of-type(1) {
    right: 61%;
  }
}

@media (min-width: 992px) {
  .icon__couch #sparkles-two:nth-of-type(1) {
    right: 45%;
  }
}

@media (min-width: 1200px) {
  .icon__couch #sparkles-two:nth-of-type(1) {
    right: 38%;
  }
}

.icon__couch #sparkles-two:nth-of-type(1) #sparkle-lg {
  animation-delay: 4.25s;
}

.icon__couch #sparkles-two:nth-of-type(2) {
  top: -1%;
  right: 14%;
  width: 2.5rem;
}

@media (min-width: 768px) {
  .icon__couch #sparkles-two:nth-of-type(2) {
    right: 11%;
  }
}

@media (min-width: 992px) {
  .icon__couch #sparkles-two:nth-of-type(2) {
    right: 8%;
  }
}

@media (min-width: 1200px) {
  .icon__couch #sparkles-two:nth-of-type(2) {
    right: -2.5rem;
    top: 50%;
  }
}

.icon__couch #sparkles-two:nth-of-type(2) #sparkle-lg {
  animation-delay: 2.75s;
}

.icon__couch #sparkles-two:nth-of-type(2) #sparkle-sm {
  display: none;
}

#icon-neighborhood {
  max-width: 100%;
  height: auto;
}

.icon__neighborhood {
  overflow: hidden;
  margin-bottom: -0.25rem;
  text-align: center;
}

.icon__neighborhood #sparkle-lg {
  animation: sparkles-glowing-hard 3500ms infinite;
  fill: #d4357f;
  opacity: 1;
  transform-origin: 35% 70%;
}

.icon__neighborhood #sparkle-sm {
  opacity: 0.5;
  fill: #d4357f;
}

.icon__neighborhood #sparkles-two {
  position: absolute;
}

.icon__neighborhood #sparkles-two:nth-of-type(1) {
  top: 50%;
  left: 0;
  width: 1.5rem;
}

.icon__neighborhood #sparkles-two:nth-of-type(1) #sparkle-lg {
  animation-delay: 4.25s;
}

.icon__neighborhood #sparkles-two:nth-of-type(2) {
  top: -25%;
  left: 24%;
  width: 2.5rem;
}

@media (min-width: 992px) {
  .icon__neighborhood #sparkles-two:nth-of-type(2) {
    top: -15%;
  }
}

.icon__neighborhood #sparkles-two:nth-of-type(2) #sparkle-lg {
  animation-delay: 2.75s;
}

.icon__neighborhood #sparkles-two:nth-of-type(2) #sparkle-sm {
  display: none;
}

.icon__neighborhood #sparkles-two:nth-of-type(3) {
  top: -28%;
  left: 50%;
  width: 2rem;
}

@media (min-width: 992px) {
  .icon__neighborhood #sparkles-two:nth-of-type(3) {
    top: -13%;
  }
}

.icon__neighborhood #sparkles-two:nth-of-type(3) #sparkle-lg {
  animation-delay: 3.6s;
}

.icon__neighborhood #sparkles-two:nth-of-type(4) {
  top: 55%;
  right: 0;
  width: 2rem;
  transform: scaleX(-1);
}

.icon__neighborhood #sparkles-two:nth-of-type(4) #sparkle-lg {
  animation-delay: 1.4s;
}

#icon-kitchen {
  max-width: 560px;
}

.icon__kitchen {
  overflow: hidden;
}

.icon__kitchen #sparkle-lg {
  animation: sparkles-glowing-hard 3500ms infinite;
  fill: #d4357f;
  opacity: 1;
  transform-origin: 35% 70%;
}

.icon__kitchen #sparkle-sm {
  opacity: 0.5;
  fill: #d4357f;
}

.icon__kitchen #sparkles-two {
  position: absolute;
}

.icon__kitchen #sparkles-two:nth-of-type(1) {
  top: 65%;
  left: -1.5rem;
  width: 1.5rem;
  transform: scaleX(-1);
}

.icon__kitchen #sparkles-two:nth-of-type(1) #sparkle-lg {
  animation-delay: 0;
}

.icon__kitchen #sparkles-two:nth-of-type(2) {
  top: 30%;
  left: 27%;
  width: 1.5rem;
}

@media (min-width: 768px) {
  .icon__kitchen #sparkles-two:nth-of-type(2) {
    left: 25%;
  }
}

@media (min-width: 992px) {
  .icon__kitchen #sparkles-two:nth-of-type(2) {
    left: 17%;
  }
}

@media (min-width: 1200px) {
  .icon__kitchen #sparkles-two:nth-of-type(2) {
    left: 15%;
  }
}

.icon__kitchen #sparkles-two:nth-of-type(2) #sparkle-lg {
  animation-delay: 4.25s;
}

.icon__kitchen #sparkles-two:nth-of-type(2) #sparkle-sm {
  display: none;
}

.icon__kitchen #sparkles-two:nth-of-type(3) {
  top: 32%;
  left: 92%;
  width: 2.5rem;
}

@media (min-width: 576px) {
  .icon__kitchen #sparkles-two:nth-of-type(3) {
    top: 50%;
    left: 99%;
  }
}

@media (min-width: 768px) {
  .icon__kitchen #sparkles-two:nth-of-type(3) {
    left: 82%;
  }
}

@media (min-width: 992px) {
  .icon__kitchen #sparkles-two:nth-of-type(3) {
    left: 61%;
  }
}

@media (min-width: 1200px) {
  .icon__kitchen #sparkles-two:nth-of-type(3) {
    left: 52%;
  }
}

.icon__kitchen #sparkles-two:nth-of-type(3) #sparkle-lg {
  animation-delay: 2.75s;
}

.navbar-collapse {
  flex-direction: column;
}

.navbar-brand {
  width: 125px;
}

@media (min-width: 768px) {
  .navbar-brand {
    width: 200px;
  }
}

.navbar__top-row .btn {
  font-size: 0.875rem;
  margin-right: 1rem;
}

.navbar__bug {
  color: #d4357f;
  margin-left: 2rem;
  text-align: center;
  width: 170px;
  height: 49px;
}

.navbar__bug,
.navbar__bug small {
  font-weight: 700;
}

.navbar__bug small {
  display: block;
}

.navbar__bug a {
  background-image: linear-gradient(180deg, transparent 96%, #d4357f 0);
  background-size: 0 100%;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size 0.2s ease;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
}

.navbar__bug a:hover,
.navbar__bug a:focus {
  background-size: 100% 100%;
  color: #d4357f;
}

.navbar__bug a:active {
  background-image: linear-gradient(180deg, transparent 0, #d4357f 0);
}

.navbar .nav-link {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  font-size: 0.875rem;
  font-weight: 600;
}

.navbar .nav-link:hover {
  transform: translateY(-2px);
}

.navbar .nav-link svg {
  color: #d4357f;
}

.navbar .nav-link:hover svg {
  color: #20b7f0;
}

.navbar .navbar-toggler {
  border: none;
}

.nav-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1020;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.nav-backdrop.show {
  display: block;
}

.nav-tabs {
  font-weight: 600;
}

.nav-tabs .nav-item {
  margin-right: 0.25rem;
}

.nav-tabs .nav-link {
  background-color: #fff4f7;
}

.nav-tabs .nav-link:not(.active):hover {
  background-color: #d4357f;
  color: #fff;
}

.page-title .h1,
.page-title h1 {
  font-size: 52px;
  color: #d4357f;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

@media (max-width: 1200px) {
  .page-title .h1,
  .page-title h1 {
    font-size: 48px;
  }
}

.review__content--medium {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .review__content--medium {
    font-size: calc(1.275rem + 0.3vw);
  }
}

@media (min-width: 992px) {
  .review__content--medium {
    font-size: 1.75rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .review__content--medium {
    font-size: calc(1.3rem + 0.6vw);
  }
}

.review__name--medium,
.review__location--medium {
  font-size: 1.25rem;
}

@media (min-width: 992px) {
  .review__name--medium,
  .review__location--medium {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .review__name--medium,
  .review__location--medium {
    font-size: calc(1.275rem + 0.3vw);
  }
}

.review__content--large {
  font-size: 1.25rem;
}

@media (min-width: 576px) {
  .review__content--large {
    font-size: 1.75rem;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .review__content--large {
    font-size: calc(1.3rem + 0.6vw);
  }
}

@media (min-width: 992px) {
  .review__content--large {
    font-size: 2rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .review__content--large {
    font-size: calc(1.325rem + 0.9vw);
  }
}

.review__name--large,
.review__location--large {
  font-size: 1.25rem;
}

@media (min-width: 576px) {
  .review__name--large,
  .review__location--large {
    font-size: 1.5rem;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .review__name--large,
  .review__location--large {
    font-size: calc(1.275rem + 0.3vw);
  }
}

@media (min-width: 992px) {
  .review__name--large,
  .review__location--large {
    font-size: 1.75rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .review__name--large,
  .review__location--large {
    font-size: calc(1.3rem + 0.6vw);
  }
}

.review__stars span {
  display: inline-block;
}

.reviews__accordion .card-body {
  padding-top: 0;
}

@media (max-width: 575.98px) {
  .reviews__accordion .card-body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.reviews__review {
  background-color: #fff;
  border: 1px solid #dbdbdb;
}

@media (min-width: 768px) {
  .reviews__review {
    border: none;
  }
}

.reviews__review .card-header {
  background-color: #fff;
  border: 0;
  margin: 0;
  padding: 1.25rem 1.25rem;
}

@media (min-width: 576px) {
  .reviews__review .card-header {
    padding: 2.5rem 2.5rem;
  }
}

.reviews__review.accordion__card--active,
.reviews__review.accordion__card--active .card-header {
  background-color: #e8f8fe !important;
}

.sticky-cta {
  /* display: none; */
}

.sticky-cta .btn {
  font-size: 0.875rem;
}

.sticky-cta .btn:first-of-type {
  margin-right: 1rem;
}

@media (max-width: 767.98px) {
  .sticky-cta .btn {
    font-size: 0.75rem;
    margin-bottom: 0;
    padding: 0.28125rem 0.75rem;
  }

  .cta-buttons--center .btn {
    font-size: 0.75rem;
    padding: 0.28125rem 0.75rem;
    margin: 0 0.5rem 0 0.5rem;
  }

  .about-cta .cta-buttons--center {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 374.98px) {
  .sticky-cta .btn {
    padding: 0.28125rem 0.375rem;
  }

  .sticky-cta .btn:first-of-type {
    margin-right: 0.25rem;
  }
}

@media (max-width: 575.98px) {
  .sticky-cta .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.sticky-cta--stuck {
  animation: stickyUp 500ms 1 forwards;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.05),
    0 -2px 4px -1px rgba(0, 0, 0, 0.03);
  position: fixed;
  right: 0;
  bottom: -1px;
  left: 0;
  z-index: 30020;
  display: block;
}

.sticky-cta--hidden {
  display: none;
}

@keyframes stickyUp {
  0% {
    bottom: -100px;
  }

  100% {
    bottom: -1px;
  }
}

.sticky-cta__phone a {
  background-image: linear-gradient(180deg, transparent 96%, #fff 0);
  background-size: 0 100%;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size 0.2s ease;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.sticky-cta__phone a:hover,
.sticky-cta__phone a:focus {
  background-size: 100% 100%;
  color: #fff;
}

/* .sticky-cta__phone a:active {
  background-image: linear-gradient(180deg, transparent 0, #fff 0);
} */

.sticky-cta__phone .sparkles {
  margin-right: 1rem;
  width: 1.5rem;
}

.sticky-cta--book {
  /* box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.05),
    0 -2px 4px -1px rgba(0, 0, 0, 0.03); */
}

.sticky-cta--book .btn {
  margin-right: 0;
  font-size: 1rem;
}

.sticky-cta--book .sparkles {
  display: none;
}

.about-text-columns {
  max-width: 100%;
}

.book__total-cost {
  text-align: left;
  margin-bottom: -0.35rem;
}

.cta-schedule-button {
  margin-bottom: 0 !important;
}

@media (max-width: 489.98px) {
  .estimated-price-col {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .book__total-cost {
    text-align: center;
  }

  .cta-schedule-button .btn-primary {
    margin-top: 1rem;
    margin-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .text-columns {
    column-count: 1;
    column-gap: 60px;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .text-columns-3 {
    column-count: 2;
    column-gap: 30px;
  }
}

@media (min-width: 1200px) {
  .text-columns-3 {
    column-count: 3;
    column-gap: 60px;
  }
}

@media (min-width: 576px) {
  .text-columns-4 {
    column-count: 2;
    column-gap: 30px;
  }
}

@media (min-width: 768px) {
  .text-columns-4 {
    column-count: 3;
    column-gap: 60px;
  }
}

@media (min-width: 1200px) {
  .text-columns-4 {
    column-count: 4;
  }
}

.section-404 {
  margin-top: 15vh;
  margin-bottom: 15vh;
}

.home-services h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.home-services__service #sparkle-lg {
  animation: sparkles-glowing-hard 3500ms infinite;
  fill: #d4357f;
  opacity: 1;
  transform-origin: 35% 70%;
}

.home-services__service #sparkle-sm {
  opacity: 0.5;
  fill: #d4357f;
}

.home-services__service:nth-of-type(1) #sparkles-two {
  position: absolute;
  bottom: 1.5rem;
  left: -2.5rem;
  width: 1.5rem;
  transform: scaleX(-1);
}

.home-services__service:nth-of-type(1) #sparkle-lg {
  animation-delay: 0;
}

.home-services__service:nth-of-type(2) #sparkles-two {
  position: absolute;
  top: -2.5rem;
  left: 2.5rem;
  width: 1.5rem;
}

.home-services__service:nth-of-type(2) #sparkle-lg {
  animation-delay: 4.25s;
}

.home-services__service:nth-of-type(2) #sparkle-sm {
  display: none;
}

.home-services__service:nth-of-type(3) #sparkles-two {
  position: absolute;
  top: 1.5rem;
  right: -2.5rem;
  width: 1.5rem;
}

.home-services__service:nth-of-type(3) #sparkle-lg {
  animation-delay: 2.75s;
}

.home-services__icon {
  margin-left: auto;
  margin-right: auto;
  max-width: 150px;
  overflow: visible;
  width: auto;
}

@media (min-width: 992px) {
  .home-services__icon {
    max-width: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (min-width: 1200px) {
  .home-services__icon {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.home-services__icon .embed-responsive-item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.services .sticky-cta--stuck {
  animation: none;
}

.services__selection {
  top: 1rem;
}

.services__selection .custom-control-label {
  font-size: 1.5rem;
  color: #d4357f;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

@media (max-width: 1200px) {
  .services__selection .custom-control-label {
    font-size: calc(1.275rem + 0.3vw);
  }
}

.services__selection .custom-control-label::before,
.services__selection .custom-control-label::after {
  top: 0.55rem;
}

.services__select-buttons .btn {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  min-width: 250px;
  margin-bottom: 1rem;
  display: none;
  visibility: hidden;
}

.services__select-buttons .btn.show {
  display: block;
  visibility: visible;
}

/* .services__nav-tabs .nav-tabs {
  display: none;
  visibility: hidden;
} */

.services__nav-tabs .nav-tabs.show {
  display: flex;
  visibility: visible;
}

.services__nav-tabs .nav-item {
  margin-right: 0;
  width: 100%;
}

@media (max-width: 991.98px) {
  .services__nav-tabs .nav-item {
    margin-bottom: 4px;
  }

  .services__nav-tabs .nav-item.active {
    order: 99;
    margin-bottom: -1px;
  }
}

@media (min-width: 992px) {
  .services__nav-tabs .nav-item {
    margin-right: 0.25rem;
    width: auto;
  }
}

@media (max-width: 991.98px) {
  .services__nav-tabs .nav-link {
    border-color: #c9c9c9;
  }
}

/* .services__tab-content .tab-content {
  display: none;
  visibility: hidden;
} */

.services__tab-content .tab-content.show {
  display: flex;
  visibility: visible;
}

.services__tab-content .tab-pane {
  max-width: 100%;
}

.services__tab-content .cta-buttons {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.services__room-list li {
  margin-bottom: 1rem;
  /* padding-left: 1.5em; */
  position: relative;
  page-break-inside: avoid;
  break-inside: avoid-column;
}

.services__house--desktop {
  top: var(--header-height);
}

.services__house--mobile {
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 30%;
  z-index: 1020;
}

@media (min-width: 375px) {
  .services__house--mobile {
    width: 35%;
  }
}

.services__house--stuck {
  position: fixed;
}

#services-house > g {
  opacity: 0;
}

#services-house > g#services-house-house,
#services-house > g.active {
  opacity: 1;
}

.about main {
  position: relative;
}

main {
  /* margin-top: -87vh; */
  font-family: 'Gotham Book';
}

.contact__areas {
  column-count: 2;
  column-gap: 30px;
}

@media (min-width: 768px) {
  .contact__areas {
    column-count: 2;
  }
}

@media (min-width: 992px) {
  .contact__areas {
    column-count: 2;
  }
}

.locations__franchise {
  border-bottom: 1px solid #dbdbdb;
}

.locations__accordion .card-header,
.locations__accordion .collapse,
.locations__accordion .collapse.show,
.locations__accordion .card {
  background-color: transparent;
}

.locations__accordion .card-header,
.locations__accordion .card {
  border-bottom: 1px solid #dbdbdb;
  padding: 0;
  margin: 0.5em 0;
}

.locations__accordion .card-header,
.locations__accordion .collapse:not(.show) {
  cursor: pointer;
}

.locations__accordion > .card > .card-header {
  /* prevent disabled states from having a second underline */
  border: none;
  margin: 0;
  color: #757575;
  cursor: default;
}

.book .page-title h1 {
  color: #4d4d4d;
  font-weight: 700;
  font-size: 3.75rem;
}

.book__steps {
  text-align: center;
  position: relative;
  line-height: 1.2;
}

.book__steps::before {
  background-color: #4d4d4d;
  content: '';
  position: absolute;
  top: 0.5rem;
  left: 16.67%;
  right: 16.67%;
  height: 1px;
}

.book__indicator {
  border-radius: 50%;
  background-color: #4d4d4d;
  margin: 0 auto 0.25rem;
  height: 1rem;
  width: 1rem;
}
.book__indicator::hover {
  background-color: var(--blue);
}

.book__indicator.active {
  background-color: #d4357f;
}

.book__gated {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0.5;
  /* pointer-events: none; */
}

.book__gated.active {
  opacity: 1;
  pointer-events: auto;
}

@media (max-width: 992px) {
  .booking__package-row .my-4 {
    text-align: unset !important;
  }
}

@media (max-width: 1200px) {
  .book__gated {
    text-align: center;
  }
  .book__gate > .form-group {
    text-align: center !important;
  }
  .booking__package-row {
    margin-bottom: 15px !important;
  }
  .select__package-col {
    text-align: center !important;
  }
  .select__package-col label {
    margin-bottom: 15px;
  }
}

.book__select-buttons .btn {
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin-left: auto;
  margin-right: auto;
  min-width: 200px;
  margin-bottom: 0.5rem;
}

@media (min-width: 992px) {
  .book__select-buttons .btn {
    margin-left: 0;
    margin-right: 1rem;
  }

  .book__select-buttons .btn:nth-of-type(3n) {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .book__select-buttons {
    width: 123%;
  }
}

.book__select-buttons .btn.show {
  display: block;
  visibility: visible;
}

@media (min-width: 992px) {
  .book__select-buttons .btn.show {
    display: inline-block;
  }
}

@media (max-width: 992px) {
  .book__select-buttons {
    margin-top: 15px !important;
  }
}

.book__select-buttons .btn.active {
  background-color: #751d46 !important;
  position: relative;
}

@media (min-width: 992px) {
  .book__select-buttons .btn.active::after {
    border: solid transparent;
    border-bottom-color: #e8f8fe;
    border-width: 2rem;
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    bottom: -2.75rem;
    left: calc(50% - 2rem);
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .book__select-buttons {
    /* width: 123%; */
    margin-left: -50px;
  }
}

.book__tab-content .tab-content.show,
.book__tab-content label[for='bookPackage'].show {
  display: block;
}

.book__tab-content .tab-content {
  padding: 0 !important;
}

.book__tab-content .tab-content .text-columns {
  column-count: 1;
}

.book__datepicker.flatpickr {
  display: none;
}

.book__timepicker .btn {
  width: 100%;
  margin: 0.25rem 0;
}

@media (min-width: 768px) {
  .book__timepicker {
    margin-top: 3rem;
  }
}

.overlay-white {
  filter: brightness(1);
}

/* Custom Scollbar */
*::-webkit-scrollbar {
  width: 12px;
}
/* *::-webkit-scrollbar-button{
  background-color: #ccc;
}

*::-webkit-scrollbar-track {
  background: linear-gradient(0.25turn, #eef, white, #eef);
} */

*::-webkit-scrollbar-thumb {
  background: #888;
  width: 6px;
  border: 3px solid white;
  border-radius: 28px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #d4357f;
}
@media (pointer: coarse) {
  *::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    width: 6px;
  }
}

body {
  overflow-x: hidden;
}

.store-picker-button {
  border: none;
  background: transparent;
  font-size: 0.875rem;
  font-weight: 700;
  color: #4d4d4d;
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 0;
  position: relative;
}

.store-picker-button .store-picker-tooltip {
  visibility: hidden;
  /* width: 220px; */
  background-color: #d4357f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  opacity: 0;
  transition: all 0.2s ease;
}

.store-picker-button:hover .store-picker-tooltip {
  visibility: visible;
  opacity: 1;
}

.store-picker-card {
  border-bottom: 1px solid rgba(216, 216, 216, 0.72);
}
.store-picker-card:last-child {
  border: none;
}

.store-picker-card .card-title {
  margin-bottom: 5px;
}

.content-drawer {
  --width: 260px;
  /* offscreen */
  position: absolute;
  margin-left: calc((var(--width) * -1) - 20px);
  left: calc((var(--width) * -1) - 20px);
  width: var(--width);
  top: 0;
  /* height: 100vh; */
  z-index: 1010;
  background-color: white;
  padding: 0px;
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  bottom: 0;
  overflow-y: hidden;
  box-shadow: 35px 30px 78px -44px rgba(0, 0, 0, 0.2);
}

.content-drawer.active {
  margin-left: 280px;
  /* position: sticky; */
}

@media (max-width: 1190px) {
  .store-picker-top{
    margin-top: 7rem !important;
    }
}

@media (max-width: 575.98px) {
  .content-drawer {
    --width: 100vw;
  }

  .content-drawer.active {
    --width: 100vw;
    left: 0;
    margin-left: 0;
  }

}
.scroll-wrapper {
  margin-top: 10px;
  overflow-y: scroll;
  box-shadow: inset 0px 28px 15px -23px rgba(0, 0, 0, 0.08);
  transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.content-drawer .scroll-wrapper {
  height: calc(100vh - (var(--header-height) * 5) + 120px);
  padding-bottom: 40px;

}

.scroll-wrapper.modal-scroll {
  max-height: 300px;
}

.twomaids-store-list {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  padding-left: 0;
}

.store-link h5 {
  color: var(--secondary);
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.store-link h5:hover {
  color: var(--primary);
}

.toast-wrapper {
  position: fixed;
  top: calc(var(--header-height, 64px));
  right: 15px;
  transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 9999;
}

.toast-wrapper.confirm-toast-wrapper{
  width: 30%;
  min-width: 350px;
}

.locations__franchise_name {
  color: var(--dark-pink);
  font-family: 'Gotham Medium';
}

.locations__franchise_name a {
  text-transform: uppercase;
}

.locations__franchise,
.franchisee-location-address {
  color: var(--grey) !important;
}

.franchisee-location-address:hover {
  color: var(--dark-pink) !important;
}

.franchisee-location-phone {
  color: var(--dark-pink) !important;
}

.toast-error {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.25rem;
  color: #fff;
  padding: 0.75rem 1.25rem;
  text-align: center;
  background: var(--dark-pink);
  margin-bottom: 10px;
}
@media (max-width: 1199.98px) {
  .mobile-nav-columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0.5em;
  }
}

@media (max-width: 575.98px) {
  .mobile-nav-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0.5em;
  }

  a.header-link:nth-child(2) {
    grid-column-start: 1;
    grid-row-start: 4;
  }

  a.header-link:nth-child(4) {
    grid-column-start: 1;
    grid-row-start: 5;
  }
}

/* .store-card-horizontal{
  display: grid;
  grid-template-columns: auto 150px;
  grid-template-rows: 24px auto;

  grid-template-areas: 
  " name | phone "
  " address | button";
}

.store-card-horizontal>.store-title{
  grid-area: title;
}

.store-card-horizontal>.store-phone-number{
  grid-area: phone;
}

.store-card-horizontal>.store-address{
  grid-area: address;
}

.store-card-horizontal>.btn{
  grid-area: button;
} */

.dynamic-page-embed iframe {
  width: 100%;
  max-height: 100%;
  height: 400px;
}

.dynamic-page-image {
  height: auto;
  object-fit: contain;
}

@media (max-width: 991.98px) {
  .dynamic-page-image-with-text {
    width: 100% !important;
  }
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.local-content-dropdown-menu li {
  color: var(--c-gray-800);
  margin-bottom: -1px;
  cursor: pointer;
  background-color: transparent;
  flex: 1 1 auto;
  margin-right: 0.25rem;
  border-color: var(--pink);
  padding: 0.5rem;
  /* padding-left: 0; */
  font-weight: 700;
  font-size: 0.875rem;
}

.local-content-dropdown-menu a {
  color: var(--c-gray-800);
  margin-bottom: -1px;
  cursor: pointer;
  background-color: transparent;
  /* text-align: center; */
  border-color: var(--pink);
  padding: 0.5rem;
  font-weight: 700;
  font-size: 0.875rem;
  display: block;
  max-width: 200px;
}

/* .local-content-dropdown-menu li:hover, */
/* .local-content-dropdown-menu li:focus, */
.local-content-dropdown-menu a:hover,
.local-content-dropdown-menu a:focus {
  color: var(--pink);
}

.local-content-dropdown {
  display: none !important;
  animation: growDown 300ms ease-in-out;
  transform-origin: top;
}

.local-content-dropdown-menu:hover .local-content-dropdown,
.local-content-dropdown-menu:focus .local-content-dropdown {
  display: block !important;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@media (max-width: 1199.98px) {
  .local-content-dropdown-menu li {
    padding: 0.5rem 0;
  }

  .local-content-dropdown {
    margin-left: -0.5rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: var(--pink, #d4357f);
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: var(--blue, #20b7f0);
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: -ms-flexbox;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '/';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  font-weight: 700;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--pink);
  background-color: transparent;
  /* border: 1px solid #dee2e6; */
}

.welcome-packet-button:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.welcome-packet-button .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--pink);
  border-color: var(--pink);
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.paginator-nav {
  width: 40% !important;
}

.paginator-nav .page-item .page-link {
  cursor: pointer;
  transition: all 200ms ease;
  position: relative;
  z-index: 3;
}

.paginator-nav .page-item.disabled {
  pointer-events: none;
}

.paginator-nav .page-item.active {
  position: relative;
  color: #fff;
}

.paginator-nav .page-item.active .page-link:after {
  transition: all 200ms ease;
  content: '';
  z-index: -1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #d4357f;
}

.paginator-nav .page-item.active .page-link {
  color: #fff;
  border-radius: 50%;
  background: var(--dark-pink);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-top: 5px;
}

.paginator-nav .page-item .page-link:after {
  transition: all 200ms ease;
  content: '';
  z-index: 2;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.paginator-nav .page-item .page-link:hover:after {
  background-color: rgba(0, 0, 0, 0.05);
}

.paginator-nav .page-item.active .page-link:hover:after {
  background-color: #751d46;
  content: '';
  z-index: -1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (max-width: 991.98px) {
  nav.paginator-nav {
    width: 100% !important;
  }
}

/* .services__room-list li::before {
  background: url(../images/sparkle-li.svg) top left no-repeat;
  background-size: 0.75em;
  content: '';
  margin-right: 1em;
  position: absolute;
  left: 0;
  top: 0.3em;
  height: 1.3em;
  width: 1.3em;
} */

.bg-pink-wave {
  background: bottom / cover no-repeat url('../../images/pink-wave.svg');
}

.swoopty-bg::before {
  content: '';
  background-image: bottom url('../../images/hero-clip-mobile.svg');
  background-size: 106% 100%;
  position: absolute;
  top: 40%;
  right: 0;
  bottom: 20%;
  left: 0;
  z-index: -1;
}

@media (min-width: 576px) {
  .swoopty-bg::before {
    background-image: url(../images/hero-clip.svg);
  }
}

.swoopty-bg {
  position: relative;
}

.swoopty-bg::after {
  background-color: #fff;
  content: '';
  position: absolute;
  top: 80%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
}

.swoopty-bg .container {
  position: relative;
  z-index: 10;
}

.swoopty-bg--fixed {
  height: 1px;
  padding: 0 !important;
}

.swoopty-bg--fixed::before {
  background-color: #fff4f7;
  height: 152px;
  top: calc(70vh - 152px);
  bottom: 0;
}

.swoopty-bg--fixed::after {
  background-color: #fff4f7;
  top: 0;
  height: 70vh;
}

@media (max-width: 414px) {
  .swoopty-bg--fixed::after {
    height: 78vh;
  }
}
@media (max-width: 375px) and (max-height: 812px) {
  .swoopty-bg--fixed::after {
    height: 70vh;
  }
}
@media (max-width: 375px) and (max-height: 667px) {
  .swoopty-bg--fixed::after {
    height: 85vh;
  }
}

@media (max-width: 320px) {
  .swoopty-bg--fixed::after {
    height: 110vh;
  }
}

.blog-card {
  transition: all 0.4s ease;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: rgb(31 44 60 / 10%) 0px 5px 40px -10px;
}

.blog-card img {
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 230px;
  max-width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.blog-card:hover {
  box-shadow: rgb(30 45 62 / 21%) 0px 10px 15px -10px,
    rgb(31 44 60 / 10%) 0px 2px 30px -2px;
}

.carousel-inner {
  /* padding: 1rem 0; */
}

.review-card {
  border-radius: 5px;
  /* box-shadow: rgb(30 45 62 / 21%) 0px 10px 15px -10px,
    rgb(31 44 60 / 10%) 0px 2px 30px -2px; */
  /* border: solid 1px #4d4d4d; */
  box-shadow: rgb(0 0 0 / 15%) 0px 3px 8px;
  width: 350px;
  height: 375px;
  padding: 1rem;
}

.carousel-control-next {
  right: -15px;
}

.carousel-control-prev {
  left: -15px;
}

@media (max-width: 767.9px) {
  .review-card {
    width: 100%;
    height: 400px;
  }

  .carousel-control-next {
    right: 0;
  }

  .carousel-control-prev {
    left: 0;
  }
}

@media (max-width: 1199.9px) {
  .reviews-stars-cta-row {
    justify-content: center !important;
  }

  .reviews-cta .stars-row {
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 768px) {
  .reviews-buttons {
    justify-content: center !important;
    white-space: nowrap;
    flex-wrap: nowrap;
  }

  .reviews-buttons .btn-blue {
    padding: 0.5rem;
  }

  .first-review-button {
    margin-bottom: 1.75rem !important;
  }
}

@media (max-width: 575.98px) {
  .hero__overlay > video {
    margin-left: -28vw;
  }

  .reviews-buttons {
    justify-content: center !important;
    white-space: nowrap;
    flex-wrap: wrap;
  }
}

.contact-form {
  display: none;
}

@media (min-width: 992px) {
  .contact__areas {
    column-count: 4;
  }
}

.payment-button .payment-tooltip {
  visibility: hidden;
  width: 260px;
  background-color: #d4357f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 23px;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 3;
  opacity: 0;
  transition: all 0.2s ease;
}

.payment-button:hover .payment-tooltip {
  visibility: visible;
  opacity: 1;
}
.payment-button:disabled:hover {
  opacity: 1;
}

@media (max-width: 767px) {
  .p4p-text {
    text-align: center;
  }
  .p4p-text > p {
    text-align: start;
  }
  .payment-tooltip{
    /* top: -100% !important; */
    left: -25px !important;

  }
}

@media (max-width: 468px) {
  .form-group-sqft,
  .form-group-pets {
    flex-basis: 50%;
  }
}

#CollectJSInlineccexp,
#CollectJSInlineccnumber,
#CollectJSInlinecvv {
  border: none;
  height: 40px !important;
}

@supports (-webkit-backdrop-filter: blur(1px)) {
  .blog-banner-img {
    max-height: 58vh;
  }
}

@media (max-width: 576px) {
  .blog-banner-img {
    max-height: 38vh;
  }
}

.gradient {
  background-color: rgba(208, 216, 226, 0.48);
  /* IE9, iOS 3.2+ */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIxMDIuOSUiIHkxPSI5NC45JSIgeDI9Ii0yLjklIiB5Mj0iNS4xJSI+PHN0b3Agc3RvcC1jb2xvcj0iI2IyYzBjZiIgc3RvcC1vcGFjaXR5PSIwIiBvZmZzZXQ9IjAuMTI5Ii8+PHN0b3Agc3RvcC1jb2xvcj0iI2YwZjNmNiIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAuMzY2Ii8+PHN0b3Agc3RvcC1jb2xvcj0iI2YwZjNmNiIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAuNTE5Ii8+PHN0b3Agc3RvcC1jb2xvcj0iI2IyYzBjZiIgc3RvcC1vcGFjaXR5PSIwIiBvZmZzZXQ9IjAuOTMyIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI3ZzZ2cpIiAvPjwvc3ZnPg==);
  background-image: -webkit-gradient(
    linear,
    102.9% 94.9%,
    -2.9% 5.1%,
    color-stop(0.129, rgba(178, 192, 207, 0)),
    color-stop(0.366, rgb(240, 243, 246)),
    color-stop(0.519, rgb(240, 243, 246)),
    color-stop(0.932, rgba(178, 192, 207, 0))
  );
  /* Android 2.3 */
  background-image: -webkit-linear-gradient(
    140deg,
    rgba(178, 192, 207, 0) 12.9%,
    rgb(240, 243, 246) 36.6%,
    rgb(240, 243, 246) 51.9%,
    rgba(178, 192, 207, 0) 93.2%
  );
  /* IE10+ */
  background-image: linear-gradient(
    310deg,
    rgba(178, 192, 207, 0) 12.9%,
    rgb(240, 243, 246) 36.6%,
    rgb(240, 243, 246) 51.9%,
    rgba(178, 192, 207, 0) 93.2%
  );
  background-image: -ms-linear-gradient(
    140deg,
    rgba(178, 192, 207, 0) 12.9%,
    rgb(240, 243, 246) 36.6%,
    rgb(240, 243, 246) 51.9%,
    rgba(178, 192, 207, 0) 93.2%
  );
  background-repeat: no-repeat;
}

/* IE8- CSS hack */
@media \0screen\, screen\9 {
  .gradient {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00b2c0cf",endColorstr="#00b2c0cf",GradientType=1);
  }
}
@keyframes shimmer {
  0% {
    background-position: -500px;
  }

  100% {
    background-position: 500px;
  }
}
.shimmer-container {
  width: 100%;
  margin-top: 2px;
}
.shimmer {
  font-weight: 300;
  font-size: 0.65rem;
  padding: 1px 84px;
  display: inline;
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  animation: shimmer 2.5s infinite forwards;
}

.btn-learn-more {
  color: #d4357f;
  text-decoration: 'underline';
  cursor: 'pointer';
}
.btn-learn-more:hover {
  opacity: 0.7;
}

@media (max-width: 1000px) {
  .suggested_address {
    margin-top: 15px;
  }
}
.suggested_address,
.current_address {
  box-shadow: 0px 0px 6px #878787;
  padding: 20px;
  border-radius: 15px;
}

.address_line_one_input {
  /*the container must be positioned relative:*/
  position: relative;
}

.autocomplete-items {
  margin-left: 1rem;
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  /* top: 100%; */
  left: 0;
  right: 0;
  font-size: 12px;
  height: 134px;
  width: 50%;
  overflow-y: auto;
  background: white;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}
#cleaningAddressLine1 {
  max-height: 100px !important;
  background: white;
  z-index: 2323;
  border: 1px solid #d4d4d4;
}
#cleaningAddressLine1 a {
  font-size: 14px;
  color: black;
}

.suggestion_container {
  border-bottom: 1px solid #d4d4d4;
  padding: 4px;
}
.suggestion_container:hover {
  background-color: #d4d4d4;
  cursor: pointer;
}
.suggestion_container > .active {
  font-weight: 500;
}

.CookieConsent {
  width: 500px !important;
}
@media (max-width: 576px) {
  .CookieConsent {
    /* width: 90vw !important;
    left: 0 !important; */
    width: 92% !important;
    right: initial !important;
  }
  .cookie_disclaimer_title {
    font-size: 16px;
  }
  .CookieConsent span {
    font-size: 12px;
  }
  .cookie_disclaimer_button button {
    font-size: 12px !important;
  }
}

.tip-text {
  margin-top: 15px;
  margin-bottom: 30px;
  font-weight: 500;
}
.tip-bottom-text {
  margin-top: 25px;
  color: gray;
  font-size: 12px;
}
.tip-container {
  display: flex;
  justify-content: space-between;
}
.tip-container .active {
  transition: transform 0.4s ease-out;
  transform: scale(1.1);
  border-color: #d4367f;
}
.tip-container .active svg {
  /* color:#ff9e00 !important; */
}
.tip-container .active h5 {
  color: #d4357f !important;
}
.tip-box {
  /* border: 2px solid #fff4f7; */
  width: 120px;
  text-align: center;
  border-radius: 10px;
  /* color: white; */
  background: #fff4f7;
  box-shadow: 0px 0px 2px 1px #da5592;
  position: relative;
}
.stable:hover {
  opacity: 0.6;
  cursor: pointer;
}
/* .tip-box > h5 {
  margin-bottom: 14px;
  padding-top: 10px;
  color: black;
} */
.tip-box span {
  padding: 2px;
  margin-right: 2px;
}
.tip-box input:focus {
  outline: none;
}
.tip-box input {
  outline: 0;
  border: none;
  width: 35%;
  background: none;
}
/* .tip-box > svg {
  position: absolute;
  top: -8px;
  right: -12px;
  -webkit-animation: rotateHeart 2.5s infinite alternate;
  animation: rotateHeart 2.5s infinite alternate;
} */

.tip-main-container {
  /* display: flex;
  flex-direction: column; */
  position: relative;
}
.tip-main-container > .css-0 {
  position: absolute;
  right: 0;
}
.tip-main-container button {
  /* width: 30%;
  align-self: end;
  margin-top: 20px; */
  /* width: 38%; */
  /* align-self: end;
  display: flex; */
  margin-top: 20px;
}

@media screen and (min-width: 550px) {
  .tip-box-thank-you {
    width: 100px !important;
  }
}

@media screen and (max-width: 550px) {
  .tip-main-container button {
    width: 50%;
  }
  .tip-box {
    width: 100px;
    height: 80px;
  }
  /* .tip-box > h5 {
    font-size: 20px;
    margin-bottom: 8px;
  } */
  .tip-box > p {
    font-size: 16px;
    /* margin-bottom: 5px; */
  }
  .tip-box input {
    width: 60%;
  }
}
@media screen and (max-width: 450px) {
  .tip-main-container button {
    width: 50%;
  }
  .tip-box {
    width: 80px;
    height: 80px;
  }
  .tip-box > h5 {
    font-size: 18px;
    /* margin-bottom: 8px; */
  }
  .tip-box > p {
    font-size: 14px;
    /* margin-bottom: 5px; */
  }
  .tip-box input {
    width: 60%;
  }
}
@media screen and (max-width: 370px) {
  .tip-main-container button {
    width: 50%;
  }
  .tip-box {
    width: 70px;
    height: 70px;
  }
  .tip-box > h5 {
    font-size: 16px;
    /* margin-bottom: 8px; */
  }
  .tip-box > p {
    font-size: 14px;
    /* margin-bottom: 5px; */
  }
  .tip-box input {
    width: 60%;
  }
}

@keyframes rotateHeart {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

.tip-heading {
  /* position: relative; */
}
.tip-heading > span {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  color: #d4367f;
}

.tooltip-tip-selection {
  /* position: relative; */
  display: inline-block;
}
.tooltip-tip-selection .tooltiptext {
  visibility: hidden;
  width: 375px;
  background-color: #da5592;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 12px;
  /* Position the tooltip */
  position: absolute;
  top: -20px;
  z-index: 1;
  left: 0;
  right: 0;
  margin: auto;
}

.tooltip-tip-selection:hover .tooltiptext {
  visibility: visible;
}
.tip-disable {
  opacity: 0.5;
  pointer-events: none;
}
.tooltip-tip-selection .tooltiptext-tax-fees-container {
  visibility: hidden;
  width: 220px;
  background-color: white;
  color: black;
  border: 2px solid #ced4d9;
  /* text-align: center; */
  border-radius: 6px;
  /* padding: 5px 0; */
  /* font-size: 12px; */
  /* Position the tooltip */
  position: absolute;
  top: -143px;
  z-index: 1;
  left: 25px;
}
.tooltip-tip-selection .tooltiptext-tax-fees-container-sticky {
  visibility: hidden;
  width: 220px;
  background-color: white;
  color: black;
  border: 2px solid #ced4d9;
  /* text-align: center; */
  border-radius: 6px;
  /* padding: 5px 0; */
  /* font-size: 12px; */
  /* Position the tooltip */
  position: absolute;
  top: -165px;
  z-index: 1;
  right: -220px;
}
.tooltip-tip-selection:hover .tooltiptext-tax-fees-container,
.tooltip-tip-selection:hover .tooltiptext-tax-fees-container-sticky {
  visibility: visible;
}

.tooltiptext-tax-fees-container,
.tooltiptext-tax-fees-container-sticky {
  display: flex;
  flex-direction: column;
}

.tooltip-tax-service-item {
  display: flex;
  justify-content: space-between;
  padding: 3px 10px;
}
.tooltiptext-tax-fees-container hr,
.tooltiptext-tax-fees-container-sticky hr {
  border: 1px solid #eff0f2;
  width: 150px;
  margin: 10px auto;
}

.welcome-packet-button-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: space-evenly;
}

.welcome-packet-button-container p {
  margin-bottom: 0;
}

.welcome-packet-button {
  border: none;
  border-radius: 4px;
  background: none;
  transition: all ease 0.3s;
  font-size: 20px;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

.welcome-packet-content {
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 489px) {
  .tooltip-tip-selection .tooltiptext-tax-fees-container-sticky {
    visibility: hidden;
    width: 220px;
    background-color: white;
    color: black;
    border: 2px solid #ced4d9;
    /* text-align: center; */
    border-radius: 6px;
    /* padding: 5px 0; */
    /* font-size: 12px; */
    /* Position the tooltip */
    position: absolute;
    top: -215px;
    z-index: 1;
    left: -100px;
  }
}

@media (max-width: 400px) {
  .tooltip-tip-selection .tooltiptext-tax-fees-container {
    visibility: hidden;
    width: 220px;
    background-color: white;
    color: black;
    border: 2px solid #ced4d9;
    /* text-align: center; */
    border-radius: 6px;
    /* padding: 5px 0; */
    /* font-size: 12px; */
    /* Position the tooltip */
    position: absolute;
    top: -210px;
    z-index: 1;
    left: -10px;
  }
}
#SubmitPaymentMethod {
  background: none;
  color: var(--dark-pink);
  border: solid 1px var(--dark-pink);
}
#SubmitPaymentMethod:hover {
  color: #fff !important;
  background-color: var(--dark-pink) !important;
}

.confirm-checkbox input{
  width: 20px;
  height: 20px;
}
.gratuity-button:hover a{
  color: var(--dark-pink) !important;
}


.gratuity-button:hover,.gratuity-button:active{
  background-color: #fff !important;
  color: var(--dark-pink) !important;
}

.gratuity-button a{
  color: #fff !important;
}
.toast-header span{
  color: white;
  padding-right: 7px;
}
.nav-funnel{
  display: none;
}
@media (max-width: 1192px) {
  .nav-funnel{
    display: block;
  }
}

input:disabled {
  cursor: not-allowed;
}