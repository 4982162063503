/* Footer Styles ----------------------------------------------------------- */
footer {
  overflow: hidden;
  /* z-index: 1; */
  position: relative;
  font-family: 'Gotham Medium';
}

.let-life-shine-section {
  position: relative;
  width: 100%;
  background: var(--light-pink);
  z-index: -1;
  background-image: url('https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,q_auto,w_1920/v1670256573/hubsite-reskin/opaque-dots.webp');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: bottom; */
  height: 30vh;
  /* background-size: 100% 200%; */
  background-position: 0 51%;
}

.footer-white-bg {
  background: #fff;
  position: relative;
  width: 100%;
  z-index: -1;
  background-image: url('https://res.cloudinary.com/twomaidsengi/image/upload/c_scale,q_auto,w_1920/v1670256573/hubsite-reskin/opaque-dots.webp');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: bottom; */
  /* height: 80vh; */
  /* background-position: 0% 80%; */
  height: 30vh;
  background-position: 0 52%;
}

.let-life-shine-text {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 3 !important;
  width: 300px !important;
  height: 38px !important;
}

.footer-tagline {
  color: var(--dark-pink);
  font-size: var(--sub-heading);
  text-align: center;
  margin-bottom: 4rem;
  font-weight: 600;
}

.footer-cta-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.footer-cta-section img {
  z-index: 1;
}

.footer-cta-section a {
  color: var(--dark-pink);
  background: var(--white);
  margin-right: 1rem;
  border: solid 1px var(--white);
  padding: 0.75rem 1rem;
  font-weight: 600;
  transition: var(--button-transition);
  z-index: 3;
}

.footer-cta-section .link-inverse {
  border: none;
  z-index: 20000;
  background: none;
  color: #fff;
}

.footer-cta-section a:hover {
  background: var(--dark-pink);
  color: var(--white);
}

.footer-cta-section .link-inverse:hover {
  border: none;
  z-index: 20000;
  background: none;
  text-decoration: underline;
}

.call-for-estimate-text {
  display: flex;
  align-items: center;
}

.footer-cta-section p {
  color: #fff;
  z-index: 20000;
  margin: 0;
}

.footer-cta-section span {
  font-weight: 800;
  color: #fff;
  margin-left: 1rem;
}

.sticky-footer-cta-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 6rem;
  font-family: 'Gotham Medium' !important;
}

.sticky-footer-cta-section a {
  color: var(--dark-pink);
  background: var(--white);
  margin-right: 1rem;
  border: solid 1px var(--white);
  padding: 0.75rem 1rem;
  font-weight: 600;
  transition: var(--button-transition);
}

.sticky-footer-cta-section .link-inverse {
  border: none;
  z-index: 20000;
  background: none;
  color: var(--white);
}

.sticky-footer-cta-section a:hover {
  background: var(--dark-pink);
  color: var(--white);
}

.sticky-footer-cta-section .link-inverse:hover {
  border: none;
  z-index: 20000;
  background: none;
  text-decoration: underline;
}

/* .call-for-estimate-text {
  display: flex;
  align-items: center;
} */

.sticky-footer-cta-section p {
  color: #fff;
  z-index: 20000;
  margin: 0;
}

.sticky-footer-cta-section span {
  font-weight: 800;
  color: #fff;
  margin-left: 1rem;
}

.footer-nav-section {
  background: var(--dark-pink);
  padding: 4rem 6rem 4rem 6rem;
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

.footer-circle {
  position: absolute;
  content: '';
  width: 500px;
  height: 500px;
  background: var(--light-pink);
  top: -200px;
  right: 140px;
  border-radius: 50%;
  opacity: 0.25;
  /* box-shadow: 10px 10px 10px #971c5c; */
  z-index: 0;
}

.footer-circle-two {
  position: absolute;
  content: '';
  width: 500px;
  height: 500px;
  background: var(--light-pink);
  bottom: -300px;
  right: 140px;
  border-radius: 50%;
  opacity: 0.25;
  /* box-shadow: 10px 10px 10px #971c5c; */
  z-index: 0;
}

.footer-nav-section hr {
  color: var(--light-pink);
  height: 1px;
  border: none;
  background: var(--light-pink);
}

.footer-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4rem;
  background: transparent;
}

.footer-contact a {
  background: none;
  color: var(--white);
  border: solid 1px #fff;
  text-transform: uppercase;
  font-size: var(--paragraph-text);
  height: 50px;
  width: 275px;
  cursor: pointer;
  transition: var(--button-transition);
  font-weight: 800;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.footer-contact a:hover {
  background: var(--white);
  color: var(--dark-pink);
}

.mobile-footer-logo,
.sticky-mobile-footer-logo {
  display: none;
}

.footer-nav {
  padding-top: 6rem;
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
}

.footer-logo {
  max-width: 175px;
  /* height: 100%; */
  margin-right: 10rem;
  margin-bottom: 1rem;
}

.footer-links {
  list-style: none;
  padding: 0 0 3rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 6rem;
  row-gap: 0.75rem;
  font-weight: 600;
  z-index: 2;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  transition: var(--button-transition);
  z-index: 2;
}

.footer-link:hover {
  color: #f9ddebd4;
}

.footer-link a {
  color: var(--light-grey);
}

.social-links-reskin-container {
  display: inline;
  list-style: none;
  display: flex;
  gap: 1.25rem;
  padding-left: 0;
  z-index: 2;
}

.social-link-reskin {
  color: var(--white) !important;
}

.social-link-reskin:hover {
  color: #f9ddebd4 !important;
}

.brand-icons {
  color: var(--light-pink);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-self: flex-start;
  column-gap: 1rem;
}

.brand-icons svg {
  width: 22px;
}

.footer-nav div p {
  color: #fff;
  font-size: var(--paragraph-text);
  font-weight: 400;
  z-index: 2;
}

.footer-nav div p a {
  color: inherit;
}

.mobile-hr {
  display: none;
}

.footer-address {
  display: flex;
  flex-direction: column;
}

.footer-address p {
  font-size: 14px !important;
}

.footer-address .copyright-text {
  font-size: 10px !important;
}

/* Tablet Styles --------------------------------------------------------------- */
@media screen and (max-width: 1350px) {
  .footer-cta-section {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .sticky-footer-cta-section {
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
    padding: .75rem 0rem;
  }

  .sticky-footer-cta-section a { 
    margin-right: 0;
    text-align: center;
    width: 100%;
    max-width: 240px;
    padding: 0.28125rem 0.75rem;
    white-space: nowrap;
  }

  .sticky-footer-cta-section-buttons {
    row-gap: .75rem !important;
    grid-row-gap: .75rem;
    font-size: 12px;
    flex-direction: row !important;
    column-gap: .75rem;
  }

  .sticky-footer-cta-section .link-inverse {
    padding: 0rem;
    font-size: 1.25rem;
  }

  .sticky-footer-cta-section .call-for-estimate-text p { 
    margin-bottom: 0 !important;
    font-size: 12px;
  }

  .footer-cta-section a {
    margin: 0;
    min-width: 300px;
    width: 100%;
    text-align: center;
  }

  .call-for-estimate-text {
    flex-direction: column;
  }

  .call-for-estimate-text p {
    margin-bottom: 0.5rem;
  }

  .call-for-estimate-text span {
    margin: 0;
  }

  .mobile-footer-logo {
    max-width: 175px;
    height: auto;
    margin-bottom: 2rem;
    display: block;
  }

  .footer-logo {
    display: none;
  }

  .footer-cta-section-buttons,
  .sticky-footer-cta-section-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
  }

  .footer-nav-section {
    background: var(--dark-pink);
    padding: 8rem 6rem 5rem 6rem;
    font-weight: 400;
  }

  .footer-circle {
    top: -200px;
    right: -200px;
  }

  .footer-circle-two {
    bottom: -200px;
    right: -200px;
  }

  .footer-contact {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 1rem;
  }

  .footer-contact button {
    margin-bottom: 1rem;
  }

  .footer-contact span {
    margin-left: 1rem;
  }

  .footer-nav {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .footer-logo {
    max-width: 150px;
  }

  .footer-links {
    padding: 3rem 0 3rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0rem;
    row-gap: 0.75rem;
  }
}

/* Mobile Styles --------------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .let-life-shine-section {
    height: 20vh;
  }

  .let-life-shine-text {
    width: 200px !important;
    height: 26px !important
  }

  .social-links-reskin-container {
    margin: 0 auto;
  }

  .footer-nav-section {
    background: var(--dark-pink);
    padding: 8rem 2rem 5rem 2rem;
    font-weight: 400;
  }

  .footer-circle {
    display: none;
  }

  .footer-circle-two {
    display: none;
  }

  .footer-nav-section hr {
    color: var(--light-pink);
    height: 1px;
    border: none;
    background: var(--light-pink);
  }

  .footer-contact {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }

  .footer-contact button {
    font-size: 16px;
    height: 50px;
    width: 275px;
    margin-bottom: 1rem;
  }

  .footer-contact div {
    flex-direction: column;
    text-align: center;
  }

  .footer-contact p {
    font-size: 18px;
    font-weight: 400;
  }

  .footer-contact span {
    margin-left: 0;
  }

  .footer-nav {
    padding-top: 0;
    flex-direction: column-reverse;
    text-align: center;
  }

  .footer-logo {
    display: none;
  }

  .footer-links {
    padding: 2rem 0 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0;
    row-gap: 2rem;
  }

  .footer-links-col {
    row-gap: 2rem;
    grid-template-columns: auto;
  }

  .brand-icons {
    color: var(--light-pink);
    align-self: center;
    flex: 1;
    padding: 0.5rem 0 0.5rem 0;
  }

  .footer-address {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 4rem;
  }

  .footer-address p:first-child {
    margin-right: 0 !important;
  }

  .mobile-hr {
    display: block;
    flex-grow: 1;
    width: 100%;
  }
}
