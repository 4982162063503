#reviews-accordion {
    margin-top: 6rem;
}

.review-button {
    border: solid 1px var(--dark-pink);
    min-width: 250px;
}
  
@media (max-width: 1350px) {
    #reviews-accordion {
        margin-top: 12rem;
    }

    .first-review-content .review__content {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    #reviews-accordion {
        margin-top: 6rem;
    }
}
  