.booking-input-container {
  /* background: var(--light-pink); */
}

.booking-input-container input,
.booking-input-container select,
#cleaningAddressLine1 {
  border: solid 1px var(--grey);
  border-radius: 0;
  font-family: 'Gotham Medium';
}
#cleaningAddressLine1:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ffd8ea;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 88, 165, 0.25);
}
.form-section-header {
  color: var(--dark-pink);
  font-family: 'Gotham Medium';
  /* margin-top: 3rem; */
  font-size: var(--paragraph-text);
  width: 100%;
  text-align: left;
}

.booking-terms-disclaimer {
  font-size: 12px;
  color: var(--grey);
  /* padding-top: 2rem; */
  line-height: 2;
}

.form-section-labels {
  color: var(--dark-pink);
  font-size: 14px;
  font-family: 'Gotham Book';
  text-align: left;
  width: 100%;
}

.book__select-buttons .btn.active::after {
  display: none;
}

.book__select-buttons {
  /* margin-bottom: 6rem !important; */
}

.clear-lead-button {
  border: solid 1px transparent;
  transition: .3s ease all;
  background: var(--dark-pink);
  color: var(--white);
  margin-left: 20px;
}

.clear-lead-button:hover {
  background: transparent;
  color: var(--dark-pink);
  border: solid 1px var(--dark-pink);
}

.booking-accordion-card .card-header {
  border-bottom: none !important;
}

.booking-accordion-card .card-body {
  padding: 2rem 0;
}
.store-picker-card .card-body {
  padding-bottom: 0px !important;
}
#packageDescription {
  font-family: 'Gotham Book';
  line-height: 1.75;
}

#packageDescription p {
  margin-bottom: 4rem;
}

.tip-container {
  font-family: 'Gotham Bold';
}

.tip-box {
  border: solid 1px var(--grey);
  border-radius: 0;
  box-shadow: none;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tip-box h5,
.tip-box p {
  margin-bottom: 0;
  color: var(--grey);
}

.tip-box input {
  border: none;
  /* width: 100%; */
  /* margin: 0 auto; */
  color: var(--white);
  /* width: 65px; */
}
.tip-box div:before {
  content: '$';
}
.tip-box.active {
  transform: none;
  border-color: var(--dark-pink);
  opacity: 1;
  background: var(--dark-pink);
  color: var(--white);
}

.tip-box.active h5,
.tip-box.active p {
  color: var(--white) !important;
}

.booking-info-text {
  color: #808080;
  font-size: 14px;
}

.thank-you-hero-container {
  position: relative;
  /* margin-top: 2rem; */
  /* height: 50vh; */
}

.thank-you-hero-background {
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.thank-you-hero-text-section {
  padding: 4rem 0;
  font-family: 'Gotham Book';
}

.thank-you-hero-text-section h2 {
  color: var(--dark-pink);
  font-family: 'TiemposFine Semibold';
}

.thank-you-hero-text-section p {
  color: var(--grey);
  font-size: 20px;
  line-height: 1.5;
}

.booking-input-container h3 {
  color: var(--dark-pink);
  font-size: 24px;
  overflow-x: hidden;
}

.thank-you-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  margin-top: 4rem !important;
}

.thank-you-page-card {
  background: var(--white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 300px;
  color: var(--dark-pink);
}

.thank-you-page-card .border-top {
  border-top: 1px solid var(--dark-pink) !important;
}

.video-modal-title,
.store-picker-modal-title {
  color: var(--dark-pink);
  font-size: 20px;
  font-family: 'Gotham Bold';
}
.video-modal-title {
  font-size: 22px;
}
.modal-content {
  border: none;
  border-radius: 0;
  font-family: 'Gotham Book';
  color: var(--grey);
  line-height: 1.75;
}

.modal-content .form-control {
  border: solid 2px var(--light-pink);
  border-radius: 0;
  margin-right: 1.5rem;
}

.content-drawer .form-control {
  border-radius: 0;
  /* width: 60%; */
}

.modal-header {
  border-bottom: none;
}

.modal-body {
  padding-top: 0;
  font-family: 'Gotham Book';
  /* margin-bottom: 1rem; */
}

.scroll-wrapper {
  box-shadow: none;
}

.modal-footer {
  border-top: none;
  /* margin-top: 1rem; */
  padding: 0px 0.75rem;
}

.performance__text {
  color: var(--grey);
  font-size: var(--paragraph-text);
  font-family: 'Gotham Book';
}

.performance__text strong {
  font-weight: 800;
  font-family: 'Gotham Bold';
}

.video-modal-button {
  background: var(--dark-pink);
  color: var(--white);
  text-transform: uppercase;
  font-family: 'Gotham Medium';
  font-size: 14px;
  margin: 1rem 0;
}

.store-picker-button {
  background: var(--dark-pink);
  border: solid 1px var(--dark-pink);
  color: var(--white);
  text-transform: uppercase;
  font-family: 'Gotham Medium';
  font-size: 14px;
}

.store-picker-store-title {
  color: var(--dark-pink) !important;
  font-family: 'Gotham Medium';
  font-size: 18px;
  margin-bottom: 0;
  border-top: 1px solid var(--dark-pink);
  padding-top: 1rem;
}

.store-picker-card {
  border-bottom: none;
}

.store-picker-title {
  color: var(--dark-pink);
}

.select-package-header {
  width: 680px;
}

.thank-you-address-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.returning-customer-welcome-container {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.welcome-back-greeting-heading {
  font-size: 24px !important;
  font-family: 'Gotham Book';
  display: block !important;
}

.welcome-back-greeting-store {
  font-size: 24px;
  font-family: 'Gotham Bold';
  display: inline-block;
}
 
.welcome-back-address {
  font-size: 24px;
  font-family: 'Gotham Bold';
}

.returning-customer-home-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.returning-home-label {
  color: var(--dark-pink);
  margin-bottom: .5rem;
}

.returning-customer-home-details .returning-home-value {
  font-family: 'Gotham Bold' !important;
  font-size: 16px !important;
  color: var(--grey)
}

.customer-thank-you-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

@media screen and (max-width: 1332px) {
  .book__select-buttons .btn {
    margin-right: 1rem;
  }

  .thank-you-hero-text-section p {
    font-size: 18px;
    line-height: 1.75;
  }
}

@media screen and (max-width: 768px) {
  .form-section-labels {
    color: var(--dark-pink);
    font-size: 18px;
  }

  .form-section-header {
    font-size: 18px;
    margin-bottom: 0.5rem;
  }

  #booking-form-billing-info .form-row {
    flex-direction: column;
  }

  .form-section-header {
    display: flex;
    text-align: left;
  }

  .booking-home-info-inputs {
    flex-direction: column;
  }

  .returning-customer-home-details {
    flex-direction: column;
  }

  .booking-home-info-inputs .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: flex-start;
    align-items: center;
  }

  .select-package-header {
    width: 100%;
  }

  .booking-package-info {
    margin: 0 !important;
  }

  .booking-package-info .row {
    display: flex;
    /* flex-wrap: nowrap; */
    /* flex-direction: row; */
  }

  .book__select-buttons {
    margin-top: 3rem !important;
    text-align: left !important;
  }

  .book__select-buttons button {
    /* min-width: 250px !important; */
    width: 100%;
    background-color: var(--white);
    color: var(--grey);
    border-color: var(--grey);
    margin-bottom: 1.5rem !important;
    font-family: 'Gotham Medium';
  }

  .booking-state-select,
  .booking-zip-input {
    flex-basis: 50%;
  }

  .tip-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    column-gap: 1rem;
    row-gap: 2rem;
  }

  .tip-box {
    width: 100%;
  }

  .booking-input-container h3 {
    border-top: 1px solid var(--dark-pink);
    border-bottom: 1px solid var(--dark-pink);
    padding: 1rem 0;
  }

  .thank-you-hero-text-section {
    padding: 2rem 0 0 0;
  }

  .thank-you-hero-text-section p {
    font-size: 18px;
  }

  .thank-you-grid {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    justify-items: center;
    margin-top: 2rem !important;
  }

  .thank-you-page-card-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  body.customer-thank-you .thank-you-page-card-container {
    margin-top: 0;
  }

  body.thank-you .thank-you-grid {
    margin-top: 1rem !important;
  }

  .thank-you-page-card {
    width: 100%;
  }
}
