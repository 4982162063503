.top-hero-container {
  position: relative;
  height: 350px;
  margin-top: 48px;
  font-family: 'Gotham Book';
}
.top-hero-container h1 {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  /* margin-bottom: 20px; */
}
.top-hero-container p {
  margin: 30px 0px;
  font-size: 18px;
  color: var(--grey);
}
.top-hero-image-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.top-hero-image {
  position: relative;
  height: 100%;
}
.top-hero-image img {
  object-fit: cover;
  object-position: center;
}
.steps-container {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.step-container {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.step-container h6 {
  color: var(--dark-pink);
  font-weight: initial;
  font-family: 'Gotham Medium';
  margin-bottom: 10px;
  font-size: 12px;
}
.step-circle {
  background: var(--dark-pink);
  width: 40px;
  border-radius: 50%;
  height: 40px;
  position: relative;
}
.circle-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  color: #fff;
  font-family: 'Coves Bold';
}

.top-hero-content-container {
  padding-top: 30px;
}

/* Element | http://localhost:3000/book/ */

.steps-container hr {
  width: 100%;
  align-self: flex-end;
  padding-top: 5px;
}

@media screen and (max-width: 1330px) {
  .top-hero-content-container {
    padding-top: 0px;
  }
}
