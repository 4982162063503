.blog-heading-section {
  padding-left: 0 !important;
}

.blog-title {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  font-size: 4rem;
}

.blog-card-new {
  cursor: pointer;
  background: none;
}
.blog-card-new span {
  border-radius: 50%;
}

.blog-card-image {
  transition: var(--button-transition);
  border-radius: 50%;
  width: 400px;
  height: 400px;
  object-fit: cover;
  position: relative;
  margin-bottom: 2rem;
}

.blog-card-title {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.blog-card-new:hover .blog-card-image {
  filter: grayscale(100);
}

.badge-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge {
  padding-left: 0 !important;
  /* padding-right: 1rem; */
}

.single-blog-page .static-page-title,
.single-blog-page h1 {
  margin-bottom: 0;
}

.single-blog-content {
  margin-top: 8rem;
}

.blog-text-content::first-letter {
  float: left;
  color: var(--dark-pink);
  font-family: 'TiemposFine Semibold';
  font-size: 6rem;
  line-height: 4rem;
  height: 4rem;
  text-transform: uppercase;
}

.blog-content-container h1 {
  font-family: 'TiemposFine Semibold';
  color: var(--dark-pink);
  font-size: 36px;
}

.blog-social-link {
  color: var(--grey);
  margin-right: 1.5rem !important;
  margin-top: 1.5rem !important;
  transition: var(--button-transition);
}

.blog-social-link:hover {
  color: var(--dark-pink);
}

@media screen and (max-width: 1332px) {
  .blog-title {
    font-size: 40px;
  }

  .blog-content-container h1 {
    font-size: 24px;
  }
}