.services-hero-image {
  height: 740px;
  /* height: 80vh; */
  position: relative;
}
.services-hero-image img {
  position: absolute;
  object-fit: cover;
  z-index: -1;
}
.services-hero-container {
}

.services-hero-content {
  position: absolute;
  width: 550px;
  top: 300px;
  left: 100px;
}
.services-hero-content h1 {
  color: var(--dark-pink);
  font-family: 'TiemposFine Regular';
  margin-bottom: 40px;
}
.services-hero-content p {
  line-height: 30px;
  font-family: 'Gotham Book';
  color: var(--grey);
  margin-bottom: 50px;
}
.services-hero-cta-buttons {
  display: flex;
  justify-content: space-between;
  width: 85%;
}
.services-accordian-container div.card:nth-child(1) > div:nth-child(1) {
  /* background-color: var(--light-pink); */
}
.services-accordian-container div.card:nth-child(2) > div:nth-child(1) {
  /* background-color: var(--light-grey); */
}
.services-accordian-container div.card:nth-child(3) > div:nth-child(1) {
  /* background-color: #fff; */
}

.services-accordian-container {
  font-family: 'Gotham Book';
}
.services-accordian-header {
  text-align: center;
  /* width: 800px; */
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.services-accordian-header span {
  /* margin-right: 50px; */
  color: var(--dark-pink);
  font-family: 'Gotham Medium';
  font-size: 24px;
}
.services-accordian-header div {
  position: absolute;
  margin-left: 300px;
}
.services-accordian-header div svg {
  font-size: 32px;
  background-color: var(--dark-pink);
  border-radius: 50%;
  border: 2px solid var(--dark-pink);
}
.services-accordian-header div svg:hover {
  cursor: pointer;
  opacity: 0.6;
}
.package-selection-container {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Gotham Medium';
  color: #4d4d4d;
  margin-top: 50px;
  text-align: center;
}
.faq-service-container {
  font-family: 'Gotham Book';
}

.services-package-description {
  width: 67%;
  margin: 80px auto;
  position: relative;
}
.services-package-description p {
  z-index: 2;
  position: relative;
}
.service-circle-one,
.service-circle-two {
  height: 100px;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.268);
  position: absolute;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  z-index: 1;
}
.service-circle-one {
  transform: translate(-50%, 20%);
}
.service-circle-two {
  transform: translate(-50%, -100%);
}
.package-selection-container span:hover,
.service-package-active {
  cursor: pointer;
  color: var(--dark-bg-pink);
  /* text-decoration: underline; */
  border-bottom: 1px solid var(--dark-bg-pink);
}
.accordion__card .card-header {
  border-bottom: 1px solid #9f9f9d;
}
.services-accordion-icon svg {
  width: 22px !important;
  height: 3em !important;
}
@media screen and (min-width: 600px) {
  .touch-up-service-header {
    margin-left: 40px;
  }
}

@media screen and (max-width: 1332px) {
  body.services main {
    /* margin-top: -91vh; */
  }
}

@media screen and (max-width: 1234px) {
  .services-hero-content {
    position: relative;
    top: unset;
    left: unset;
    width: 90%;
    margin: 50px auto;
  }
  .services-hero-cta-buttons {
    flex-direction: column;
    width: 250px;
    gap: 20px;
  }
  .services-hero-image {
    height: 50vh;
  }
  .services-hero-image img {
    display: block;
    position: relative;
  }
}
@media screen and (max-width: 736px) {
  .services-hero-image {
    height: 32vh;
  }

  .services-accordian-header {
    justify-content: flex-start;
  }

  .services-package-description {
    width: 85%;
    margin: 50px auto;
  }

  .faq-service-container .card-body,
  .faqs .card-body {
    padding: 2.5rem 0 !important;
  }
}
