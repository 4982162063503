.hero__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.hero__content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  margin: 2rem;
  position: absolute;
  width: calc(100vw - 4rem);
  height: calc(100% - 4rem);
  justify-content: space-between;
  top: 0;
}

.hero__image {
  position: relative;
  height: 24rem;
  color: '#fff';
  width: 100vw;
}

.hero__content--image {
  width: 90%;
  max-width: 847px;
  min-width: 300px;
}

.hero__ctas {
  display: flex;
  gap: 2rem;
  flex-direction: row;
}

a.btn-tm-cta {
  display: inline-flex;
  color: var(--white);
  border: solid 2px var(--dark-pink);
  background: var(--dark-pink);
  padding: 0.75rem 1rem;
  font-weight: 600;
  transition: var(--button-transition);
  font-family: 'Gotham Medium';
  /* background-color: rgba(0, 0, 0, 0.007); */
  min-width: 16rem;
  align-items: center;
  justify-content: center;
}

a.btn-tm-cta:hover {
  background: var(--white);
  color: var(--dark-pink);
  border-width: 2px;
}

.hero-circles {
  position: fixed;
  z-index: -2;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: none;
}

.hero-circle {
  background-color: var(--light-pink);
  z-index: -2;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  opacity: 0.5;
}

@media(max-width: 1332px) {
  a.btn-tm-cta {
    /* width: 40%; */
    max-width: 350px;
    height: 50px;
    color: var(--white);
    border: solid 2px var(--dark-pink);
    background: var(--dark-pink);
    cursor: pointer;
    font-weight: 700;
    padding: 15px;
    text-decoration: none;
    font-family: Gotham Medium;
  }

  .hero-circles {
    display: flex;
    top: -12%;
  }
}

@media(max-width: 600px) {
  .hero__content {
    position: relative;
  }

  .hero__content--image {
    position: absolute;
    top: -200px;
  }

  .hero__image {
    height: 12rem;
  }

  .hero__ctas {
    flex-direction: column;
  }
}
