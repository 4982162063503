.careers-container {
    position: relative;
}

.careers-hero-image-container {
    width: 100%;
    height: 600px;
    display: block;
}

.careers-hero-image-container img {
    position: absolute;
    width: 100%;
    height: 600px;
    top: 0;
    object-fit: cover;
    object-position: left;
}

.careers-heading {
    color: var(--dark-pink);
    text-align: center;
    font-family: 'TiemposFine Regular';
    padding: 50px 0px;
    font-size: var(--large-heading);
}

.careers-sub-heading {
    color: var(--dark-pink);
    font-family: 'TiemposFine Regular';
    padding: 50px 0px;
    font-size: var(--sub-heading);
}

.careers-columns {
    column-count: 2;
    padding-bottom: 50px;
    margin-bottom: 0;
}

.careers-maid-image-container {
    position: relative;
}

.grey-bg {
    background: var(--light-grey);
    height: 90%;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

.careers-future-section {
    display: grid;
    grid-template-columns: 65% 35%;
    justify-items: center;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.careers-button-section p {
    text-align: center;
    margin-top: 1rem;
}

.careers-button-border {
    border: 3px solid var(--dark-pink);
    width: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    cursor: pointer;
    transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.careers-button-border:hover {
    background: var(--dark-pink);
}

.careers-button-border:hover > .careers-button {
    color: #fff;
}

.careers-button {
    transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    padding: 20px;
    font-size: 24px;
    text-transform: uppercase;
    word-wrap: break-word;
    text-align: center;
    width: 250px;
}

.careers-benefits-list {
    column-count: 2;
    padding: 50px 0 50px 12px;
    line-height: 2.5;
}

.careers-grey-section {
    background: var(--light-grey);
    border-radius: 25px;
    padding: 50px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 4rem;
}

.careers-grey-section img {
    max-width: 250px;
    width: 100%;
}

.careers-grey-section p {
    max-width: 600px;
}

.careers-video-section {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    padding: 0 2rem;
    column-gap: 2rem;
}

.careers-video {
    position: relative;
    cursor: pointer;
}

.career-vid-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    width: 100%;
    height: 100%;
    transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 1;
}

.career-vid-one {
    background-image: url("https://res.cloudinary.com/twomaidsengi/image/upload/q_auto/v1682369983/hubsite-reskin/video_thumb_1.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 425px;
    max-height: 333px;
    width: 100%;
    height: 300px;
}

.career-vid-two {
    background-image: url("https://res.cloudinary.com/twomaidsengi/image/upload/q_auto/v1682369983/hubsite-reskin/video_thumb_2.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 425px;
    max-height: 333px;
    width: 100%;
    height: 300px;
}

.career-vid-three {
    background-image: url("https://res.cloudinary.com/twomaidsengi/image/upload/q_auto/v1682369983/hubsite-reskin/video_thumb_3.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 425px;
    max-height: 333px;
    width: 100%;
    height: 300px;
}

.career-vid-four {
    background-image: url("https://res.cloudinary.com/twomaidsengi/image/upload/q_auto/v1682369983/hubsite-reskin/video_thumb_4.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 425px;
    max-height: 333px;
    width: 100%;
    height: 300px;
}

.career-vid-one:hover > .career-vid-gradient,
.career-vid-two:hover > .career-vid-gradient,
.career-vid-three:hover > .career-vid-gradient,
.career-vid-four:hover > .career-vid-gradient {
    opacity: 0;
}

.careers-video img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.careers-video p {
    position: absolute;
    bottom: 0;
    left: 8px;
    color: var(--white);
}

@media (max-width: 991px) {
    .careers-future-section {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }

    .careers-grey-section {
        flex-direction: column;
        row-gap: 2rem;
    }
}

@media (max-width: 768px) {
    .careers-columns {
        column-count: 1;
    }

    .careers-video-section {
        display: flex;
        flex-wrap: wrap;
        row-gap: 2rem;
        padding: 0 2rem;
    }

    .careers-button {
        width: 100%;
        max-width: 100%;
    }

    .careers-button-border {
        width: 100%;
        max-width: 100%;
    }
}