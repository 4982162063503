.contact-page-google-map {
  filter: grayscale(0.5);
}

.contact-page-city-state {
  color: var(--dark-pink);
  font-size: var(--large-paragraph-text);
  font-family: 'Gotham Bold';
}

.communities-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
}

.served-community {
  border-right: 2px solid var(--grey);
  padding-right: 1rem;
  padding-left: 1rem;
}
.communities-grid div:last-child span {
  border-right: none;
}
.contact-us-title-container,
.contact-us-content-container {
  /* background-color: #faf7f3; */
}

.contact-us-content-container {
  padding-bottom: 2rem;
}

@media screen and (max-width: 1332px) {
  body.contact main {
    /* margin-top: -91vh; */
  }
}

@media screen and (max-width: 991px) {
  .contact-page-google-map {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .contact-page-google-map {
    margin-top: 0;
  }
}